import React, { Component } from "react";
import {Modal, Segment, Header, Form, Icon, Grid, List, Button,Image,Message,GridColumn, Menu,Label } from "semantic-ui-react";
import 'react-anything-sortable/sortable.css';

import {CarBrand} from './sync/'
import Classification from "./sync/Classification";
import numberToText from "number-to-text/converters/de";
import MaskedInput from 'react-text-mask'

class CarEdit extends Component {
    state={
        loading:true,
        uploadingImage: false,
        uploadingFile: false,
    }
    
    handleChange = (e, { name,value }) => this.props.setState({ car:{...this.props.car, [name]:value } })
    
    render() {

        return (
            <Form>
                <Grid columns="equal" style={{marginBottom:10}}>
                    <Grid.Column>
                        <Segment color="black">
                            <Header as="h5">Interne Fahrzeugnummer<up>*</up></Header>
                            <Form.Input value={this.props.car.internal_id} name="internal_id" required onChange={this.handleChange.bind(this)} />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment color="black">
                            <Header as="h5">Klassifikation {this.state.classification?"":"- Bitte auswählen"}</Header>
                            <Classification value={this.props.car.classification} onChange={(a,e)=>this.props.setState({car:{...this.props.car,classification:e.value,type:null,manufacturer:null}})} />
                        </Segment>
                    </Grid.Column>
                </Grid>
                {this.props.car.classification &&<div>
                     <Segment color="black">
                        <Header as="h5">Marke, Modell, Variante</Header>
                        <CarBrand category={this.props.car.category} type={this.props.car.type} classification={this.props.car.classification} manufacturer={this.props.car.manufacturer} onChange={data=>this.props.setState({car:{...this.props.car,manufacturer:data.manufacturer,type:data.type,category:data.category}})} />
                    </Segment>
                     <Segment color="black">
                        <Header as="h5">Preis</Header>
                        <Form.Group widths="equal">
                            <Form.Input required name="price" value={this.props.car.price} label="Gesamtpreis in Euro" type="number" onChange={this.handleChange.bind(this)} />
                            <Form.Field >
                                <label>in Worten</label>
                                <p style={{marginTop:12}}>{numberToText.convertToText(this.props.car.price ? this.props.car.price : 0)} </p>
                            </Form.Field>
                        </Form.Group>
                        <Form.Checkbox checked={this.props.car.tax==1?true:false} name="tax" onChange={(e)=>this.handleChange(e,{name:"tax",value:this.props.car.tax==1?false:true})} toggle label="Mit MwSt."  />

                    </Segment>
                     <Segment color="black"> 
                        <Header as="h5">Datenblatt</Header>
                        <Form.Input name="identification" value={this.props.car.identification} required  onChange={this.handleChange.bind(this)}  label="Fahrzeug-Ident.-Nr" />
                        <Form.Group widths="equal">
                            <Form.Input required label="Datum der Erstzulassung"  >
                                <MaskedInput
                                    onChange={(e)=>this.handleChange(e,{name:"admission_first",value:e.target.value})} 
                                    pattern="[(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                                    placeholder="DD-MM-YYYY" 
                                    mask={[/\d/,/\d/,"-",/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/]}  
                                    guide={true} value={this.props.car.admission_first} required  />
                            </Form.Input>
                            <Form.Input name="admission_nr" value={this.props.car.admission_nr} onChange={this.handleChange.bind(this)} required label="Zulassungsnummer" />
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Form.Input name="kilometer" value={this.props.car.kilometer} required onChange={this.handleChange.bind(this)} type="number" label="Kilometerstand" />
                            <Form.Field >
                                <Form.Group widths="equal">
                                    <Form.Input required label="Nächste AU" onChange={this.handleChange.bind(this)}  >
                                        <MaskedInput
                                            onChange={(e)=>this.handleChange(e,{name:"inspection_month",value:e.target.value})}
                                            pattern="[0-9]{2}" placeholder="MM" mask={[/\d/,/\d/]} guide={true} value={this.props.car.inspection_month} required  />
                                    </Form.Input>
                                    <Form.Input  label="&nbsp;" onChange={this.handleChange.bind(this)}  >
                                        <MaskedInput
                                            onChange={(e)=>this.handleChange(e,{name:"inspection_year",value:e.target.value})}
                                            pattern="[0-9]{4}" placeholder="YYYY" mask={[/\d/,/\d/,/\d/,/\d/]} guide={true} value={this.props.car.inspection_year} required  />
                                    </Form.Input>
                                </Form.Group>
                            </Form.Field>
                        </Form.Group>
                        <Form.TextArea value={this.props.car.description} name="description" onChange={this.handleChange.bind(this)} label="Reparaturen, Änderungen am Fahrzeug" />
                        <Form.Field>
                            <label>Weitere Angaben</label>
                            <Form.Group>
                                <Form.Checkbox checked={this.props.car.commercial==1?true:false} name="commercial" onChange={(e)=>this.handleChange(e,{name:"commercial",value:this.props.car.commercial==1?false:true})} style={{marginTop:10}} toggle label="Wurde gewerblich genutzt"  />
                                <Form.Checkbox checked={this.props.car.imported==1?true:false} name="imported" onChange={(e)=>this.handleChange(e,{name:"imported",value:this.props.car.imported==1?false:true})} style={{marginTop:10}} toggle label="Importfahrzeug"  />
                            </Form.Group>
                        </Form.Field>
                    </Segment>

                </div>}

            </Form>
        )
    }
}

export default CarEdit