import React, { Component } from "react";
import { Segment, Header, Input, Checkbox, Message, Loader, Form } from "semantic-ui-react";
import client from '../../../shared/feathers'

import moment from "moment";
class ContractSingleEdit extends Component {
    state={
        holding: false,
        loading:true,
        saving:false,
        files: [],
        customers:[],
        cars:[],
    }

    async search(field,query){
        this.setState({[field]:(await client.service(field).find({query})).data.map((elm,index)=>({
            key:elm.id,
            value:elm.id,
            text:(elm.firstname||elm.company)?(elm.company?elm.company:elm.firstname+" "+elm.lastname):elm.manufacturer+" "+elm.type,
            description:(elm.firstname||elm.company)?elm.id:elm.identification
        }))});
    }
    async componentDidMount(){
        let newState= {}
        if(this.props.contract.customer_id){
            let customer = await client.service("customers").get(this.props.contract.customer_id)
            newState.customer = customer
            newState.customers=[{
                key: customer.id,
                value: customer.id,
                text: customer.firstname?customer.firstname+" "+customer.lastname:customer.company,
                description: customer.id
            }]
            
        }

        if(this.props.contract.car_id){
            let customer = await client.service("cars").get(this.props.contract.car_id)
            newState.cars=[{
                key: customer.id,
                value: customer.id,
                text: customer.manufacturer+" "+customer.type,
                description: customer.identification
            }]
        }
        this.setState({...newState,loading:false})
    }
    async handleChangeCustomer (e,{value}) {
        this.setState({
            customer_id: value,
            customer:await client.service("customers").get(value),
            holding:false
        })
    }
    async handleChangeCar (e,{value}) {
        let car = await client.service("cars").get(value)
        this.setState({
            car_id: value,
            kilometers:car.kilometer,
            price:car.price,
            taxes: car.tax
        })
    }
    handleChange = (e, { name,value }) => this.setState({ [name]:value })
    handleCheckbox = (e,{name}) => this.setState({[name]: !this.state[name]})
    async save(){
        this.setState({error:false,saving:true})
        let {keys,admission_papers,financing,prepayment,price,customer_id,car_id,kilometers,taxes,holding,holding_company,holding_company_tax,holding_company_vat,holding_company_address,holding_company_address_extra,holding_company_postcode,holding_company_city,holding_company_country} = this.state
        client.service("contracts").patch(this.props.contract.id,{
            keys,admission_papers,financing,prepayment,updated_at:moment(),price,customer_id,car_id,kilometers,taxes,holding,holding_company,holding_company_tax,holding_company_vat,holding_company_address,holding_company_address_extra,holding_company_postcode,holding_company_city,holding_company_country}).then((result)=>{
            this.setState({saving:false})
        }).catch((e)=>{
            console.log(e)
            this.setState({error:true,saving:false})
        })
    }
    render() {
        if(this.state.loading){
            return <Loader active />
        }
        return (
            <Form loading={this.state.saving} onSubmit={this.save.bind(this)}>
                <Form.Group widths="equal">
                    <Form.Select
                        required
                        defaultValue={this.props.contract.customer_id}
                        name="customer_id"
                        label="Kunde"
                        placeholder='Kunden auswählen'
                        fluid
                        search
                        noResultsMessage='Es wurde keine Kunden gefunden.' 
                        onSearchChange={(e,{searchQuery})=>this.search("customers",{
                            $or:[
                                {company:{$like: "%"+searchQuery+"%"}},
                                {firstname:{$like: "%"+searchQuery+"%"}},
                                {lastname:{$like: "%"+searchQuery+"%"}},
                                {id:{$like: "%"+searchQuery+"%"}},
                            ]
                        })}
                        options={this.state.customers}  
                        onChange={this.handleChangeCustomer.bind(this)}
                        />
                    {this.state.customer.type=="private" &&<Form.Checkbox name="holding" checked={this.state.holding} onChange={this.handleCheckbox.bind(this)} style={{marginTop:30}} label="als Gewerbe in Vertretung" toggle />}
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Select
                        required
                        defaultValue={this.props.contract.car_id}
                        name="car_id"
                        label="Fahrzeuge"
                        placeholder='Fahrzeug auswählen'
                        fluid
                        search
                        noResultsMessage='Es wurde keine Autos gefunden.' 
                        onSearchChange={(e,{searchQuery})=>this.search("cars",{
                            $or:[
                                {firstname:{$like: "%"+searchQuery+"%"},},
                                {identification:{$like: "%"+searchQuery+"%"},},
                                {id:{$like: "%"+searchQuery+"%"},}
                            ]
                        })}
                        options={this.state.cars} 
                        onChange={this.handleChangeCar.bind(this)}
                    />
                    <Form.Field >
                        <label>Kilometerstand<up>*</up></label>
                        <Input type="number" required defaultValue={this.props.contract.kilometers} onChange={this.handleChange.bind(this)} name="kilometers" label="KM" labelPosition="right" />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field >
                        <label>Preis<up>*</up></label>
                        <Input type="number" required defaultValue={this.props.contract.price} onChange={this.handleChange.bind(this)} name="price" label="€" labelPosition="right" />
                    </Form.Field>
                    <Form.Field >
                        <label>Anzahlung</label>
                        <Input type="number" defaultValue={this.props.contract.prepayment} onChange={this.handleChange.bind(this)} name="prepayment" label="€" labelPosition="right" />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Input label="Schlüssel" required defaultValue={this.props.contract.keys} onChange={this.handleChange.bind(this)} name="keys"  />

                    <Form.Field >
                        <label>Zulassungsbescheinigung erhalten</label>
                        <Checkbox checked={this.props.contract.admission_papers==1?this.props.contract.admission_papers:this.state.admission_papers} onChange={()=>this.setState({admission_papers:this.state.admission_papers})} name="admission_papers" style={{marginTop:8}} toggle required />
                    </Form.Field>
                </Form.Group>
                <Form.Field >
                    <label>Darlehnsunterlagen erhalten</label>
                    <Checkbox checked={this.props.contract.financing==1} onChange={()=>this.setState({financing:this.state.financing})} name="financing" style={{marginTop:8}} toggle />
                </Form.Field>
                {this.state.holding && <Segment.Group>
                    <Header attached="top">Vertretendes Unternehmen</Header>
                    <Segment attached="bottom" color="green">
                        <Form.Input defaultValue={this.props.contract.holding_company} onChange={this.handleChange.bind(this)} name="holding_company" required label="Unternehmen" />
                        <Form.Group widths="equal">
                            <Form.Input defaultValue={this.props.contract.holding_company_tax} onChange={this.handleChange.bind(this)}name="holding_company_tax" required label="Steuernummer" />
                            <Form.Input defaultValue={this.props.contract.holding_company_vat} onChange={this.handleChange.bind(this)} name="holding_company_vat" label="USt-ID" />
                        </Form.Group>
                        <Form.Input defaultValue={this.props.contract.holding_company_address} onChange={this.handleChange.bind(this)} name="holding_company_address" required label="Straße, Hausnummer" />
                        <Form.Input placeholder="Adresszusatz" defaultValue={this.props.contract.holding_company_address_extra} onChange={this.handleChange.bind(this)} name="holding_company_address_extra" />
                        <Form.Group widths="equal">
                            <Form.Input defaultValue={this.props.contract.holding_company_postcode} onChange={this.handleChange.bind(this)} name="holding_company_postcode" required width="5" label="PLZ" />
                            <Form.Input defaultValue={this.props.contract.holding_company_city} onChange={this.handleChange.bind(this)} name="holding_company_city" required label="Stadt" />
                        </Form.Group>
                        <Form.Select defaultValue={this.props.contract.holding_company_country} onChange={this.handleChange.bind(this)} name="holding_company_country" required label="Land" options={[{flag:"de",key:"de",value:"DE",text:"Deutschland"}]} value="DE" />
                    </Segment>
                </Segment.Group>}
                {this.state.error && <Message negative >Es ist ein Fehler beim speichern aufgetreten. Bitte überprüfen Sie alle Felder oder kontaktieren Sie den Support.</Message>}
                <Form.Button primary >Speichern</Form.Button>
            </Form>
        )
    }
}

export default ContractSingleEdit