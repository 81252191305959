import React, { Component } from "react";
import { Modal,Button, Image, Table, Loader, Dimmer,Icon, Message } from "semantic-ui-react";
import client from "../../shared/feathers";
import Currency from "react-currency-formatter";

class CarsImport extends Component {
    state={
        open:false,
        loading:true,
        cars:[],
        error:null,
        succ:[]
    }
    async componentDidUpdate(prevProps,prevState){
        if(this.state.open && !prevState.open ){
            let ads=await client.service("mobile-ads").find()
            this.setState({loading:false,cars:ads.ads})
        }
    }
    async add(mobileAdId){
        this.setState({loading:true,error:null})
        try{

            let result =  await client.service("mobile-ads").create({mobileAdId})
            if(result.status){
                this.setState({loading:false,succ:[...this.state.succ,"Erfolgreich Importiert. Interne Nummer: "+result.car.internalNumber]})
            }else{
                this.setState({loading:false,error:result.error})
            }
        }catch(e){
            console.log(e)
            this.setState({loading:false,error:"Verbindung zum Server verloren"})
        }
    }
    render() {
        return (
            <Modal closeIcon open={this.state.open} onClose={()=>this.setState({open:false})} onOpen={()=>this.setState({open:true})} trigger={this.props.trigger}>
                <Modal.Header><Image src='/images/mobile.png' style={{ height: 20, width: 'auto',float:"left",marginRight:10 }} size='small' />Fahrzeugimport mobile.de</Modal.Header>
                {this.state.open && <Modal.Content >
                    <Dimmer active={this.state.loading}>
                        <Loader />
                    </Dimmer>
                    {this.state.error && <Message negative>{this.state.error}</Message>}
                    {this.state.succ.map(el=><Message positive>{el}</Message>)}
                    <Table compact size="small" striped celled >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan="5">Verfügbare Fahrzeuge</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell collapsing style={{padding:5}} >Interne Fahrzeugnummer</Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5}} >Anzeigentitel</Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5}} >Model</Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5}} >Preis</Table.HeaderCell>
                                <Table.HeaderCell collapsing style={{padding:5}} ></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.cars.map((el,id)=>
                            [
                                <Table.Row key={el.mobileAdId}>
                                    <Table.Cell>{el.internalNumber}</Table.Cell>
                                    <Table.Cell>{el.modelDescription}</Table.Cell>
                                    <Table.Cell>{el.make} {el.model}</Table.Cell>
                                    <Table.Cell><Currency quantity={el.price.consumerPriceGross} currency="EUR" /></Table.Cell>
                                    <Table.Cell><Button onClick={()=>this.add(el.mobileAdId)} icon size="mini"><Icon name="add" /></Button></Table.Cell>
                                </Table.Row>
                            ]
                            )}
                        </Table.Body>
                    </Table>
                </Modal.Content>}
            </Modal>
        )
    }
}

export default CarsImport