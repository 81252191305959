import React, {Component} from 'react'
import {  Form, Icon, Header, Button } from 'semantic-ui-react'
import {withRouter, Route} from 'react-router-dom'
import client from '../../shared/feathers'

class LoginForm extends Component{

    state = {
        err:null,
        loading:false
    }
    sendLogin(){
        this.props.onLogin()
        this.setState({loading:true})
        const { email, password } = this.state;
        client.authenticate({
          strategy: 'local',
          email, password
        }).catch(err => {this.props.onLoginFailed();this.setState({ err,loading:false })});
    }
    handleChange = (e, { name, value }) => this.setState({ [name]: value })
    render(){
        return (
            <Form style={{paddingTop:15}} loading={this.state.loading} onSubmit={this.sendLogin.bind(this)} >
                <Header as="h3">Anmelden</Header>
                <Form.Input label="E-Mail" required name="email" onChange={this.handleChange.bind(this)} />
                <Form.Input label="Passwort" required type="password" name="password" onChange={this.handleChange.bind(this)} />
                <Form.Button primary fluid icon labelPosition="right" style={{textAlign:"left"}}>
                    Anmelden
                    <Icon name='key' />
                </Form.Button>
                <Button onClick={()=>this.props.history.push("/Authentification/forgot-password")} fluid icon labelPosition="right" style={{textAlign:"left"}}>
                    Passwort vergessen ?
                    <Icon name='question circle outline' />
                </Button>
            </Form>
        )
    }
} 

export default withRouter(LoginForm)