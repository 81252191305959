import React, { Component } from "react";
import {Modal, Segment, Header, Form, Icon, Grid, List, Button,Image,Message,GridColumn, Menu,Label } from "semantic-ui-react";
import Dropzone from 'react-dropzone'
import moment from 'moment'
import {SortableContainer, SortableElement,SortableHandle} from 'react-sortable-hoc';
import client from "../../shared/feathers";
import arrayMove from 'array-move';
import 'react-anything-sortable/sortable.css';


const DragHandle = SortableHandle(({file}) => <Icon name="expand arrows alternate" style={{float:"left"}} />);
const SortableItem = SortableElement(({file,onDelete}) => (
    <div style={{height:150, width:150, padding:0,marginRight:5,marginBottom:5,float:"left", border:"1px solid #ddd"}}>
        <DragHandle file={file}  />
        <Icon onClick={()=>onDelete()} name="close" style={{float:"right",marginBottom:10}} />
        <Modal trigger={<Image src={"https://api.carbill.dev.tecoyo.com/uploads/"+file.id} />}>
            <Modal.Content style={{overflow: 'hidden', padding:0}}>
                <Image src={"https://api.carbill.dev.tecoyo.com/uploads/"+file.id} style={{width: '100%',cursor:"pointer"}}/>
            </Modal.Content>
        </Modal>
    </div>
));

const SortableList = SortableContainer(({items,onDelete}) => {
  return (
    <div style={{}}>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} onDelete={()=>onDelete(index)} file={value} />
      ))}
      <div style={{clear:"both"}}/>
    </div>
  );
});

class CarFiles extends Component {
    state={
        loading:true,
        uploadingImage: false,
        uploadingFile: false,
    }
    
    handleChange = (e, { name,value }) => this.props.setState({ car:{...this.state.car, [name]:value } })
    
    async uploadingFile(file,field,loadingField,last){
        let reader  = new FileReader();
            reader.addEventListener("load", () =>{
                client.service('uploads')
                    .create({uri: reader.result})
                    .then((response)=>{
                        this.props.setState({ car: {...this.props.car,[field]: [...this.props.car[field],{name:file.name,id:response.id, sort:0}]}, [loadingField]:last });
                    }).catch(()=>{
                        this.props.setState({ [loadingField]:last} );
                    });
            }, false);
            reader.readAsDataURL(file);
    }
    async onDropImages(images){
        this.props.setState({uploadingImage:true})
        let counter = 0
        for (const image of images) {
            counter++
            this.uploadingFile(image, "images","uploadingImage",!(counter >=images.length))
        }
    }
    async onDrop(images){
        this.props.setState({uploadingFile:true})
        let counter = 0
        for (const image of images) {
            counter++
            this.uploadingFile(image, "files","uploadingFile",!(counter >=images.length))
        }
    }
    deleteDoc(index,field){
        this.props.setState({ car: {...this.state.car,[field]: this.state.car[field].filter((val,indexfil)=>indexfil!=index) } })
    }
    changeSort(index,field,value){
        this.props.car[field][index].sort = value;
        this.props.setState({ car: this.props.car })
    }
    onSortEnd = ({oldIndex, newIndex}) => {
        this.props.setState({
            car: {...this.props.car, images: arrayMove(this.props.car.images, oldIndex, newIndex) }
        })
    };
    render() {

        return (
            <div>
                <Segment.Group>
                    <Header attached="top">Bilder</Header>
                    <Segment attached="bottom">
                        <Dropzone onDrop={this.onDropImages.bind(this)} maxSize={20000000} accept="image/*">
                        {({getRootProps, getInputProps, isDragActive}) => (
                            <Segment placeholder={!isDragActive} style={{minHeight:80}} {...getRootProps()}>
                                <input {...getInputProps()} />
                                {this.props.uploadingImage ? "Wird gerade hochgeladen..." :"Ziehe die Datei hierhin, um sie hochzuladen."}
                                {!this.props.uploadingImage && <small className="helper">Maximale Datei-Größe von 10MB</small>}
                            </Segment>
                        )}
                        </Dropzone>
                        {this.props.car.images.length > 0 &&
                        <SortableList useDragHandle onSortEnd={this.onSortEnd.bind(this)} axis="xy" onDelete={(index)=>this.deleteDoc(index,"images")} items={this.props.car.images} />}
                    </Segment>
                </Segment.Group>
                <Segment.Group>
                    <Header attached="top">Dokumente</Header>

                    <Segment attached="bottom">
                        <Dropzone maxSize={20000000} onDrop={this.onDrop.bind(this)} >
                        {({getRootProps, getInputProps, isDragActive}) => (
                            <Segment placeholder={!isDragActive} style={{minHeight:80}} {...getRootProps()}>
                                <input {...getInputProps()} />
                                {this.props.uploadingFile ? "Wird gerade hochgeladen..." :"Ziehe die Datei hierhin, um sie hochzuladen."}
                                {!this.props.uploadingImage && <small className="helper">Maximale Datei-Größe von 10MB</small>}
                            </Segment>
                        )}
                        </Dropzone>
                        {this.props.car.files.length > 0 &&<List>
                            {this.props.car.files.map((file,index) => (
                                <List.Item key={index}>
                                    <List.Content floated='right'>
                                        <Button onClick={()=>this.deleteDoc(index,"files")} as="a" negative icon><Icon name="trash" /></Button>
                                    </List.Content>
                                    <List.Content><a href={"https://api.carbill.dev.tecoyo.com/uploads/"+file.id} target="_blank">{file.name}</a></List.Content>
                                </List.Item>
                            ))}
                        </List>}
                    </Segment>
                </Segment.Group>
            </div>
        )
    }
}

export default CarFiles