import React,{ Component } from "react";
import { Form, Loader, Button } from "semantic-ui-react";
import axios from 'axios'

class Classification extends Component{
    state={
        loading:true,
        value:null,
        options:[]
    }
    async componentDidMount(){
        if(this.props.value){
            await this.update()
            await this.setStateAsync({value:this.props.value})
        }else{
            await this.update()
        }
    }
    async componentDidUpdate(prevProps){
        if(prevProps.value != this.props.value){
            await this.update()
            await this.setStateAsync({value:this.props.value})
        }
    }
    handleChange(e,data){
        this.setState({value:data.value})
        this.props.onChange(e,data)
    }
    setStateAsync(state) {
        return new Promise((resolve) => {
          this.setState(state, resolve)
        });
    }
    async update(){
        this.setState({loading:true,options:[],value:null})
        await this.setStateAsync( {loading:false,options:(await axios.get("https://carbill.dev.tecoyo.com/refdata/classes",{headers:{Accept:"application/vnd.de.mobile.api+json"},withCredentials:false}) ).data.values.map(el=>({text:el.description,value:el.name,key:el.name})) } )
        return true;
    }
    render(){
        if(this.state.loading){
            return <Loader inline active />
        }
        return <Form.Select options={this.state.options} value={this.state.value} search name="Classification" onChange={this.handleChange.bind(this)} />
    }
}

export default Classification