import React,{ Component } from "react";
import { Segment, Menu, Input, Icon, Table, Pagination, Header, Select } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import CustomersNew from "./new";
import Currency from "react-currency-formatter";
import client from "../../shared/feathers";
let types = {commercial:"Unternehmen",private:"Privat"}

class Customers  extends Component{

    state={
        query:{},
        data:{
            total:1,
            limit:1,
            data:[]
        },
        page: 1,
        loading:true
    }
    componentDidMount(){
        document.title = 'Kunden';
        this.update()
    }

    changePage(event,data){
        this.setState({loading:true,page:data.activePage},()=>this.update())
    }
    update(){
        this.setState({loading:true})
        if(this.reactive){this.reactive.unsubscribe()}
        this.reactive = client.service("customers").watch().find({query: {$and:[...Object.values(this.state.query)],$skip:(this.state.page * this.state.data.limit)-this.state.data.limit }}).subscribe(async data=>this.setState({data,loading:false}))
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    changeFilter(name,value){
        if(name=="name"){
            this.setState({
                query:{
                    ...this.state.query,
                    [name]:{
                        $or:[
                            {firstname:{$like: "%"+value+"%" }},
                            {lastname:{$like: "%"+value+"%" }},
                            {company:{$like: "%"+value+"%" }}
                        ]
                    }
                }
            }, ()=>this.update())
        }else if(name=="address"){
            this.setState({
                query:{
                    ...this.state.query,
                    [name]:{
                        $or:[
                            {postcode:{$like: "%"+value+"%" }},
                            {address:{$like: "%"+value+"%" }},
                            {city:{$like: "%"+value+"%" }}
                        ]
                    }
                }
            }, ()=>this.update())
        }else{
            this.setState({query:{...this.state.query,[name]:{[name]:{$like: "%"+value+"%" }}}}, ()=>this.update())
        }
    }
    render(){
        return ( 
            <Segment basic>
                <Menu>
                    <Menu.Item><b>Kunden</b></Menu.Item>
                    <Menu.Menu position="right">
                        <CustomersNew trigger={<Menu.Item  ><Icon name="plus square" /> Hinzufügen</Menu.Item>} />
                    </Menu.Menu>
                </Menu>
                <Segment loading={this.state.loading} style={{padding:0}}>
                    <Table selectable celled size="small">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>ID</Table.HeaderCell>
                                <Table.HeaderCell>Typ</Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Addresse</Table.HeaderCell>
                                <Table.HeaderCell>Branche/Job</Table.HeaderCell>
                                <Table.HeaderCell>E-Mail</Table.HeaderCell>
                                <Table.HeaderCell>Telefon</Table.HeaderCell>
                                <Table.HeaderCell>Fax</Table.HeaderCell>
                                <Table.HeaderCell>Umsatz</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell collapsing style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)}  name="id" size="tiny" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:0, backgroundColor:'#fff'}}><Select style={{border:"0",height:20}} options={[{key:"com",value:"commercial",text:"Unternehmen"},{key:"prv",value:"private",text:"Privat"}]} onChange={(e,{name,value})=>this.changeFilter(name,value)} clearable text='' transparent name="type" fluid /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="name" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="address" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="job" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="email" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="phone" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {this.state.data.data.length >0 && <Table.Body>
                            {this.state.data.data.map((entry, id) =>
                                <Table.Row key={entry.id} style={{cursor:"pointer"}} onClick={()=>this.props.history.push("/customers/"+entry.id)}>
                                    <Table.Cell>{entry.id}</Table.Cell>
                                    <Table.Cell>{types[entry.type]}</Table.Cell>
                                    <Table.Cell>{entry.company ? entry.company : entry.firstname+" "+entry.lastname}</Table.Cell>
                                    <Table.Cell>{entry.address + ", "+entry.postcode+" "+entry.city}</Table.Cell>
                                    <Table.Cell>{entry.job}</Table.Cell>
                                    <Table.Cell>{entry.email}</Table.Cell>
                                    <Table.Cell>{entry.phone}</Table.Cell>
                                    <Table.Cell>{entry.fax}</Table.Cell>
                                    <Table.Cell><Currency quantity={entry.sales} currency="EUR" /></Table.Cell>
                                </Table.Row>
                            ) }
                        </Table.Body>}
                    </Table>

                    {this.state.data.data.length ==0 &&<Segment placeholder attached="bottom">
                        <Header icon><Icon name='search' /> Es wurde kein Kunde gefunden </Header>
                    </Segment>}
                </Segment>
                <Pagination 
                    onPageChange={this.changePage.bind(this)}
                    attached="bottom" 
                    boundaryRange={2} 
                    siblingRange={2}
                    fluid 
                    defaultActivePage={this.state.page} 
                    totalPages={Math.ceil(this.state.data.total / this.state.data.limit)} />
            </Segment>
        )
    }
}

export default withRouter(Customers)