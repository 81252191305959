import React, {Component} from 'react'
import { Icon, Header } from 'semantic-ui-react'

class Step2Desc extends Component{

    render(){
        return (
            <div>
                <Header as='h2' style={{width:"100%",color:"#fff",textAlign:"left"}}>
                    <span><Icon name='address card outline' /> Benutzer</span>
                    <Header.Subheader style={{color:"#fff"}}>Bitte notieren Sie sich die E-Mail und das Passwort. Sie werden diese zukünftig zum Einloggen ins Portal brauchen.</Header.Subheader>
                </Header>
            </div>
        )
    }
} 

export default Step2Desc