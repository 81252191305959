import React, {Component} from 'react'
import { Button, Header, List } from 'semantic-ui-react'

import RegisterContext from "../context";

class Step3Desc extends Component{

    render(){
        return (
            <div>
                <Button onClick={()=>this.props.history.push("/Authentification/Register/")} size="mini" >
                    Daten Ändern
                </Button>
                <Header inverted>Angaben</Header>

                <List>
                    <List.Item>{this.context.company.name}</List.Item>
                    <List.Item>{this.context.company.address}</List.Item>
                    {this.context.company.address_extra && <List.Item>{this.context.company.address_extra}</List.Item>}
                    <List.Item>{this.context.company.postcode} {this.context.company.city}</List.Item>
                    <List.Item>{this.context.company.phone}</List.Item>
                </List>

                <List>
                    <List.Item>{this.context.user.salutation} {this.context.user.firstname} {this.context.user.lastname}</List.Item>
                    <List.Item>{this.context.user.email}</List.Item>
                    <List.Item>{this.context.user.phone}</List.Item>
                    <List.Item>••••••••••</List.Item>
                </List>


                <Header inverted>Paket</Header>
                <List>
                    <List.Item><b>30</b>-Tage Testpaket</List.Item>
                </List>
            </div>
        )
    }
} 

Step3Desc.contextType = RegisterContext
export default Step3Desc