import React, {Component} from 'react'
import {  Form, Button, Header } from 'semantic-ui-react'
import RegisterContext from "./context";

class Step1 extends Component{

    render(){
        let {company,changeCompany} = this.context
        
        return (
            <Form onSubmit={()=>this.props.history.push("/Authentification/Register/Step-2")}>
                <Header >
                    Unternehmen
                    <Header.Subheader>Bitte tragen Sie Ihre Unternehmensinformationen in die Felder ein.</Header.Subheader>
                </Header>
                <Form.Input  label="Unternehmen" name="name" onChange={(e,{name,value})=>changeCompany(name,value)} value={company.name} required />
                <Form.Input  label="Geschäftsführer" name="owner" onChange={(e,{name,value})=>changeCompany(name,value)} value={company.owner} required />
                <Form.Input  label="Adresse" value={company.address} name="address" onChange={(e,{name,value})=>changeCompany(name,value)} required    />
                <Form.Input  name="address_extra" value={company.address_extra} onChange={(e,{name,value})=>changeCompany(name,value)} />
                <Form.Input  label="Stadt" value={company.city} required  name="city" onChange={(e,{name,value})=>changeCompany(name,value)} />
                <Form.Input  label="Postleitzahl" value={company.postcode} required  name="postcode" onChange={(e,{name,value})=>changeCompany(name,value)} />
                <Form.Input  label="Telefon" value={company.phone} required name="phone" onChange={(e,{name,value})=>changeCompany(name,value)} />
                <Form.Input style={{marginBottom:-10}}  label="E-Mail" value={company.mail} required name="mail" onChange={(e,{name,value})=>changeCompany(name,value)} />
                <div style={{marginBottom:10}}><small >Nur für die Buchhaltung notwendig</small></div>

                <Form.Select  options={[{key:"de",value:"DE",flag:"de",text:"Deutschland"}]} value="DE" label="Land" />
                <div>
                    <Button onClick={()=>this.props.history.push("/Authentification")} >Abbrechen</Button>
                    <Button primary >Weiter</Button>
                </div>
            </Form>
        )
    }
} 
Step1.contextType = RegisterContext
export default Step1