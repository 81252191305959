import React, { Component } from "react";
import { Grid, Segment, SegmentGroup, Header, Icon, Image } from 'semantic-ui-react';
import { ResponsiveStream } from '@nivo/stream'
import FullCalendar from 'fullcalendar-reactwrapper';
import moment from 'moment'
import Context from "../context";
import client from '../../shared/feathers'
import Moment from "react-moment";
import { Line,Bar } from 'react-chartjs-2';

import deLocales from '@fullcalendar/core/locales/de';
moment.locale('de');
let monthArr = [ "Januar", "Februar", "März", "April", "Mai","Juni","Juli" ]
class Dashboard extends Component {

    state={
        loadingBest:true,
        loadingFull:true,
        loadingEvents:true,
        user:null,
        events:[],
        full:[],
        fullKeys:[],
        best:[],
        bestToLess:false,
        fullToLess:false,
        bestUser:{firstname:"Loading", lastname:""}
    }

    async componentDidMount(){
        let user = await client.service("user").find()
        this.setState({loadingEvents:false,user})
        client.service("sales_user_monthly").find({query:{$limit:1,$sort:{default:1},month:moment().subtract(1,"month").month()+1,year:moment().subtract(1,"month").year()}}).then(async result=>{
            if(result.data.length==0){this.setState({bestToLess:true,loadingBest:false,bestUser:{firstname:"Zu wenig Daten", lastname:""}}); return;}
            let bestUser = await client.service("users").get(result.data[0].user_id)
            this.setState({bestUser})
            client.service("sales_user_daily").find({query:{$limit:30,$sort:{day:-1},user_id:result.data[0].user_id,month:moment().subtract(1,"month").month()+1,year:moment().subtract(1,"month").year()}}).then(result=>{
                this.setState({loadingBest:false,best:
                    {
                        label:bestUser.firstname+" "+bestUser.lastname,
                        strokeColor: bestUser.color,
                        pointColor: bestUser.color,
                        pointStrokeColor: bestUser.color,
                        pointHighlightFill: bestUser.color,
                        pointHighlightStroke: bestUser.color,
                        fillColor: bestUser.color, 
                        data: result.data.map(el=>({ t: moment(el.year+"-"+el.month+"-"+el.day,"YYYY-M-D"),y:el.default }))
                    }
                })
            })
        })
        client.service("sales_user_monthly").find({query:{year:moment().year()}}).then(async result=>{
            if(result.data.length==0){this.setState({loadingFull:false,fullToLess:true}); return;}
            let monthDetailed = {}
            let userMonth
            for (const elm of result.data) {
                if(!monthDetailed[elm.user_id]){monthDetailed[elm.user_id]={data:[]}}
                userMonth = await client.service("users").get(elm.user_id) 
                monthDetailed[elm.user_id].label=userMonth.firstname+" "+userMonth.lastname
                monthDetailed[elm.user_id].data.push({t:moment(elm.year+"-"+elm.month,"YYYY-M"),y:elm.default})
            }
            this.setState({full:Object.values(monthDetailed),loadingFull:false})
        })
    }

    render() {
        return (
            <Segment basic>
                <Grid >
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <SegmentGroup >
                                <Segment attached="top" loading={this.state.loadingBest}>
                                    <Header as='h2'>
                                        <Image src="/images/salesman.png" />
                                        <Header.Content>
                                            <Header.Subheader>Bester Verkäufer im <Moment format="MMMM" locale="de">{moment().subtract(1,"month")}</Moment></Header.Subheader>
                                            {this.state.bestUser.firstname} {this.state.bestUser.lastname}
                                        </Header.Content>
                                    </Header>
                                </Segment>
                                <Header attached>Umsätze von {this.state.bestUser.firstname} {this.state.bestUser.lastname} des letzten Monats</Header>
                                <Segment placeholder={this.state.bestToLess} loading={this.state.loadingBest} attached="bottom" style={{ height: 277, padding: 0 }}>
                                {!this.state.bestToLess && <Line 
                                    data={{
                                        datasets:[
                                            this.state.best
                                        ]
                                    }} 
                                    options={{
                                        maintainAspectRatio: false,
                                        legend:false,
                                        responsive: true,
                                        fill:true,
                                        scales: {
                                            xAxes:[
                                                {
                                                    display: false,
                                                    type:       "time",
                                                    time:       {
                                                        tooltipFormat: 'DD.MM.YYYY'
                                                    },
                                                }
                                            ],
                                            yAxes:[
                                                {
                                                    display:false,
                                                    ticks:{
                                                        suggestedMin:0,
                                                        beginAtZero:true
                                                    }
                                                }
                                            ]
                                        }    
                                    }}/>}
                                </Segment>
                            </SegmentGroup>

                        </Grid.Column>
                        <Grid.Column>
                            <Segment.Group >
                                <Header attached="top">Deine heutige Agenda</Header>
                                <Segment loading={this.state.loadingEvents} attached="bottom">
                                {this.state.user && <FullCalendar
                                    height={325}
                                    noEventsMessage="Keine Ereignisse anzuzeigen"
                                    id = "Test-Calc2"
                                    header = {false}
                                    locale="de"
                                    defaultView="listWeek"
                                    buttonText={{
                                        today:    'Heute',
                                        month:    'Monat',
                                        week:     'Woche',
                                        day:      'Tag',
                                        list:     'Liste'
                                    }}
                                    weekNumbers
                                    events = {(start,end,lol,callback)=>{
                                        client.service("events").find(
                                            {
                                                query:{
                                                    $and:[
                                                        {user_id:this.state.user.id},
                                                        {start:{$lte:end.format("YYYY-MM-DD HH:mm:SS")}},
                                                        {start:{$gte:start.format("YYYY-MM-DD HH:mm:SS")}},
                                                    ]
                                                }
                                            }).then(
                                            (result)=>{
                                            callback(
                                                result.data.map(elm=>({
                                                    title: elm.title,
                                                    start: moment(elm.start),
                                                    id:elm.id,
                                                    end:moment(elm.end),
                                                    backgroundColor:elm.color
                                                }) ) ) 
                                            }
                                        )
                                    }}
                                    defaultDate={moment().format("YYYY-MM-DD")}
                                    navLinks= {true} // can click day/week names to navigate views
                                    editable= {true}
                                    eventLimit= {true} // allow "more" link when too many events
                                    selectable
                                    timeFormat="H:mm"
                                    select={(start,end)=>this.context.openEvent(0,start,end)}
                                    eventClick={(e)=>{this.context.openEvent(e)}}
                                />}
                                </Segment>
                            </Segment.Group>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Segment.Group >
                                <Header attached="top">Umsätze pro Verkäufer der letzten 12 Monate</Header>
                                <Segment loading={this.state.loadingFull} placeholder={this.state.fullToLess} attached="bottom" style={{ height: 300, padding: 0 }}>
                                {!this.state.fullToLess && <Line 
                                    data={{
                                        datasets:this.state.full
                                    }} 
                                    options={{
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        fill:true,
                                        scales: {
                                            xAxes:[
                                                {
                                                    display: true,
                                                    type:       "time",
                                                    time:       {
                                                        tooltipFormat: 'MMMM'
                                                    },
                                                }
                                            ],
                                            yAxes:[
                                                {
                                                    display:false,
                                                    ticks:{
                                                        suggestedMin:0,
                                                        beginAtZero:true
                                                    }
                                                }
                                            ]
                                        }    
                                    }}/>}
                                    {/* <ResponsiveStream
                                        offsetType="none"
                                        keys={this.state.fullKeys}
                                        borderWidth={2}
                                        borderColor="inherit"
                                        fillOpacity={0.3}
                                        indexBy = "day"
                                        margin={{
                                            "top": 50,
                                            "right": 110,
                                            "bottom": 50,
                                            "left": 60
                                        }}
                                        axisBottom={{
                                            "orient": "bottom",
                                            "tickSize": 5,
                                            "tickPadding": 5,
                                            "tickRotation": 0,
                                            "legend": "",
                                            "legendOffset": 36,
                                            format:(d)=>monthArr[d]
                                        }}
                                        axisLeft={{
                                            "orient": "left",
                                            "tickSize": 5,
                                            "tickPadding": 5,
                                            "tickRotation": 0,
                                            "legend": "",
                                            "legendOffset": -40
                                        }}

                                        data={this.state.full}
                                        margin={{right:0,top:50,left:60,bottom:40}}
                                        legends={[
                                            {
                                                "anchor": "top-right",
                                                "direction": "row",
                                                "justify": false,
                                                "translateX": 30,
                                                "translateY": 0,
                                                "itemWidth": 120,
                                                "itemHeight": -30,
                                                "itemTextColor": "#000000",
                                                "symbolSize": 8,
                                                "symbolShape": "circle"
                                            }
                                        ]}
                                    />*/}
                                </Segment>
                            </Segment.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }
}
Dashboard.contextType = Context

export default Dashboard