import React,{ Component } from "react";
import { Tab,Header, Form, Segment, Comment } from "semantic-ui-react";
import Moment from "react-moment";

import client from '../../../shared/feathers'
import SellerSingleCompact from "../../Sellers/Single/compact";
import moment  from "moment";

class CommentsCustomer  extends Component{
    state={
        loading:true,
        comments: [],
        user: null,
        saving: false,
        comment:""
    }
    async componentDidMount() {
        this.setState({user:await client.service("user").find()})
        this.reactive = client.service("customer_comments").watch().find({query:{$sort:{id:-1},$limit:10000,customer_id:this.props.customer.id}}).subscribe(async (data)=>this.setState({comments:data.data,loading:false}) )
    }
    async addComment(){
        if(this.state.comment==""){return false}
        this.setState({saving:true})
        await client.service("customer_comments")
        .create({created_at:moment().format("YYYY-MM-DD HH:mm:ss"),user_id: this.state.user.id, customer_id: this.props.customer.id,comment: this.state.comment})
        this.setState({saving:false,comment:""})
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    async delete(entry){
        console.log(entry)
        await client.service("customer_comments").remove(entry)
    }
    render(){
        return (
            <Tab.Pane loading={this.state.loading} attached="bottom" style={{marginTop:0}}>
                <Segment.Group >
                    <Header attached="top">Kommentar schreiben</Header>
                    <Segment attached="bottom">
                        <Form loading={!this.state.user || this.state.saving} onSubmit={this.addComment.bind(this)} attached="bottom">
                            <Form.TextArea value={this.state.comment} onChange={(e,{value})=>this.setState({comment: value})} label="Nachricht" />
                            <Form.Button primary>Speichern</Form.Button>
                        </Form>
                    </Segment>
                </Segment.Group>
                <Comment.Group style={{maxWidth:"100%"}}>
                    {this.state.comments.map((entry,id)=>
                        
                    <Comment>
                        <Comment.Content>
                            <Comment.Author as='a'><SellerSingleCompact seller_id={entry.user_id} /></Comment.Author>
                            <Comment.Metadata><Moment fromNow locale="de">{entry.created_at}</Moment></Comment.Metadata>
                            <Comment.Text>{entry.comment}</Comment.Text>
                            <Comment.Actions>
                                <Comment.Action onClick={()=>this.delete(entry.id)}>Löschen</Comment.Action>
                            </Comment.Actions>
                            <hr/>
                    </Comment.Content>
                </Comment>
                    )}
                    
                </Comment.Group>
            </Tab.Pane>
        )
    }
}

export default CommentsCustomer