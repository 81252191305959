import React,{ Component } from "react";
import {Tab, Grid, Header, Segment, Form,Input,Message} from "semantic-ui-react";
import { HuePicker } from 'react-color';
import client from "../../../shared/feathers";


var strongRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");
class SellerSingleSettings extends Component{
    state = {
        saving:false,
        user:{isAdmin:false},
        companies:[],
        rightGroups:[
            { key: "manager", text: "Manager", value: "manager" },
            { key: "salesman", text: "Verkäufer", value: "salesman" },
            { key: "accountant", text: "Buchhalter", value: "accountant" },
            { key: "mechanic", text: "Mechaniker", value: "mechanic" },
            { key: "carcreator", text: "Fahrzeugerfasser", value: "carcreator" },
        ],
        error:false,
    }

    async componentDidMount(){
        let user = await client.service("user").find()
        if(user.isAdmin==1&&user.permissions=="administrator"){
            this.setState({
                rightGroups:[
                    ...this.state.rightGroups,
                    { key: "administrator", text: "Administrator", value: "administrator" },
                ]
            })
        }
        this.setState({
            user,
            company_id:user.company_id,
            companies:(await client.service("companies").find() ).data
        })
    }
    save(){
        this.setState({saving:true})
        let {isAdmin,salutation,firstname,lastname,email,phone,password,language,permissions,company_id,color} = this.state
        client.service("users").patch(this.props.user.id,{isAdmin,salutation,firstname,lastname,email,phone,password,language,permissions,company_id,color}).then((result)=>{
            this.setState({saving:false,error:false})
        }).catch((e)=>{
            console.log(e)
            this.setState({saving:false,error:true})
        })
    }
    handleChange = (e, { name, value }) => this.setState({ [name]: value })
    
    render(){
        return (
            <Tab.Pane attached="bottom" style={{marginTop:0}}>
                <Grid columns="equal">
                    <Grid.Column>
                        <Segment.Group>
                            <Header attached="top">Mitarbeiter ändern</Header>
                            <Segment>
                                <Form loading={this.state.saving} id="userCreateForm" onSubmit={this.save.bind(this)}>
                                    <Form.Group widths="equal">
                                        <Form.Select defaultValue={this.props.user.salutation} name="salutation" onChange={this.handleChange.bind(this)} required options={[{ key: "Herr", value: "Herr", text: "Herr" }, { key: "Frau", value: "Frau", text: "Frau" }]} fluid width="4" label="Anrede" />
                                        <Form.Input defaultValue={this.props.user.firstname} name="firstname" onChange={this.handleChange.bind(this)} required label="Vorname" />
                                        <Form.Input defaultValue={this.props.user.lastname} name="lastname" onChange={this.handleChange.bind(this)} required label="Nachname" />
                                    </Form.Group>
                                    <Form.Field>
                                        <label>E-Mail</label>
                                        <Input disabled type="email" defaultValue={this.props.user.email} name="email" onChange={this.handleChange.bind(this)} required label="@" />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Telefonnummer</label>
                                        <Input defaultValue={this.props.user.phone} name="phone" onChange={this.handleChange.bind(this)}  />
                                        {this.state.phoneUsed && <small style={{marginTop:"-1em"}} className="helper">Diese Telefonnummer ist bereits in Benutzung</small>}
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Passwort</label>
                                        <Input name="password" onChange={this.handleChange.bind(this)} type="password" />
                                        {!strongRegex.test(this.state.password) && <small style={{marginTop:"-1em"}} className="helper">Mindestens 8 Zeichen, einen Groß- und einen Kleinbuchstaben, sowie eine Nummer.</small>}
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Kalender Farbe</label>
                                        <HuePicker color={ this.state.color || this.props.user.color } onChangeComplete={ (color)=>this.setState({color:color.hex}) } />
                                    </Form.Field>
                                    <Form.Select value={this.state.language||this.props.user.language} name="language" onChange={this.handleChange.bind(this)}  required label="Sprache" options={[{ flag: "de", key: "de", value: "DE", text: "Deutsch" }]} value="DE" />
                                    {this.state.user.isAdmin ==1 && <Form.Dropdown required
                                        name="company_id"
                                        value={this.state.company_id||this.props.company_id}
                                        onChange={this.handleChange.bind(this)} 
                                        label="Unternehmen auswählen"
                                        placeholder='Unternehmen auswählen'
                                        fluid
                                        search
                                        selection
                                        options={
                                            this.state.companies.map((elm)=>({
                                                key: elm.id, text: elm.name, value: elm.id
                                            }))
                                        }
                                    />}
                                    <Form.Select 
                                        name="permissions"
                                        value={this.state.permissions || this.props.user.permissions}
                                        onChange={this.handleChange.bind(this)} 
                                        required label="Rechte" 
                                        options={[
                                            ...this.state.rightGroups
                                    ]}></Form.Select>
                                    {this.state.error && <Message negative >Es ist ein Fehler beim speichern aufgetreten. Bitte überprüfen Sie alle Felder oder kontaktieren Sie den Support.</Message>}
                                    <Form.Button primary>Speichern</Form.Button>
                                    <Form.Checkbox defaultChecked={this.props.user.isAdmin} onClick={()=>this.setState({isAdmin:!this.state.isAdmin})} label="Zugriff zur Administratoren Oberfläche" />

                                </Form>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment >
                            <Form loading={this.state.saving} id="userCreateForm" onSubmit={this.save.bind(this)}>
                                <Form.TextArea label="Notiz" name="description" />
                                <Form.Button primary>Speichern</Form.Button>
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        )
    }
}

export default SellerSingleSettings