import React,{ Component } from "react";
import { Segment, Grid, Form, Header,Button, Image, Message, Input} from "semantic-ui-react";
import Dropzone from 'react-dropzone'

import Order from '../Order';
import IBAN from "iban";
import client from '../../shared/feathers'

var IBANRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");

class Settings  extends Component{
    state={
        images: [],
        loading: true,
        error:false,
        uploadingFile: false,
        saving:false,
        savingMobile:false,
    }
    async uploadingFile(file,field,loadingField,last){
        let reader  = new FileReader();
        reader.addEventListener("load", () =>{
            client.service('uploads')
                .create({uri: reader.result})
                .then(async(response)=>{
                    this.setState({uploadingFile:false, company:{...this.state.company,logo: response.id}})
                }).catch(()=>{
                    this.setState({ uploadingFile:false} );
                }); 
        }, false);
        reader.readAsDataURL(file);
    }
    async onDrop(images){
        this.setState({uploadingFile:true})
        this.uploadingFile(images[0])
    }
    async componentDidMount() {
        let user = await client.service("user").find()
        this.reactive = client.service("companies").watch().get(user.company_id).subscribe(async (data)=>this.setState({company:data,loading:false}),()=>this.setState({loading:false, notFound: true}))
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    async save(){
        this.setState({error:false,saving:true})
        let {mobile_username,mobile_password,logo,name,owner,tax,vat,address,address_extra,postcode,city,phone,fax,payment_BIC,payment_owner,payment_IBAN} = this.state.company
        client.service("companies").patch(this.state.company.id,{mobile_username,mobile_password,logo,name,owner,tax,vat,address,address_extra,postcode,city,phone,fax,payment_BIC,payment_owner,payment_IBAN}).then( ()=>{
            this.setState({error:false,saving:false})
        }).catch((e)=>{
            console.log(e)
            this.setState({error:true,saving:false})
        })
    }
    async connectMobile(){
        this.setState({savingMobile:true})
        let {mobile_username,mobile_password,mobile_mobileSellerId} = this.state.company
        client.service("companies").patch(this.state.company.id,{mobile_username,mobile_password,mobile_mobileSellerId}).then(async ()=>{
            await client.service("mobile").find()
            this.setState({savingMobile:false})
        }).catch((e)=>{
            console.log(e)
            this.setState({savingMobile:false})
        })
    }
    handleChange = (e, { name, value }) =>{
        this.setState({company:{...this.state.company,[name]: value}})
    }
    render(){
        if(this.state.loading){
            return <Segment basic><Segment loading placeholder></Segment></Segment>
        }
        return (
            <Segment basic>
                <Segment.Group>
                    <Header attached>
                        <Order trigger={<Button floated="right" positive>Upgraden</Button>} />
                        Packet:
                        <Header.Subheader><b>{this.state.company.trail}-Tage</b> Testversion</Header.Subheader>
                    </Header>
                </Segment.Group>
                {this.state.error && <Message negative >Es ist ein Fehler beim speichern aufgetreten. Bitte überprüfen Sie alle Felder oder kontaktieren Sie den Support.</Message>}
                <Grid columns="2">
                    <Grid.Column>
                        <Segment.Group id="general-settings">
                            <Header attached="top">Allgemeine Kontaktdaten</Header>
                            <Segment attached="bottom">
                                <Form loading={this.state.saving} onSubmit={this.save.bind(this)}>
                                    <Form.Group widths="2">
                                        <Form.Field style={{position:"relative"}}>
                                            <label>Firmen Logo</label>
                                            <Dropzone onDrop={this.onDrop.bind(this)}  accept="image/*">
                                                {({getRootProps, getInputProps, isDragActive}) => (
                                                    <Segment loading={this.state.uploadingFile} placeholder={!isDragActive} style={{minHeight:100}} {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {!this.state.company.logo ? <span>Ziehe die Datei hierhin, um sie hochzuladen.</span>:<div><Image height={60} src={"https://api.carbill.dev.tecoyo.com/uploads/"+this.state.company.logo} /></div>}
                                                    </Segment>
                                                )}
                                            </Dropzone>
                                            {this.state.company.logo && <Button onClick={(e) =>{this.handleChange(e,{name:"logo",value:null})}} style={{position:"absolute", top:40,right:10}} size="mini">Löschen</Button>}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input onChange={this.handleChange.bind(this)} name="name" required defaultValue={this.state.company.name} style={{marginBottom:10}} label="Firmenname" />
                                            <Form.Input onChange={this.handleChange.bind(this)} name="owner" defaultValue={this.state.company.owner} label="Inhaber" />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths="2">
                                        <Form.Input onChange={this.handleChange.bind(this)} name="tax" defaultValue={this.state.company.tax} label="Steuernummer" />
                                        <Form.Input onChange={this.handleChange.bind(this)} name="vat" defaultValue={this.state.company.vat} label="USt-IdNr." />
                                    </Form.Group>
                                    <Form.Input onChange={this.handleChange.bind(this)} name="address" required defaultValue={this.state.company.address} label="Adresse" />
                                    <Form.Input onChange={this.handleChange.bind(this)} name="address_extra" defaultValue={this.state.company.address_extra} placeholder="Adresszusatz" />
                                    <Form.Group widths="2">
                                        <Form.Input onChange={this.handleChange.bind(this)} name="postcode" required label="PLZ"  defaultValue={this.state.company.postcode} />
                                        <Form.Input onChange={this.handleChange.bind(this)} name="city" required label="Stadt"  defaultValue={this.state.company.city} />
                                    </Form.Group>
                                    <Form.Input onChange={this.handleChange.bind(this)} name="phone" label="Telefon" defaultValue={this.state.company.phone}/>
                                    <Form.Input onChange={this.handleChange.bind(this)} name="fax" label="Fax" defaultValue={this.state.company.fax}/>
                                    <Button fluid primary >Speichern</Button>
                                </Form>
                            </Segment>   
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column>

                        <Segment.Group id="bank-settings">
                            <Header attached="top">
                                Bankverbindung
                                <Header.Subheader>Wird unter den Rechnungen und Verträgen angezeigt</Header.Subheader>
                            </Header>
                            <Segment attached>
                                <Form onSubmit={this.save.bind(this)} loading={this.state.saving}>
                                    <Form.Input onChange={this.handleChange.bind(this)} name="payment_owner" label="Kontoinhaber" defaultValue={this.state.company.payment_owner}/>
                                    <Form.Field>
                                        <label>IBAN</label>
                                        <Input onChange={this.handleChange.bind(this)} name="payment_IBAN" value={this.state.company.payment_IBAN} pattern="^DE\d{2}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{2}|DE\d{20}$" />
                                        {!IBAN.isValid(this.state.payment_IBAN) && <small style={{marginTop:"-1em"}} className="helper">Die angegebene IBAN entspricht nicht dem Standardformat. Bitte überprüfen Sie diese, vor dem Speichern.</small>}
                                    </Form.Field>
                                    <Form.Input onChange={this.handleChange.bind(this)} name="payment_BIC" label="BIC" defaultValue={this.state.company.payment_BIC}/>
                                    <Form.Button>Speichern</Form.Button>
                                </Form>
                            </Segment>
                        </Segment.Group>
                        <Segment.Group id="mobile-settings">
                            <Header attached="top">
                                <Image src='/images/mobile.png' style={{ height: 20, width: 'auto' }} size='small' /> mobile.de
                                <Header.Subheader>Bitte tragen Sie Ihre API-Zugangsdaten in die unteren Felder ein. Hiermit sind nicht die Standard Mobile.de Zugangsdaten gemeint.</Header.Subheader>
                            </Header>
                            <Segment attached>
                                <Form onSubmit={this.connectMobile.bind(this)} loading={this.state.savingMobile}>
                                    {!!this.state.company.mobile_error && <Message >{this.state.company.mobile_error}</Message>}
                                    <Form.Group widths="equal">
                                        <Form.Input value={this.state.company.mobile_username} onChange={this.handleChange.bind(this)} name="mobile_username" label="Benutzername" />
                                        <Form.Input type="password" onChange={this.handleChange.bind(this)} name="mobile_password" label="Seller API Passwort" />
                                    </Form.Group>
                                    {this.state.company.mobile_sellers && <Form.Select
                                        value={this.state.company.mobile_mobileSellerId} onChange={this.handleChange.bind(this)} name="mobile_mobileSellerId" label="Unternehmen"
                                        options={JSON.parse(this.state.company.mobile_sellers).map(el=>({
                                            key:el.mobileSellerId,
                                            value:el.mobileSellerId,
                                            text: el.companyName
                                        }))}     
                                    />}
                                </Form>
                            </Segment>
                            <Button attached="bottom" onClick={this.connectMobile.bind(this)} >Speichern</Button>
                        </Segment.Group>

                            <Segment.Group>
                                <Header attached="top"><Image src='/images/autoscout.png' style={{ height: 20, width: 'auto' }} size='small' /> Autoscout24.de</Header>
                                <Segment attached style={{textAlign:"center"}}>
                                    <Button disabled>Jetzt Account verknüpfen</Button>
                                </Segment>
                            </Segment.Group>

                    </Grid.Column>
                </Grid>
            </Segment>
        )
    }
}

export default Settings