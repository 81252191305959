import React,{ Component } from "react";
import { Segment, Header, Grid, Dropdown, Icon, Label, Button, Table } from "semantic-ui-react";
import client from "../../shared/feathers";
import Currency from "react-currency-formatter";
import moment from "moment"
import CustomerCompact from "../Customers/compact";

class ReceiptSingle extends Component{
    state={
        loading:true,
        error:false,
        notFound: false,
        company:null
    }
    componentDidMount() {
        this.reactive = client.service("receipts").watch().get( this.props.match.params.id).subscribe(this.update.bind(this),()=>this.setState({loading:false, notFound: true}))
    }
    async update(data){
        let company = await client.service("companies").get((await client.service("user").find()).company_id)
        data["created_at"] = moment(data["created_at"]).format("DD-MM-YYYY")
        data["until"] = moment(data["created_at"]).format("DD-MM-YYYY")
        data["products"] = JSON.parse(data["products"])
        this.setState({...data,company,loading:false})
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    async paying(payment_type){
        await client.service("receipts").patch(this.state.id,{status:"payed",payment_type})
        let user=await client.service("users").get(this.state.user_id)
        let customer=await client.service("customers").get(this.state.customer_id)
        let now = moment()
        await client.service("users").patch(user.id,{sales:user.sales + this.state.price})
        await client.service("customers").patch(customer.id,{sales:customer.sales + this.state.price})
        let sales_user = (await client.service("sales_user_daily").find({query:{user_id:user.id,year:now.year(),month:now.month()+1,day:now.date()}})).data
        if(sales_user.length > 0){
            console.log("found")
            await client.service("sales_user_daily").patch(sales_user[0].id,{default:user.sales + this.state.price})
        }else{
            console.log("notfound")
            await client.service("sales_user_daily").create({user_id:user.id,default:this.state.price,year:now.year(),month:now.month()+1,day:now.date()})
        }
        sales_user = (await client.service("sales_user_monthly").find({query:{user_id:user.id,year:now.year(),month:now.month()+1}})).data
        if(sales_user.length > 0){
            await client.service("sales_user_monthly").patch(sales_user[0].id,{default:user.sales + this.state.price})
        }else{
            await client.service("sales_user_monthly").create({user_id:user.id,default:this.state.price,year:now.year(),month:now.month()+1})
        }
         sales_user = (await client.service("sales_user_daily").find({query:{year:now.year(),month:now.month()+1,day:now.date()}})).data
        if(sales_user.length > 0){
            await client.service("sales_user_daily").patch(sales_user[0].id,{default:user.sales + this.state.price})
        }else{
            await client.service("sales_user_daily").create({default:this.state.price,year:now.year(),month:now.month()+1,day:now.date()})
        }
        sales_user = (await client.service("sales_monthly").find({query:{year:now.year(),month:now.month()+1}})).data
        if(sales_user.length > 0){
            await client.service("sales_monthly").patch(sales_user[0].id,{default:user.sales + this.state.price})
        }else{
            await client.service("sales_monthly").create({default:this.state.price,year:now.year(),month:now.month()+1})
        }
        /*--------- */
    }
    render(){
        if(this.state.loading){
            return <Segment basic><Segment loading placeholder></Segment></Segment>
        }
        if(this.state.notFound){
            return <Segment basic ><Segment placeholder color="red"> <Header icon><Icon name='search' /> Kunde wurde nicht gefunden.</Header> </Segment></Segment>
        }
        return (<Segment basic>
            <Segment.Group  style={{backgroundColor:"#fff"}}>
                <Grid attached="top" columns="equal" >
                    <Grid.Column>
                        <Segment style={{boxShadow:"none", border:0}}>
                            <Header>
                                Rechnung #{this.state.id}
                                <Header.Subheader >{this.state.created_at}</Header.Subheader>
                            </Header>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column style={{textAlign:"center", paddingTop:30}}>
                        {this.state.status == "open" && <Label color="red" >Offen</Label> }
                        {this.state.status == "payed" && <Label color="green">Bezahlt</Label> }
                        {this.state.status == "void" && <Label color="red" basic>Storniert</Label> }
                    </Grid.Column>
                    <Grid.Column style={{textAlign:"right", paddingTop:29, paddingRight:30}}>
                        <Button.Group style={{float:"right",marginRight:10}} >
                            {/*<Button icon labelPosition='right' positive><Icon name="add" /> Zahlung</Button>*/}
                            {/*<Button onClick={()=>this.props.history.push("/receipts/add")} icon labelPosition='right' ><Icon name="edit" /> Bearbeiten</Button>*/}
                            {!!(this.state.document) && <Button as="a" href={"https://api.carbill.dev.tecoyo.com/uploads/"+this.state.document} target="_blank" icon labelPosition='right'><Icon name="file pdf" /> Rechnung</Button>}
                            {/*this.state.status=="payed" && <Button icon labelPosition='right'><Icon name="file pdf" /> Quittung</Button>*/}
                            {this.state.status =="open" && <Button onClick={()=>client.service("receipts").patch(this.state.id,{status:"void"})} labelPosition='right' icon negative><Icon name="trash" /> Stornieren</Button>}
                            {this.state.status =="open" &&<Dropdown text='Bezahlen' icon='money' floating labeled button positive className='icon positive'>
                                <Dropdown.Menu>
                                <Dropdown.Header content='Bezahlmethode' />
                                    <Dropdown.Item onClick={()=>this.paying("Bar")}>Bar</Dropdown.Item>
                                    <Dropdown.Item onClick={()=>this.paying("Finanzierung")}>Finanzierung</Dropdown.Item>
                                    <Dropdown.Item onClick={()=>this.paying("EC")}>EC/Kreditkarte</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>}
                        </Button.Group>
                    </Grid.Column>
                </Grid>
                <Segment.Group attached horizontal>
                    <Segment>
                        <div>
                            <Header>Kunde</Header>
                            <b><CustomerCompact id={this.state.customer_id} field="company" /><CustomerCompact id={this.state.customer_id} field="firstname" /> <CustomerCompact id={this.state.customer_id} field="lastname" /></b><br/>
                                <CustomerCompact id={this.state.customer_id} field="tax" /> <CustomerCompact id={this.state.customer_id} field="vat" /><br/>
                                <CustomerCompact id={this.state.customer_id} field="address" /><br/>
                                <CustomerCompact id={this.state.customer_id} field="postcode" />, <CustomerCompact id={this.state.customer_id} field="city" /><br/>
                        </div>
                    </Segment>
                    <Segment>
                        <div>
                            <Header>Rechnung von:</Header>
                            <b>{this.state.company.name}</b><br/>
                            {this.state.company.vat?this.state.company.vat:this.state.company.tax}<br/>
                            {this.state.company.address}<br/>
                            {this.state.company.postcode}, {this.state.company.city}<br/>
                        </div>
                    </Segment>
                </Segment.Group>
                <Table attached>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Artikel</Table.HeaderCell>
                            <Table.HeaderCell>Beschreibung</Table.HeaderCell>
                            <Table.HeaderCell>Menge</Table.HeaderCell>
                            <Table.HeaderCell>Preis</Table.HeaderCell>
                            <Table.HeaderCell>Steuer</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.state.products.map((el,index)=>(<Table.Row key={index}>
                            <Table.Cell><b>{el.title}</b></Table.Cell>
                            <Table.Cell>{el.description}</Table.Cell>
                            <Table.Cell>x{el.amount}</Table.Cell>
                            <Table.Cell><Currency quantity={el.price} currency="EUR" /></Table.Cell>
                            <Table.Cell>19 %</Table.Cell>
                        </Table.Row>))}
                    </Table.Body>
                </Table>
                <Segment.Group horizontal attached="bottom" >
                    <Segment style={{maxWidth:"50%"}}>
                        <Segment>{this.state.additional}</Segment>
                    </Segment>
                    <Segment>
                        <Table definition>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell textAlign="right">Zwischensumme</Table.Cell>
                                    <Table.Cell><Currency quantity={this.state.price} currency="EUR" /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell textAlign="right">Mwst</Table.Cell>
                                    <Table.Cell><Currency quantity={this.state.price*0.19} currency="EUR" /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell textAlign="right">Gesamtsumme</Table.Cell>
                                    <Table.Cell><Currency quantity={this.state.price*1.19} currency="EUR" /></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Segment>
                </Segment.Group>
            </Segment.Group>
        </Segment>)
    }
}

export default ReceiptSingle