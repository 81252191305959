import React,{ Component } from "react";
import { Grid, Header, Table, Modal, Form, Button,Input, Message, Divider, Loader } from "semantic-ui-react";
import moment from 'moment-timezone';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import Context from "../context";
import client from "../../shared/feathers";
import 'moment/locale/de';

class EventSingle extends Component{
    state={
        users:[],
        customers:[],
        cars:[],
        saving:false,
        error:false,
        loading:true,
    }

    async componentDidMount(){
        let event ={};
        this.setState({start:this.props.start,end:this.props.end})
        if(this.props.event_id){
            event = await client.service("events").get(this.props.event_id)
            event.start = moment(event.start).format("DD.MM.YYYY HH:mm")
            event.end = moment(event.end).format("DD.MM.YYYY HH:mm")
        }
        let cars = (await client.service("cars").find()).data.map((elm,index)=>({
            key:elm.id,
            value:elm.id,
            text:(elm.firstname||elm.company)?(elm.company?elm.company:elm.firstname+" "+elm.lastname):elm.manufacturer+" "+elm.type,
            description:(elm.firstname||elm.company)?elm.id:elm.identification
        }))
        let users = (await client.service("users").find()).data.map((elm,index)=>({
            key:elm.id,
            value:elm.id,
            text:(elm.firstname||elm.company)?(elm.company?elm.company:elm.firstname+" "+elm.lastname):elm.manufacturer+" "+elm.type,
            description:(elm.firstname||elm.company)?elm.id:elm.identification
        }))
        
        this.setState({cars,users,loading:false,...event})
    }

    async search(field,query){
        this.setState({[field]:(await client.service(field).find({query})).data.map((elm,index)=>({
            key:elm.id,
            value:elm.id,
            text:(elm.firstname||elm.company)?(elm.company?elm.company:elm.firstname+" "+elm.lastname):elm.manufacturer+" "+elm.type,
            description:(elm.firstname||elm.company)?elm.id:elm.identification
        }))});
    }

    handleChange = (e, { name,value }) => this.setState({ [name]:value })
    handleChangeUser = async (e, { name,value }) => {
        let user = await client.service("users").get(value)
        this.setState({ "user_id":value,color:user.color })
    }
    async save(){
        this.setState({error:false, loading:true})
        let {title,user_id,customer_name,car_id,description,start,end,location,color} = this.state
        try {
            if(this.props.event_id!=0 && this.props.event_id){
                await client.service("events").patch(this.state.id,{color,title,user_id,customer_name,car_id,description,start:moment.tz(this.state.start,"DD.MM.YYYY HH:mm","Europe/Berlin").format(),end:moment.tz(this.state.end,"DD.MM.YYYY HH:mm","Europe/Berlin").format(),location})
            }else{
                await client.service("events").create({color,title,user_id,customer_name,car_id,description,start:moment.tz(this.state.start,"DD.MM.YYYY HH:mm","Europe/Berlin").format(),end:moment.tz(this.state.end,"DD.MM.YYYY HH:mm","Europe/Berlin").format(),location})
            }
            this.setState({error:false, loading:false})
            this.context.closeEvent()
        }catch(e){
            this.setState({error:e, loading:false})
        }
        
    }
    async delete(){
        await client.service("events").remove(this.state.id)
        this.context.closeEvent()
    }


    render(){
        if(this.state.loading){
            return <Loader active />
        }
        return (
            <div style={{padding:20}} >
                {this.state.error && <Message >{this.state.error.message}</Message>}
                <Form onSubmit={this.save.bind(this)}>
                    <Form.Input 
                    name="title" label="Title" requierd
                    onChange={this.handleChange.bind(this)} 
                    value={this.state.title}   />
                    <Form.Field>
                        <label>Von:</label>
                        <DateTimeInput
                            name="start" localization="de" dateFormat="DD.MM.YYYY"
                            onChange={this.handleChange.bind(this)}
                            value={this.state.start?this.state.start:this.props.start}
                            iconPosition="left"
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Bis:</label>
                        <DateTimeInput
                            name="end" localization="de" dateFormat="DD.MM.YYYY"
                            onChange={this.handleChange.bind(this)}
                            value={this.state.end?this.state.end:this.props.end}
                            iconPosition="left"
                        />
                    </Form.Field>
                    <Form.Select 
                        onChange={this.handleChangeUser.bind(this)} 
                        value={this.state.user_id?this.state.user_id:this.props.user_id}
                        label="Mitarbeiter"
                        name="user_id"
                        noResultsMessage='Es wurde keine Mitarbeiter gefunden.' 
                        onSearchChange={(e,{searchQuery})=>this.search("users",{
                            $or:[
                                {firstname:{$like: "%"+searchQuery+"%"}},
                                {lastname:{$like: "%"+searchQuery+"%"}},
                                {id:{$like: "%"+searchQuery+"%"}},
                            ]
                        })}
                        options={this.state.users} search />
                    <Form.Input 
                    name="customer_name" label="Kunde"
                    onChange={this.handleChange.bind(this)} 
                    value={this.state.customer_name}   />
                    <Form.Input label="Ort" value={this.state.location} onChange={this.handleChange.bind(this)} name="location"  />
                    <Form.Select 
                        onChange={this.handleChange.bind(this)} 
                        value={this.state.car_id}
                        name="car_id"
                        noResultsMessage='Es wurde keine Autos gefunden.' 
                        onSearchChange={(e,{searchQuery})=>this.search("cars",{
                            $or:[
                                {type:{$like: "%"+searchQuery+"%"},},
                                {manufacturer:{$like: "%"+searchQuery+"%"},},
                                {identification:{$like: "%"+searchQuery+"%"},},
                                {internal_id:{$like: "%"+searchQuery+"%"},}
                            ]
                        })}
                        options={this.state.cars?this.state.cars:this.props.cars} label="Fahrzeug"
                        search={options=>this.state.cars}   />
                        <Form.TextArea value={this.state.description} name="description" label="Notizen" onChange={this.handleChange.bind(this)} />
                    <Form.Button primary fluid >Speichern</Form.Button>
                    {this.props.event_id != 0 && <Button as="a" onClick={this.delete.bind(this)} negative fluid>Löschen</Button>}
                </Form>

            </div>
        )
    }
}

EventSingle.contextType = Context
export default EventSingle