import React, { Component } from "react";
import { Segment, Menu, Icon, Loader } from "semantic-ui-react";
import SellersList from './list'
import SellersNew from './new'
import client from "../../shared/feathers";

class GetName extends Component {
    state={
        loading:true,
        user:{firstname:"",lastname:""}
    }
    async componentDidMount(){
        try{
            let user = await client.service("users").get(this.props.id)
            console.log(user)
            if(user){
                this.setState({user,loading:false})
            }else{
                this.setState({user:{firstname:"Webseite",lastname:""},loading:false})
            }
        }catch(e){
            this.setState({user:{firstname:"Webseite",lastname:""},loading:false})
        }
    }
    render() {
        if(this.state.loading){
            return <Loader active inline size="mini"/>
        }
        return this.state.user.firstname+" "+ this.state.user.lastname
    }
}

export default GetName