import React, { Component } from "react";
import {Modal, Segment, Header, Loader, Icon, Grid, List, Button,Image,Message,GridColumn, Menu,Label } from "semantic-ui-react";
import moment from 'moment'
import client from "../../shared/feathers";
import arrayMove from 'array-move';
import isJSON from 'is-json'
import Errors from "./sync/errors";

import AdSettings from "./AdSettings";
import CarFiles from "./files";
import CarEdit from "./edit";


class CarSingle extends Component {
    state={
        loading:true,
        error:false,
        notFound: false,
        car: null,
        uploadingImage: false,
        uploadingFile: false,
    }
    componentDidMount() {
        this.reactive = client.service("cars").watch().get( this.props.match.params.id).subscribe(this.update.bind(this),()=>this.setState({loading:false, notFound: true}))
    }
    async update(data){
        data["admission_first"] = moment(data["admission_first"]).format("YYYY-MM-DD")
        // console.log(data)
        data["mobile_adSettings"] = JSON.parse(data["mobile_adSettings"])
        data["images"] = JSON.parse(data["images"])
        data["files"] = JSON.parse(data["files"])
        data["admission_first"] = moment(data["admission_first"]).format("DD-MM-YYYY")
        this.setState({car:data,loading:false})
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    
    handleChange = (e, { name,value }) => this.setState({ car:{...this.state.car, [name]:value } })
    
    save(cb,ignore){
        this.setState({saving:true})
        client.service("cars").patch(this.state.car.id,{...this.state.car,mobile_adSettings:JSON.stringify(this.state.car.mobile_adSettings),images:JSON.stringify(this.state.car.images),files:JSON.stringify(this.state.car.files)}).then((result)=>{
            if(!ignore){
                if(this.state.car.mobile==1 || this.state.car.mobile==true){
                    this.uploadMobile()
                }else{
                    deleteMobile()
                }
            }
            this.setState({saving:false,error:false})
            if(cb){
                cb()
            }
        }).catch((e)=>{
            console.log(e)
            this.setState({saving:false,error:true,errMsg:e})
        })
    }


    async uploadMobile(){
        this.setState({car:{...this.state.car,mobile:1}},()=>{
            console.log("step1")
            this.save(async()=>{
                console.log("step2")
                this.setState({loading_mobile:true})
                client.service("mobile").patch(this.state.car.id,{push:true}).then(()=>{
                    console.log("step3")
                    this.setState({loading_mobile:false})
                }).catch((e)=>{
                    console.log(e)
                    this.setState({loading_mobile:false})
                })
            },true)
        })
    }
    async deleteMobile() {

        this.setState({car:{...this.state.car,mobile:0}},()=>{

            this.save(async()=>{
                this.setState({loading_mobile:true})
                client.service("mobile").remove(this.state.car.id,{}).then(()=>{
                    this.setState({loading_mobile:false})
                }).catch((e)=>{
                    this.setState({loading_mobile:false})
                })
            })
        })
    }
    render() {

        if(this.state.loading){
            return <Segment basic><Segment loading placeholder></Segment></Segment>
        }
        if(this.state.notFound){
            return <Segment basic ><Segment placeholder color="red"> <Header icon><Icon name='search' /> Kunde wurde nicht gefunden.</Header> </Segment></Segment>
        }
        return (
            <Segment basic loading={this.state.saving} style={{paddingTop:40}} >
                <Segment.Group attached="bottom" style={{ width: window.innerWidth - 120,left: 120, right: 0, position: "fixed", top: -1, bottom: "auto", zIndex: 100 }}>
                    <Header attached="top">
                        {this.state.car.manufacturer} {this.state.car.type} 
                        <Header.Subheader>{this.state.car.internal_id} - {this.state.car.identification}</Header.Subheader>
                    </Header>
                    <Menu attached="bottom">
                        <Menu.Item>
                            <Button onClick={this.uploadMobile.bind(this)} loading={this.loading_mobile} icon={<img src='/images/mobile.png' height={13} style={{marginRight:5}} />} content="Hochladen"/></Menu.Item>
                        <Menu.Item disabled><Button disabled><img src='/images/autoscout.png' height={13} style={{marginRight:5}}/>Hochladen</Button></Menu.Item>
                        <Menu.Menu position='right'>
                            <Menu.Item><Button primary icon labelPosition='left' onClick={this.save.bind(this)} ><Icon name="save" />Speichern</Button></Menu.Item>
                            <Menu.Item><Button negative icon><Icon name="delete" /></Button></Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </Segment.Group>
                <Grid columns="2">
                    <Grid.Row>
                        <Grid.Column>
                            <CarEdit  car={this.state.car} setState={this.setState.bind(this)} />
                            <AdSettings saving={this.state.saving} onChange={AdSettings=>this.setState({car:{...this.state.car,mobile_adSettings:AdSettings}})} car={this.state.car} />
                        </Grid.Column>
                        <Grid.Column>
                            <Segment.Group>
                                <Header attached="top" >
                                    {this.state.car.mobile_id && <Label style={{marginLeft:-1}} color='green' ribbon >Aktiv</Label>}
                                    {!this.state.car.mobile_id && <Label style={{marginLeft:-1}} color='red' basic ribbon >Deaktiviert</Label>}
                                    <Image src='/images/mobile.png' style={{ height: 20, width: 'auto' }} size='small' /> mobile.de
                                </Header>
                                {this.state.loading_mobile && <Segment><Loader active /></Segment>}
                                {!!this.state.car.mobile_errors && <Segment loading={this.state.loading_mobile} >
                                <Message negative>{isJSON(this.state.car.mobile_errors)?JSON.parse(this.state.car.mobile_errors).map(el=>(<li key={el.key}>{Errors[el.key]}</li>)):this.props.car.mobile_errors}</Message>
                                </Segment>}
                            </Segment.Group>
                            <CarFiles car={this.state.car} setState={this.setState.bind(this)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }
}

export default CarSingle