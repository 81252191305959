import React, { Component } from "react";
import { Segment, Table, Button, Message, Header, Grid, Divider } from "semantic-ui-react";

class Package extends Component {
    state={
        selected: "",
        package:{}
    }
    render() {
        if(this.state.selected!=""){
            return (
                <div>
                <Divider horizontal>Paket</Divider>
                <Segment attached="top">
                    <Header floated="left" style={{marginBottom:0}}>
                        {this.state.package.name}
                        <Header.Subheader>{this.state.package.users} max. Mitarbeiter, Speicherplatz ∞, E-Mail und Telefon Support, DSVGO optimiert</Header.Subheader>
                    </Header>
                    <Header floated="right" style={{marginBottom:0, textAlign:"right"}}>
                        {this.state.package.price} €
                        <Header.Subheader>im Monat inkl. MwSt.</Header.Subheader>
                    </Header>
                    <div style={{clear:"both"}} />
                </Segment>
                <Button primary attached="bottom" size="mini" onClick={()=>this.setState({selected:""})}>Bearbeiten</Button>
                </div>
            )
        }
        return (
            <Grid >
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <Segment.Group  raised={this.state.selected == 1}>
                            <Segment attached="top" inverted color="orange" >Standart</Segment>
                            <Segment attached>
                                <Header as='h1' icon style={{width:"100%"}}>
                                    99,00 €
                                    <Header.Subheader><small>zzgl. MwSt pro Monat</small></Header.Subheader>
                                </Header>
                            </Segment>
                            <Table attached definition>
                                <Table.Body>
                                    <Table.Row >
                                        <Table.Cell>Speicherplatz</Table.Cell>
                                        <Table.Cell>Unbegrenzt</Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell>Funktionen</Table.Cell>
                                        <Table.Cell>Unbegrenzt</Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell>Max. Benutzer</Table.Cell>
                                        <Table.Cell>2</Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell>Support</Table.Cell>
                                        <Table.Cell>E-Mail & Telefon</Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell>Datensicherheit</Table.Cell>
                                        <Table.Cell>DSGVO optimiert</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <Button disabled={this.state.selected == 1} onClick={()=>this.setState({selected:1,package:{name:"Standart",price:"99,00", users:2}})} attached="bottom" color="orange">{this.state.selected == 1?"Ausgewählt":"Auswählen"}</Button>
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment.Group  raised={this.state.selected == 2}>
                            <Segment attached="top" inverted color="red"  >Premium</Segment>
                            <Segment  attached>
                                <Header as='h1' icon style={{width:"100%"}}>
                                    149,00 €
                                    <Header.Subheader><small>zzgl. MwSt pro Monat</small></Header.Subheader>
                                </Header>
                            </Segment>
                            <Table attached definition>
                                <Table.Body>
                                    <Table.Row >
                                        <Table.Cell>Speicherplatz</Table.Cell>
                                        <Table.Cell>Unbegrenzt</Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell>Funktionen</Table.Cell>
                                        <Table.Cell>Unbegrenzt</Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell>Max. Benutzer</Table.Cell>
                                        <Table.Cell>6</Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell>Support</Table.Cell>
                                        <Table.Cell>E-Mail & Telefon</Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell>Datensicherheit</Table.Cell>
                                        <Table.Cell>DSGVO optimiert</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <Button disabled={this.state.selected == 2} onClick={()=>this.setState({selected:2,package:{name:"Premium",price:"149,00", users:6}})} attached="bottom" color="red">{this.state.selected == 2?"Ausgewählt":"Auswählen"}</Button>
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment.Group  raised={this.state.selected == 3}>
                            <Segment attached="top" inverted color="violet" >Ultimate</Segment>
                            <Segment attached>
                                <Header as='h1' icon style={{width:"100%"}}>
                                    249,00 €
                                    <Header.Subheader><small>zzgl. MwSt pro Monat</small></Header.Subheader>
                                </Header>
                            </Segment>
                            <Table attached definition>
                                <Table.Body>
                                    <Table.Row >
                                        <Table.Cell>Speicherplatz</Table.Cell>
                                        <Table.Cell>Unbegrenzt</Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell>Funktionen</Table.Cell>
                                        <Table.Cell>Unbegrenzt</Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell>Max. Benutzer</Table.Cell>
                                        <Table.Cell>10</Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell>Support</Table.Cell>
                                        <Table.Cell>E-Mail & Telefon</Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell>Datensicherheit</Table.Cell>
                                        <Table.Cell>DSGVO optimiert</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <Button  disabled={this.state.selected == 3} onClick={()=>this.setState({selected:3,package:{name:"Ultimate",price:"249,00", users:10}})} attached="bottom" color="violet">{this.state.selected == 3?"Ausgewählt":"Auswählen"}</Button>
                        </Segment.Group>
                    </Grid.Column>
                </Grid.Row>
                <Divider horizontal>Sie brauchen mehr Benutzer?</Divider>
                <Grid.Row width="1" style={{textAlign:"center"}}>
                    <Grid.Column>Kontaktieren Sie uns per E-Mail (support@autodigi.de) oder Telefon (0176 848 251 38)</Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default Package