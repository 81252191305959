import React,{ Component } from "react";
import { Segment, Button, Message, Table, Form, Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import MaskedInput from 'react-text-mask'
import Currency from "react-currency-formatter";
import client from '../../shared/feathers'
import CustomerCompact from "../Customers/compact";
import moment from "moment";
import queryString from "query-string";

class ReceiptAdd extends Component{
    state={
        saving:false,
        error:null,
        newItem_amount:0,
        newItem_price:0,
        price:0,
        customer_id:null,
        customers:[],
        customer:null,
        created_at: moment().format("DD-MM-YYYY"),
        until: moment().add(15,"days").format("DD-MM-YYYY"),
        products: []
    }
    async componentDidMount(){
        const parsed = queryString.parse(this.props.location.search);
        if(parsed.customer_id){
            let customer = await client.service("customers").get(parsed.customer_id)
            this.setState({
                customer: customer,
                customer_id:customer.id,
                customers:[{
                    key: customer.id,
                    value: customer.id,
                    text: customer.firstname?customer.firstname+" "+customer.lastname:customer.company,
                    description: customer.id
                }]
            })
        }
        
        this.setState({customers:(await client.service("customers").find()).data.map((elm,index)=>({
            key:elm.id,
            value:elm.id,
            text:(elm.firstname||elm.company)?(elm.company?elm.company:elm.firstname+" "+elm.lastname):elm.manufacturer+" "+elm.type,
            description:(elm.firstname||elm.company)?elm.id:elm.identification
        }))});
    }
    handleChange = (e, { name,value }) => this.setState({ [name]:value })
    handleCheckbox = (e,{name}) => this.setState({[name]: !this.state[name]})
    
    async search(field,query){
        this.setState({[field]:(await client.service(field).find({query})).data.map((elm,index)=>({
            key:elm.id,
            value:elm.id,
            text:(elm.firstname||elm.company)?(elm.company?elm.company:elm.firstname+" "+elm.lastname):elm.manufacturer+" "+elm.type,
            description:(elm.firstname||elm.company)?elm.id:elm.identification
        }))});
    }
    
    async handleChangeCustomer (e,{value}) {
        this.setState({
            customer_id: value,
            customer:await client.service("customers").get(value),
            holding:false
        })
    }
    addProduct(){
        let products = [...this.state.products,{title:this.state.newItem_title,description:this.state.newItem_description,price:Number(this.state.newItem_price),amount:Number(this.state.newItem_amount)}]
        
        this.setState({
            price:products.map((el)=>el.price*el.amount).reduce((acc,el)=>acc+el),
            newItem_title:"", newItem_description:"", newItem_price:0, newItem_amount:0,
            products
        })
    }
    removeProduct(index){
        this.setState({
            price:this.state.products.filter((el,i)=>i!=index).length>0?this.state.products.filter((el,i)=>i!=index).map((el)=>el.price*el.amount).reduce((acc,el)=>acc+el):0,
            products:this.state.products.filter((el,i)=>i!=index)
        })
    }
    async save(status){
        if(!this.state.customer_id){
            return this.setState({ error:"Bitte geben Sie einen Kunden an." })
        }
        if(this.state.products.length==0){
            return this.setState({ error:"Bitte fügen Sie Produkte zu der Rechnung hinzu." })
        }
        this.setState({saving:true,error:null})
        let user = await client.service("user").find()
        let {additional,created_at,until,customer_id,products,price} =this.state
        client.service("receipts").create({status,user_id:user.id,additional,created_at:moment(created_at,"DD-MM-YYYY").format("YYYY-MM-DD"),until:moment(until,"DD-MM-YYYY").format("YYYY-MM-DD"),customer_id,products:JSON.stringify(products),price}).then(result=>this.props.history.push("/receipts/view/"+result.id)).catch((e)=>{
            console.log(e)
            this.setState({error:"Es ist ein Fehler beim speichern aufgetreten. Bitte überprüfen Sie alle Felder oder kontaktieren Sie den Support.",saving:false})
        })
    }
    render(){
        return (<Segment basic>
            <Form as="div" loading={this.state.saving}>
                <Segment.Group >
                    <Segment.Group horizontal style={{backgroundColor:"#fff", borderRadius: ".28571429rem"}}>
                        <Segment>
                            <Form.Select required value={this.state.customer_id} name="customer_id" label="Kunde" placeholder='Kunden auswählen' fluid search noResultsMessage='Es wurde keine Kunden gefunden.' 
                                onSearchChange={(e,{searchQuery})=>this.search("customers",{
                                    $or:[
                                        {company: {$like:"%"+searchQuery+"%"}},
                                        {firstname: {$like:"%"+searchQuery+"%"}},
                                        {lastname: {$like:"%"+searchQuery+"%"}},
                                        {id: {$like:"%"+searchQuery+"%"}},
                                    ]
                                })}
                                options={this.state.customers}  
                                onChange={this.handleChangeCustomer.bind(this)}
                                />
                            {this.state.customer && <div>
                                <b><CustomerCompact id={this.state.customer_id} field="company" /><CustomerCompact id={this.state.customer_id} field="firstname" /> <CustomerCompact id={this.state.customer_id} field="lastname" /></b><br/>
                                <CustomerCompact id={this.state.customer_id} field="tax" /> <CustomerCompact id={this.state.customer_id} field="vat" /><br/>
                                <CustomerCompact id={this.state.customer_id} field="address" /><br/>
                                <CustomerCompact id={this.state.customer_id} field="postcode" />, <CustomerCompact id={this.state.customer_id} field="city" /><br/>
                                
                            </div>}
                        </Segment>
                        <Segment>
                            <Form.Input required label="Rechnungsdatum" >
                                <MaskedInput
                                    value={this.state.created_at}
                                    onChange={(e)=>this.handleChange(e,{name:"created_at",value:e.target.value})} 
                                    pattern="[(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                                    placeholder="DD-MM-YYYY" 
                                    mask={[/\d/,/\d/,"-",/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/]}  
                                    guide={true} {...this.props} required  />
                            </Form.Input>
                            <Form.Input required label="Fälligkeitsdatum" >
                                <MaskedInput
                                    value={this.state.until}
                                    onChange={(e)=>this.handleChange(e,{name:"until",value:e.target.value})} 
                                    pattern="[(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                                    placeholder="DD-MM-YYYY" 
                                    mask={[/\d/,/\d/,"-",/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/]}  
                                    guide={true} {...this.props} required  />
                            </Form.Input>
                        </Segment>
                    </Segment.Group>
                    {/*<Segment placeholder style={{minHeight:"auto"}}>
                        <Dropdown text='Produkt hinzufügen' icon='add' fluid floating labeled button  className='icon'>
                            <Dropdown.Menu>
                                <Dropdown.Header content='Produkt suchen' />
                                <Input icon='search' iconPosition='left' name='search' />
                                <Dropdown.Header icon='tags' content='Leistungen' />
                                <Dropdown.Divider />
                                <Dropdown.Item text='Garantie' />
                                <Dropdown.Item text='Versicherung' />
                                <Dropdown.Item text='Ölwechsel' />
                                <Dropdown.Header icon='tags' content='Gegenstände' />
                                <Dropdown.Divider />
                                <Dropdown.Item text='Reifen' />
                                <Dropdown.Item text='Öl' />
                                <Dropdown.Item text='Scheibenwischer' />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Segment>*/}
                    <Form onSubmit={this.addProduct.bind(this)}>
                    <Table attached>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Artikel</Table.HeaderCell>
                                <Table.HeaderCell>Beschreibung</Table.HeaderCell>
                                <Table.HeaderCell>Menge</Table.HeaderCell>
                                <Table.HeaderCell>Preis</Table.HeaderCell>
                                <Table.HeaderCell>Steuer</Table.HeaderCell>
                                <Table.HeaderCell collapsing></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell><Form.Input required value={this.state.newItem_title} onChange={this.handleChange.bind(this)} name="newItem_title" fluid/></Table.Cell>
                                <Table.Cell><Form.Input required value={this.state.newItem_description} onChange={this.handleChange.bind(this)} name="newItem_description" fluid/></Table.Cell>
                                <Table.Cell><Form.Input required value={this.state.newItem_amount} type="number" onChange={this.handleChange.bind(this)} name="newItem_amount" fluid/></Table.Cell>
                                <Table.Cell><Form.Input required value={this.state.newItem_price} type="number" onChange={this.handleChange.bind(this)} name="newItem_price" fluid/></Table.Cell>
                                <Table.Cell>19 %</Table.Cell>
                                <Table.Cell><Button icon><Icon name="check" /></Button></Table.Cell>
                            </Table.Row>
                            {this.state.products.map((el,index)=>(
                            <Table.Row>
                                <Table.Cell>{el.title}</Table.Cell>
                                <Table.Cell>{el.description}</Table.Cell>
                                <Table.Cell>{el.amount}</Table.Cell>
                                <Table.Cell>{el.price}</Table.Cell>
                                <Table.Cell>19 %</Table.Cell>
                                <Table.Cell><Button as="a" onClick={()=>this.removeProduct(index)} icon negative><Icon name="trash"  /></Button></Table.Cell>
                            </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                    </Form>
                    <Segment.Group attached horizontal style={{backgroundColor:"#fff", borderRadius: "0 0 .28571429rem .28571429rem"}}>
                        <Segment><Form.TextArea rows="5"  onChange={this.handleChange.bind(this)} name="additional"  label="Anmerkungen" /></Segment>
                        <Segment>
                            <Table definition>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell textAlign="right">Zwischensumme</Table.Cell>
                                        <Table.Cell><Currency quantity={this.state.price} currency="EUR" /></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell textAlign="right">Mwst</Table.Cell>
                                        <Table.Cell><Currency quantity={this.state.price*0.19} currency="EUR" /></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell textAlign="right">Gesamtsumme</Table.Cell>
                                        <Table.Cell><Currency quantity={this.state.price*1.19} currency="EUR" /></Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Segment.Group>
                </Segment.Group>
                {this.state.error && <Message negative >{this.state.error}</Message>}
                        
                <div style={{textAlign:"right"}}>
                    <Button.Group >
                        {/*<Form.Button onClick={()=>this.save("draft")} style={{marginRight:10}} secondary>Speichern als Entwurf</Form.Button>*/}
                        <Form.Button onClick={()=>this.save("open")} primary>Erstellen</Form.Button>
                    </Button.Group>
                </div>
            </Form>
        </Segment>)
        
    }
}

export default withRouter(ReceiptAdd)