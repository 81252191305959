import React, {Component} from 'react'
import {  Form, Grid, Header, Message, List, Segment, Label, Divider } from 'semantic-ui-react'
import {withRouter, Route} from 'react-router-dom'
import LoginForm from "./Login";
import RegisterForm from "./Register";

class Login extends Component{
    state={
        err:false,
    }
    componentDidMount(){
        document.title = 'AutoDIGI - Anmelden';
    }
    render(){
        return (
            <Grid  style={{ height: '100%' }} verticalAlign='middle'>
                <Grid.Column textAlign='left' style={{ maxWidth: 760, }}>

                    <center style={{marginTop:20}}><img height="70" src="/autodigi_weiss.png"></img></center>
                    
                    <Segment style={{ overflow:"hidden"}} >
                    {this.props.register && [<Label key="reg-message" style={{width:"100%"}} basic color='green' >
                        <Header color="green" style={{marginBottom:10}}>Ihre Registrierung war erfolgreich!</Header>
                        Sie erhalten in Kürze eine E-Mail von AutoDIGI. Bitte klicken Sie auf den enthaltenen Bestätigungslink, um Ihren Zugang zu AutoDIGI zu aktivieren. Sollten Sie keine E-Mail erhalten haben, schauen Sie in Ihr Spam-Ordner oder treten Sie mit uns in Kontakt.
                    </Label>,<br key="reg-br" />]}
                    {this.state.err && [<Label key="reg-message" style={{width:"100%"}} basic color='red' >
                        <Header color="red" style={{marginBottom:10}}>Anmeldung fehlgeschlagen</Header>
                        Möglicherweise ist Ihre Passwort/E-Mail Kombination falsch. Oder Sie haben Ihren Account noch nicht in der Bestätigungs-Mail bestätigt
                    </Label>,<br key="reg-br" />]}
                    {this.props.verify && [<Label key="reg-message" style={{width:"100%"}} basic color='green' >
                        <Header color="green" style={{marginBottom:10}}>Ihre Verifizierung war erfolgreich!</Header>
                        Sie können Ihr AutoDIGI Konto nun benutzen. Melden Sie sich an und digitalisieren Sie Ihr Unternehmen noch heute!
                    </Label>,<br key="reg-br" />]}
                        <LoginForm onLogin={()=>this.setState({err:false})} onLoginFailed={()=>this.setState({err:true})} login={this.props.login} />
                        <Divider />
                        <RegisterForm />
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }
} 

export default withRouter(Login)