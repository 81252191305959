import React, {Component} from 'react'
import {Redirect, Route, BrowserRouter} from 'react-router-dom'
import Login from './Login/';
import Forgot from './Forgot/'
import ForgotVerify from './Forgot/verify'
import Verify from './Verify'
import "./video.css";
import Register from "./Register";

class Authentification extends Component{
    render(){
        return (
            <BrowserRouter>
                <div style={{ position:"fixed", top:0, left:0, width:"100vw", height:"100vh",overflowY: "auto", overflowX: "hidden"}}>
                    <div style={{float:"left",width:"25%",background:"linear-gradient(141deg,#1f191a 0,#363636 71%,#46403f 100%)",padding:20,height:"100%", overflow:"auto",boxShadow:"3px 0px 50px 0px rgba(0,0,0,0.75)"}}>

                        <Route path={"/"} exact component={()=><Login verify={false} register={false} />} />
                        {/*<Route path={"*"} exact component={()=><Login  />} /> */}
                        <Route path={"/Authentification"} exact component={()=><Login verify={false} register={false} />} />
                        <Route path={"/Authentification/forgot-password"} exact component={Forgot} />
                        <Route path={"/Authentification/forgot-password/Verify/:token"} exact component={ForgotVerify} />
                        <Route path={"/Authentification/Verify/:token"} component={Verify} />
                        <Route path={"/Authentification/Register"} component={Register} />
                        <Route path={"/Authentification/Register-Sucessfull"} exact component={()=><Login verify={false} register={true} />} />
                        <Route path={"/Authentification/Verify-Successfull"} exact component={()=><Login  verify={true}  register={false}  />} />
                    </div>
                    <div className="fullscreen-bg">
                        <video loop muted autoplay="" playbackRate={0.1} className="fullscreen-bg__video">
                            <source src="/170728_Berlin_C_034.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
            </BrowserRouter>
        )
    }
} 

export default Authentification