import React, { Component } from "react";
import { Segment, Menu, Icon } from "semantic-ui-react";
import ContractsList from './list'
import ContractsNew from './new'

class Contracts extends Component {
    render() {
        return (
            <Segment basic >
                {!this.props.hideMenu && <Menu>
                    <Menu.Item><b>Kaufverträge</b></Menu.Item>
                    <Menu.Menu position="right">
                        <ContractsNew trigger={<Menu.Item><Icon name="plus square" /> Hinzufügen</Menu.Item>} />
                    </Menu.Menu>
                </Menu>}
                <ContractsList />
            </Segment>
        )
    }
}

export default Contracts