import React, { Component } from "react";
import { Segment,Label, Input, Table, Pagination, Select } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Currency from "react-currency-formatter";
import client from '../../shared/feathers'
import SellerSingleCompact from "../Sellers/Single/compact";
import Moment from "react-moment";
import CarCompact from "../Cars/compact";
import CustomerCompact from "../Customers/compact";

class ContractsList extends Component {
    state={
        query:{},
        data:{
            total:1,
            limit:1,
            data:[]
        },
        cars:[],
        customers:[],
        users:[],
        page: 1,
        loading:true
    }

    async componentDidMount(){
        await this.search("users")
        await this.search("customers")
        await this.search("cars")
        document.title = 'Kaufverträge';
        this.update()
    }

    async search(field,query){
        this.setState({[field]:(await client.service(field).find({query})).data.map((elm,index)=>({
            key:elm.id,
            value:elm.id,
            text:(elm.firstname||elm.company)?(elm.company?elm.company:elm.firstname+" "+elm.lastname):elm.manufacturer+" "+elm.type,
            description:(elm.firstname||elm.company)?elm.id:elm.identification
        }))});
    }
    changePage(event,data){
        this.setState({loading:true,page:data.activePage},()=>this.update())
    }
    update(){
        this.setState({loading:true})
        if(this.reactive){this.reactive.unsubscribe()}
        this.reactive = client.service("contracts").watch().find({query: {$sort:{id:-1},$and:[...Object.values(this.state.query)],$skip:(this.state.page * this.state.data.limit)-this.state.data.limit,...this.props.query }}).subscribe(async data=>this.setState({data,loading:false}))
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    changeFilter(name,value){
        if((name=="car_id"||name=="customer_id"||name=="user_id")&&value){
            this.setState({
                query:{
                    ...this.state.query,
                    [name]:{[name]:value }
                }
            }, ()=>this.update())
        }else{
            this.setState({query:{...this.state.query,[name]:{[name]:{$like: "%"+value+"%" }}}}, ()=>this.update())
        }
    }
    render() {
        return (
            <Segment.Group style={{ padding: 0 }} {...this.props.SegmentProps}>
                <Table selectable attached="top" celled size="small" {...this.props.TableProps}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell collapsing>ID</Table.HeaderCell>
                            <Table.HeaderCell>Fahrzeug Ident-Nr.</Table.HeaderCell>
                            <Table.HeaderCell>Fahrzeug</Table.HeaderCell>
                            <Table.HeaderCell>Kunde</Table.HeaderCell>
                            <Table.HeaderCell>Wert</Table.HeaderCell>
                            <Table.HeaderCell>Verkäufer</Table.HeaderCell>
                            <Table.HeaderCell>Datum</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                        </Table.Row>
                        {!this.props.noFilter &&<Table.Row>
                            <Table.HeaderCell collapsing style={{ padding: 5, backgroundColor: '#fff' }}><Input name="id" onChange={(e,{name,value})=>this.changeFilter(name,value)} fluid transparent size="mini" /></Table.HeaderCell>
                            <Table.HeaderCell style={{ padding: 0}}>
                                <Select 
                                noResultsMessage='Es wurden keine Fahrzeug gefunden.' 
                                search
                                onSearchChange={(e,{searchQuery})=>this.search("cars",{
                                    $or:[
                                        {manufacturer: {$like:"%"+searchQuery+"%"},},
                                        {type: {$like:"%"+searchQuery+"%"},},
                                        {identification: {$like:"%"+searchQuery+"%"},},
                                        {id: {$like:"%"+searchQuery+"%"},}
                                    ]
                                })}
                                search={options=>this.state.cars}
                                options={this.state.cars}  
                                style={{border:"0",height:20}} 
                                onChange={(e,{name,value})=>this.changeFilter(name,value)} 
                                clearable
                                transparent name="car_id" fluid />
                            </Table.HeaderCell>
                            <Table.HeaderCell ></Table.HeaderCell>
                            <Table.HeaderCell style={{ padding: 0}}>
                                <Select 
                                noResultsMessage='Es wurden keine Kunden gefunden.' 
                                onSearchChange={(e,{searchQuery})=>this.search("customers",{
                                    $or:[
                                        {company: {$like:"%"+searchQuery+"%"}},
                                        {firstname: {$like:"%"+searchQuery+"%"}},
                                        {lastname: {$like:"%"+searchQuery+"%"}},
                                        {id: {$like:"%"+searchQuery+"%"}},
                                    ]
                                })}
                                options={this.state.customers}  
                                style={{border:"0",height:20}} 
                                onChange={(e,{name,value})=>this.changeFilter(name,value)} 
                                clearable
                                search={options=>this.state.customers}
                                transparent name="customer_id" fluid />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}><Input name="price" onChange={(e,{name,value})=>this.changeFilter(name,value)} icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                            <Table.HeaderCell style={{ padding: 0}}>
                                <Select 
                                noResultsMessage='Es wurden keine Mitarbeiter gefunden.' 
                                search
                                onSearchChange={(e,{searchQuery})=>this.search("users",{
                                    $or:[
                                        {firstname: {$like:"%"+searchQuery+"%"},},
                                        {lastname: {$like:"%"+searchQuery+"%"},},
                                        {id: {$like:"%"+searchQuery+"%"},}
                                    ]
                                })}
                                search={options=>this.state.users}
                                options={this.state.users}  
                                style={{border:"0",height:20}} 
                                onChange={(e,{name,value})=>this.changeFilter(name,value)} 
                                clearable
                                transparent name="user_id" fluid />
                            </Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell collapsing></Table.HeaderCell>
                        </Table.Row>}
                    </Table.Header>
                    <Table.Body>
                        {this.state.data.data.map((entry, id) =>
                            <Table.Row key={entry.id} style={{cursor:"pointer"}} onClick={()=>this.props.history.push("/contracts/"+entry.id)}>
                                <Table.Cell>{entry.id}</Table.Cell>
                                <Table.Cell><CarCompact id={entry.car_id} field="identification" /></Table.Cell>
                                <Table.Cell><CarCompact id={entry.car_id} field="manufacturer" /> <CarCompact id={entry.car_id} field="type" /></Table.Cell>
                                <Table.Cell><CustomerCompact id={entry.customer_id} field="company" /><CustomerCompact id={entry.customer_id} field="firstname" /> <CustomerCompact id={entry.customer_id} field="lastname" /></Table.Cell>
                                <Table.Cell><Currency currency="EUR" quantity={entry.price}  /></Table.Cell>
                                <Table.Cell><SellerSingleCompact seller_id={entry.user_id} /> </Table.Cell>
                                <Table.Cell><Moment format="DD.MM.YYYY" >{entry.created_at}</Moment></Table.Cell>
                                <Table.Cell>
                                    {entry.status == "open" && <Label color="red" >Offen</Label>}
                                    {entry.status == "closed" && <Label color="red" basic>Storniert</Label>}
                                    {entry.status == "finish" && <Label color="green">Unterzeichnet</Label>}
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                {!this.props.noPagination && <Pagination 
                    onPageChange={this.changePage.bind(this)}
                    attached="bottom" 
                    boundaryRange={2} 
                    siblingRange={2} 
                    fluid 
                    defaultActivePage={this.state.page} 
                    totalPages={Math.ceil(this.state.data.total / this.state.data.limit)} />}
            </Segment.Group>


        )
    }
}

export default withRouter(ContractsList)