import React, {Component} from 'react'
import { Icon, Header } from 'semantic-ui-react'

class Step1Desc extends Component{

    render(){
        return (
            <div>
                <Header as='h2' style={{width:"100%",color:"#fff",textAlign:"left"}}>
                    <span><Icon name='building outline' /> Unternehmen</span>
                    <Header.Subheader style={{color:"#fff"}}>Ihre Unternehmensdaten werden  benötigt,  um personalisierte Rechnungen und Verträge zu erstellen.</Header.Subheader>
                </Header>
            </div>
        )
    }
} 

export default Step1Desc