import React, { Component } from "react";
import { Segment, Input, Icon, Table, Pagination, Image, Modal, ModalContent } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Currency from "react-currency-formatter";
import client from "../../shared/feathers";

class CarsList extends Component {

    state={
        query:{},
        data:{
            total:1,
            limit:1,
            data:[]
        },
        page: 1,
        loading:true
    }
    componentDidMount(){
        document.title = 'Fahrzeuge';
        this.update()
    }

    changePage(event,data){
        this.setState({loading:true,page:data.activePage},()=>this.update())
    }
    update(){
        this.setState({loading:true})
        if(this.reactive){this.reactive.unsubscribe()}
        this.reactive = client.service("cars").watch().find({query: {$and:[...Object.values(this.state.query)],$skip:(this.state.page * this.state.data.limit)-this.state.data.limit,...this.props.query }}).subscribe(async data=>this.setState({data,loading:false}))
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    changeFilter(name,value){
        this.setState({query:{...this.state.query,[name]:{[name]:{$like: "%"+value+"%" }}}}, ()=>this.update())
    }
    render() {
        return (
                <Segment.Group style={{ padding: 0 }} {...this.props.SegmentProps}>
                    <Table selectable attached="top" celled size="small" {...this.props.TableProps}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>ID</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Bild</Table.HeaderCell>
                                <Table.HeaderCell>Hersteller</Table.HeaderCell>
                                <Table.HeaderCell>Typ.</Table.HeaderCell>
                                <Table.HeaderCell>Fahrzeug Ident-Nr.</Table.HeaderCell>
                                <Table.HeaderCell>Anmeld.-Nr</Table.HeaderCell>
                                <Table.HeaderCell>Inspektion</Table.HeaderCell>
                                {!this.props.lessInfo && <Table.HeaderCell>Kilometerstand</Table.HeaderCell>}
                                <Table.HeaderCell>Wert</Table.HeaderCell>
                                {!this.props.lessInfo && <Table.HeaderCell><Image src='/images/mobile.png' style={{ height: 25, width: 'auto' }} size='small' /></Table.HeaderCell>}
                                {!this.props.lessInfo && <Table.HeaderCell><Image src='/images/autoscout.png' style={{ height: 30, width: 'auto' }} size='small' /></Table.HeaderCell>}
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell collapsing style={{ padding: 5, backgroundColor: '#fff' }}><Input name="internal_id" onChange={(e,{name,value})=>this.changeFilter(name,value)} fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}><Input name="manufacturer" onChange={(e,{name,value})=>this.changeFilter(name,value)} icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}><Input name="type" onChange={(e,{name,value})=>this.changeFilter(name,value)} icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}><Input name="identification" onChange={(e,{name,value})=>this.changeFilter(name,value)} icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}><Input name="admission_first" onChange={(e,{name,value})=>this.changeFilter(name,value)} icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                {!this.props.lessInfo && <Table.HeaderCell></Table.HeaderCell>}
                                {!this.props.lessInfo && <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}></Table.HeaderCell>}
                                {!this.props.lessInfo && <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}></Table.HeaderCell>}
                            </Table.Row>
                        </Table.Header>
                        {this.state.data.data.length >0 && <Table.Body>
                            {this.state.data.data.map((entry, id) =>
                            <Table.Row key={id} style={{cursor:"pointer"}} onClick={()=>this.props.history.push("/cars/single/"+entry.id)}>
                                    <Table.Cell>{entry.internal_id}</Table.Cell>
                                    <Table.Cell style={{padding:3}} >
                                        {JSON.parse(entry.images).length >0 && <Modal trigger={<Image src={"https://api.carbill.dev.tecoyo.com/uploads/"+JSON.parse(entry.images)[0].id} style={{margin:0, height: 40, width: 'auto' }} size='small' />}>
                                            <ModalContent style={{overflow: 'hidden', padding:0}}>
                                                <Image src={"https://api.carbill.dev.tecoyo.com/uploads/"+JSON.parse(entry.images)[0].id} style={{width: '100%'}}/>
                                            </ModalContent>
                                        </Modal>}
                                    </Table.Cell>
                                    <Table.Cell>{entry.manufacturer}</Table.Cell>
                                    <Table.Cell>{entry.type}</Table.Cell>
                                    <Table.Cell>{entry.identification}</Table.Cell>
                                    <Table.Cell>{entry.admission_nr}</Table.Cell>
                                    <Table.Cell>{entry.inspection_month+"/"+entry.inspection_year}</Table.Cell>
                                    {!this.props.lessInfo && <Table.Cell>{entry.kilometer}</Table.Cell>}
                                    <Table.Cell><Currency quantity={entry.price} currency="EUR" /></Table.Cell>
                                    {!this.props.lessInfo && <Table.Cell>
                                        {entry.mobile_id && <Icon name='check' color='green' />}
                                        {!entry.mobile_id &&<Icon name='x' color='red' />}
                                    </Table.Cell>}
                                    {!this.props.lessInfo && <Table.Cell>
                                        <Icon name='x' color='red' />
                                    </Table.Cell>}
                                </Table.Row>
                            )}
                        </Table.Body>}
                    </Table>
                    <Pagination 
                    onPageChange={this.changePage.bind(this)}
                    attached="bottom" 
                    boundaryRange={2} 
                    siblingRange={2} 
                    fluid 
                    defaultActivePage={this.state.page} 
                    totalPages={Math.ceil(this.state.data.total / this.state.data.limit)} />
                </Segment.Group>
        )
    }
}

export default withRouter(CarsList)