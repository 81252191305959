import React, { Component } from "react";
import { Segment, Modal, Button, Form, Header, Message } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import MaskedInput from 'react-text-mask'
import client from '../../shared/feathers'
import moment from 'moment'

class CustomersNew extends Component {
    state={
        type:null,
        birthdate:"",
        saving:false
    }
    constructor(props){
        super(props)
    }
    save(){
        let {job,birthdate, firstname,lastname,company,type,address, address_extra,salutation,phone,phone_mobile,fax,tax,vat,email,country,postcode,city} = this.state
        this.setState({saving:true})
        client.service("customers").create({documents:"[]",job,birthdate:moment(birthdate,"DD-MM-YYYY").format("YYYY-MM-DD"), firstname,lastname,company,type,address, address_extra,salutation,phone,phone_mobile,fax,tax,vat,email,country,postcode,city}).then((result)=>{
            this.props.history.push("/customers/"+result.id)
        }).catch((e)=>{
            console.log(e)
            this.setState({saving:false,error:true})
        })
    }
    handleChange = (e, { name,value }) => this.setState({ [name]:value })
    render() {
        return (
            <Modal closeIcon size='small' closeOnDimmerClick={false} trigger={this.props.trigger} >
                <Modal.Header>Neuer Kunde</Modal.Header>
                <Modal.Content scrolling>

                    <Form loading={this.state.saving} id="userCreateForm" onSubmit={this.save.bind(this)}>
                        <Segment.Group>
                            <Header attached="top">Type</Header>
                            <Segment attached="bottom" color="green">
                                <Form.Group widths="equal">
                                    <Form.Radio required onChange={this.handleChange.bind(this)} checked={this.state.type=="commercial"} name="type" value="commercial" label="Geschäftskunde" />
                                    <Form.Radio required onChange={this.handleChange.bind(this)} checked={this.state.type=="private"} name="type" value="private" label="Privatkunde" />
                                </Form.Group>
                            </Segment>
                        </Segment.Group>
                        {this.state.type && <div>
                            <Segment.Group >
                                <Header attached="top" >Allgemeine Informationen</Header>
                                <Segment attached="bottom" color="green">
                                    {this.state.type == "private" &&<div>
                                        <Form.Group widths="equal">
                                            <Form.Select name="salutation" onChange={this.handleChange.bind(this)} required options={[{key:"Herr",value:"Herr",text:"Herr"},{key:"Frau",value:"Frau",text:"Frau"}]} fluid width="3" label="Anrede" />
                                            <Form.Input name="firstname" onChange={this.handleChange.bind(this)} required label="Vorname" />
                                            <Form.Input name="lastname" onChange={this.handleChange.bind(this)} required label="Nachname" />
                                        </Form.Group>

                                        <Form.Input required label="Geburtstag"  >
                                            <MaskedInput
                                                value={this.state.birthdate}
                                                onChange={(e)=>this.handleChange(e,{name:"birthdate",value:e.target.value})} 
                                                pattern="[(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                                                placeholder="DD-MM-YYYY" 
                                                mask={[/\d/,/\d/,"-",/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/]}
                                                guide={true} {...this.props} required  />
                                        </Form.Input>
                                        <Form.Input name="job" label="Beruf" onChange={this.handleChange.bind(this)} />
                                        <Form.Input name="phone" onChange={this.handleChange.bind(this)} label="Telefonnummer" />
                                        <Form.Input name="email" onChange={this.handleChange.bind(this)} required label="E-Mail" />
                                    </div>}
                                    {this.state.type == "commercial" && <div>
                                        <Form.Input required label="Unternehmen" name="company" onChange={this.handleChange.bind(this)} />
                                        <Form.Group widths="equal">
                                            <Form.Input required label="Steuernummer" name="tax" onChange={this.handleChange.bind(this)} />
                                            <Form.Input label="USt-ID" name="vat" onChange={this.handleChange.bind(this)} />
                                        </Form.Group>
                                        <Form.Input label="Branche" onChange={this.handleChange.bind(this)} name="job" />
                                        <Form.Input label="Telefonnummer" onChange={this.handleChange.bind(this)} name="phone" />
                                        <Form.Input label="Telefonnummer (Mobile)" onChange={this.handleChange.bind(this)} name="phone_mobile" />
                                        <Form.Input label="Fax" onChange={this.handleChange.bind(this)} name="fax" />
                                        <Form.Input required label="E-Mail" onChange={this.handleChange.bind(this)} name="email" />
                                    </div>} 
                                    
                                </Segment>
                            </Segment.Group>
                                    
                            <Segment.Group >
                                <Header attached="top" >Address Informationen</Header>

                                <Segment attached="bottom" color="green">
                                    <Form.Input required label="Straße, Hausnummer" onChange={this.handleChange.bind(this)} name="address" />
                                    <Form.Input placeholder="Adresszusatz" onChange={this.handleChange.bind(this)} name="address_extra"  />
                                    <Form.Group widths="equal">
                                        <Form.Input required width="5" label="PLZ" onChange={this.handleChange.bind(this)} name="postcode" />
                                        <Form.Input required label="Stadt" name="city" onChange={this.handleChange.bind(this)} />
                                    </Form.Group>
                                    <Form.Select required label="Land" options={[{flag:"de",key:"de",value:"DE",text:"Deutschland"}]} value="DE" onChange={this.handleChange.bind(this)} name="country" />
                                </Segment>
                            </Segment.Group>

                        </div>}
                        {this.state.error && <Message negative >Es ist ein Fehler beim speichern aufgetreten. Bitte überprüfen Sie alle Felder oder kontaktieren Sie den Support.</Message>}
                        <Segment style={{textAlign:"center"}}>
                            <Button
                                type="submit"
                                positive
                                icon='checkmark'
                                labelPosition='right'
                                content="Hinzufügen"
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
            </Modal >

        )
    }
}

export default withRouter(CustomersNew)