import React,{ Component } from "react";
import { Form } from "semantic-ui-react";
import axios from 'axios'

class HydraulicInstallation extends Component{
    state={
        loading:true,
        value:null,
        options:[]
    }
    async componentDidMount(){
        if(this.props.hydraulicInstallation){
            await this.update()
            await this.setStateAsync({value:this.props.value})
        }else{
            await this.update()
        }
    }
    handleChange(e,data){
        this.setState({value:data.value})
        this.props.onChange(e,data)
    }
    setStateAsync(state) {
        return new Promise((resolve) => {
          this.setState(state, resolve)
        });
    }
    async update(){
        this.setState({loading:true,options:[],value:null})
        await this.setStateAsync( {loading:false,options:(await axios.get("https://carbill.dev.tecoyo.com/refdata/hydraulicinstallations",{headers:{Accept:"application/vnd.de.mobile.api+json"},withCredentials:false}) ).data.values.map(el=>({text:el.description,value:el.name,key:el.name})) } )
        return true;
    }
    render(){
        return <Form.Select clearable search label="Hydraulik Installation" name="hydraulicInstallation" onChange={this.handleChange.bind(this)} {...this.state} />
    }
}

export default HydraulicInstallation