import React,{ Component } from "react";
import { Form, Header, Message } from "semantic-ui-react";
import moment from 'moment'
import MaskedInput from 'react-text-mask'
import client from "../../../shared/feathers";

class CustomerEdit  extends Component{
    state={
        saving: false,
        birthdate: "",
        error:false
    }
    save(){

        this.setState({saving:true})
        let {birthdate, firstname,lastname,company,type,address, address_extra,salutation,phone,phone_mobile,fax,tax,vat,email,country,postcode,city,job} = this.state
        client.service("customers").patch(this.props.customer.id,{job,birthdate:moment(birthdate,"DD-MM-YYYY").format("YYYY-MM-DD"), firstname,lastname,company,type,address, address_extra,salutation,phone,phone_mobile,fax,tax,vat,email,country,postcode,city}).then((result)=>{
            
            this.setState({saving:false,error:false})
        }).catch((e)=>{
            console.log(e)
            this.setState({saving:false,error:true})
        })
    }
    handleChange = (e, { name,value }) => this.setState({ [name]:value })
    render(){
        let customer = this.props.customer
        return (
            <Form loading={this.state.saving} size="small" onSubmit={this.save.bind(this)}>
                {this.state.error && <Message  negative>Fehler beim speichern der Informationen. Bitte überprüfe alle Felder!</Message>}
                <Header  >Allgemeine Informationen</Header>

                {this.props.customer.type == "private" &&<div>
                    <Form.Group widths="equal">
                        <Form.Select defaultValue={customer.salutation} name="salutation" onChange={this.handleChange.bind(this)} required options={[{key:"Herr",value:"Herr",text:"Herr"},{key:"Frau",value:"Frau",text:"Frau"}]} fluid width="3" label="Anrede" />
                        <Form.Input defaultValue={customer.firstname} name="firstname" onChange={this.handleChange.bind(this)} required label="Vorname" />
                        <Form.Input defaultValue={customer.lastname} name="lastname" onChange={this.handleChange.bind(this)} required label="Nachname" />
                    </Form.Group>
                    <Form.Input required label="Geburtstag"  >
                        <MaskedInput
                            value={this.state.birthdate? this.state.birthdate: moment(this.props.customer.birthdate).format("DD-MM-YYYY")}
                            onChange={(e)=>this.handleChange(e,{name:"birthdate",value:e.target.value})} 
                            pattern="[(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                            placeholder="DD-MM-YYYY" 
                            mask={[/\d/,/\d/,"-",/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/]}
                            guide={true} {...this.props} required  />
                    </Form.Input>
                    <Form.Input defaultValue={customer.job} name="job" label="Beruf" onChange={this.handleChange.bind(this)} />
                    <Form.Input defaultValue={customer.phone} name="phone" onChange={this.handleChange.bind(this)} label="Telefonnummer" />
                    <Form.Input defaultValue={customer.email} name="email" onChange={this.handleChange.bind(this)} required label="E-Mail" />
                </div>}
                {this.props.customer.type == "commercial" && <div>
                    <Form.Input defaultValue={customer.company} required label="Unternehmen" name="company" onChange={this.handleChange.bind(this)} />
                    <Form.Group widths="equal">
                        <Form.Input defaultValue={customer.tax} required label="Steuernummer" name="tax" onChange={this.handleChange.bind(this)} />
                        <Form.Input defaultValue={customer.vat} label="USt-ID" name="vat" onChange={this.handleChange.bind(this)} />
                    </Form.Group>
                    <Form.Input defaultValue={customer.job} label="Branche" onChange={this.handleChange.bind(this)} name="job" />
                    <Form.Input defaultValue={customer.phone} label="Telefonnummer" onChange={this.handleChange.bind(this)} name="phone" />
                    <Form.Input defaultValue={customer.phone_mobile} label="Telefonnummer (Mobile)" onChange={this.handleChange.bind(this)} name="phone_mobile" />
                    <Form.Input defaultValue={customer.fax} label="Fax" onChange={this.handleChange.bind(this)} name="fax" />
                    <Form.Input defaultValue={customer.email} required label="E-Mail" onChange={this.handleChange.bind(this)} name="email" />
                </div>} 
                <Header  >Address Informationen</Header>

                    <Form.Input defaultValue={customer.address} required label="Straße, Hausnummer" onChange={this.handleChange.bind(this)} name="address" />
                    <Form.Input placeholder="Adresszusatz" defaultValue={customer.address_extra} onChange={this.handleChange.bind(this)} name="address_extra"  />
                    <Form.Group widths="equal">
                        <Form.Input  defaultValue={customer.postcode} required width="5" label="PLZ" onChange={this.handleChange.bind(this)} name="postcode" />
                        <Form.Input defaultValue={customer.city} required label="Stadt" name="city" onChange={this.handleChange.bind(this)} />
                    </Form.Group>
                    <Form.Select  defaultValue={customer.country} required label="Land" options={[{flag:"de",key:"de",value:"DE",text:"Deutschland"}]} value="DE" onChange={this.handleChange.bind(this)} name="country" />
                <Form.Button primary>Speichern</Form.Button>
            </Form>
        )
    }
}

export default CustomerEdit