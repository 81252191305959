import React, {Component} from 'react'
import {  Sidebar, Menu, Icon, Segment, Button, Header } from 'semantic-ui-react'
import {withRouter} from 'react-router-dom'
import client from '../shared/feathers'
let Groups = {administrator:"Administrator",manager:"Manager",salesman:"Verkäufer",accountant:"Buchhalter",mechanic:"Mechaniker",carcreator:"Fahrzeugerfasser"}

class MenuDasboard extends Component{
    state={
        loading:true,
        open:false
    }
    async componentDidMount(){
        let user = await client.service("user").find()
        let company = await client.service("companies").get(user.company_id)
        this.setState({user,company,loading:false})
    }
    render(){
        if(this.state.loading){
            return <Sidebar inverted style={{width:100, zIndex:0}} direction="left"  icon='labeled' animation="overlay" icon='labeled' visible loading />
        }
        return (<div 
            onMouseEnter={()=>this.setState({open:true})}
            onMouseLeave={()=>this.setState({open:false})}>
            <Segment key="signout" inverted style={{zIndex:2000,background:"#212121",position:"fixed",top:"auto",bottom:0,borderRadius:0,left:0, width:this.state.open?250:120,margin:0}}>
                {this.state.open && <Header inverted>
                    <Header.Subheader>Angemeldet als: {Groups[this.state.user.permissions]}</Header.Subheader>
                    {this.state.user.firstname} {this.state.user.lastname}
                </Header>}
                {/*<Select options={[]} fluid />*/}
                <Button  fluid onClick={()=>{client.logout();this.props.history.push("/");}} style={{backgroundColor:"#fff",border:"#d9d9d9 1px solid"}}>
                    <Icon name='shutdown' />
                    {this.state.open && <span>Abmelden</span>}
                </Button>
            </Segment>
            <Sidebar 
            key="sidebar"
            inverted
            as={Menu}
                style={{transition:"width 0.5s",background:"#1a1b1c",width:this.state.open?250:120, zIndex:0,paddingBottom:this.state.open?150:60,zIndex:200}}
                direction="left" 
                icon='labeled'
                animation="overlay" icon='labeled' visible vertical
            >

                    {/* {!!this.state.company.logo && <div style={{padding:10}}><img src={"https://api.carbill.dev.tecoyo.com/uploads/"+this.state.company.logo} style={{width:"100%"}} /></div>}
                    {!this.state.company.logo && <img src="/autodigi_weiss.png" style={{width:"100%"}} />} */}
                    <Menu.Item 
                        id="menu-dashboard" 
                        onClick={()=>this.props.history.push("/dashboard")} 
                        link icon 
                        active={this.props.history.location.pathname.includes("/dashboard") || this.props.history.location.pathname == "/"} >
                    <Icon name="dashboard" />Dashboard
                    </Menu.Item>
                    {["administrator","manager","salesman","mechanic","accountant"].includes(this.state.user.permissions) && <Menu.Item id="menu-customers" onClick={()=>this.props.history.push("/customers")} link icon active={this.props.history.location.pathname.includes("/customers") }><Icon name="address book" /> Kunden</Menu.Item>}
                    {["administrator","manager","salesman","carcreator"].includes(this.state.user.permissions) && <Menu.Item id="menu-cars" onClick={()=>this.props.history.push("/cars/active")} link icon active={this.props.history.location.pathname.includes("/cars") }><Icon name="car" /> Fahrzeuge</Menu.Item>}
                    {this.props.history.location.pathname.includes("/cars") && <Menu.Menu>
                    {["administrator","manager","salesman","carcreator"].includes(this.state.user.permissions) && <Menu.Item onClick={()=>this.props.history.push("/cars/sold")} link icon active={this.props.history.location.pathname.includes("/cars") }>Verkaufte Fahrzeuge</Menu.Item>}
                    </Menu.Menu>}
                    {["administrator","manager"].includes(this.state.user.permissions) && <Menu.Item id="menu-sellers" onClick={()=>this.props.history.push("/sellers")} link icon active={this.props.history.location.pathname.includes("/sellers") }><Icon name="user" /> Mitarbeiter</Menu.Item>}
                    {["administrator","manager","salesman","mechanic","accountant"].includes(this.state.user.permissions) && <Menu.Item id="menu-receipts" onClick={()=>this.props.history.push("/receipts")} link icon active={this.props.history.location.pathname.includes("/receipts")}><Icon name="archive" /> Rechnungen</Menu.Item>}
                    {["administrator","manager","salesman","mechanic","carcreator","accountant"].includes(this.state.user.permissions) && <Menu.Item id="menu-calendar" onClick={()=>this.props.history.push("/calendar")} link icon active={this.props.history.location.pathname.includes("/calendar")}><Icon name="calendar alternate" /> Kalender</Menu.Item>}
                    {["administrator","manager","salesman","accountant"].includes(this.state.user.permissions) && <Menu.Item id="menu-contracts" onClick={()=>this.props.history.push("/contracts")} link icon active={this.props.history.location.pathname.includes("/contracts")}><Icon name="handshake" /> Kaufverträge</Menu.Item>}
                    
                    <Menu.Item link icon><Icon name="money bill alternate outline" /> Finanzierungen</Menu.Item><Menu.Menu>
                    <Menu.Item link icon>Demnächst</Menu.Item></Menu.Menu>
                    <Menu.Item link icon><Icon name="shield" /> Versicherungen</Menu.Item><Menu.Menu>
                    <Menu.Item link icon>Demnächst</Menu.Item></Menu.Menu>


                    {["administrator","manager"].includes(this.state.user.permissions) && <Menu.Item id="menu-settings" onClick={()=>this.props.history.push("/settings")} link icon active={this.props.history.location.pathname.includes("/settings")}><Icon name="settings" /> Einstellungen</Menu.Item>}
                    {this.state.user.isAdmin == 1 && <Menu.Item onClick={()=>this.props.history.push("/admin")} link icon active={this.props.history.location.pathname.includes("/admin")}><Icon name="server" /> Admin</Menu.Item>}

            </Sidebar></div>)
    }
} 

export default withRouter(MenuDasboard)