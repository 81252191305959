import React,{ Component } from "react";
import { Tab } from "semantic-ui-react";
import FullCalendar from 'fullcalendar-reactwrapper';
import moment from 'moment'
import client from "../../../shared/feathers";
import Context from "../../context";

class Events  extends Component{
    render(){
        return (
            <Tab.Pane attached="bottom" style={{marginTop:0}}>
                <FullCalendar
                    header = {{
                        left: 'title today',
                        right: 'month,agendaWeek,basicDay,listWeek prev,next',
                    }}
                    locale="de"
                    buttonText={{
                        today:    'Heute',
                        month:    'Monat',
                        week:     'Woche',
                        day:      'Tag',
                        list:     'Liste'
                    }}
                    nowIndicator
                    height={700}
                    defaultView = 'listWeek'
                    events = {(start,end,lol,callback)=>{
                        client.service("events").find({customer_id:this.props.customer.id,start:{$le:end,$ge:start}}).then(
                            (result)=>{
                            callback(
                                result.data.map(elm=>({
                                    title: elm.title,
                                    start: elm.start,
                                    id:elm.id,
                                    end:elm.end
                                }) ) ) 
                            }
                        )
                    }}
                    selectable
                    defaultDate={moment().format("YYYY-MM-DD")}
                    navLinks= {true} // can click day/week names to navigate views
                    editable= {true}
                    eventLimit= {true} // allow "more" link when too many events
                    selectable
                    timeFormat="H:mm"
                    select={(start,end)=>this.context.openEvent(0,start,end)}
                    eventClick={(e)=>{this.context.openEvent(e)}}
                />
            </Tab.Pane>
        )
    }
}

Events.contextType= Context
export default Events