import React, { Component } from "react";
import { Segment, Modal, Button, Form, Header, Message,Input } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { HuePicker } from 'react-color';
import client from '../../shared/feathers'

var strongRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");
class SellersNew extends Component {
    state = {
        saving:false,
        user:{isAdmin:false},
        companies:[],
        rightGroups:[
            { key: "manager", text: "Manager", value: "manager" },
            { key: "salesman", text: "Verkäufer", value: "salesman" },
            { key: "accountant", text: "Buchhalter", value: "accountant" },
            { key: "mechanic", text: "Mechaniker", value: "mechanic" },
            { key: "carcreator", text: "Fahrzeugerfasser", value: "carcreator" },
        ],
        isAdmin:false,
        error:false,
        color:"#1273de",
        emailUsed: false,
        phoneUsed: false,
    }
    async componentDidMount(){
        let user = await client.service("user").find()
        if(user.isAdmin==1&&user.permissions=="administrator"){
            this.setState({
                rightGroups:[
                    ...this.state.rightGroups,
                    { key: "administrator", text: "Administrator", value: "administrator" },
                ]
            })
        }
        this.setState({
            user,
            company_id:user.company_id,
            companies:(await client.service("companies").find() ).data
        })
    }
    save(){
        this.setState({saving:true})
        let {isAdmin,salutation,firstname,lastname,email,phone,password,language,permissions,company_id,color} = this.state
        client.service("users").create({isAdmin,salutation,firstname,lastname,email,phone,password,language,permissions,company_id,color}).then((result)=>{
            this.props.history.push("/sellers/"+result.id)
        }).catch((e)=>{
            console.log(e)
            this.setState({saving:false,error:true})
        })
    }
    handleChange = (e, { name, value }) =>{
        if(name=="email" || name=="phone"){ 
            client.service("authManagement").create({ action: 'checkUnique', value: {phone:this.state.phone,email:this.state.email} }).then(()=>{
                this.setState({emailUsed: false, phoneUsed:false})
            }).catch((err)=>{
                this.setState({emailUsed: err.errors.email ? true:false, phoneUsed: err.errors.phone ? true:false})
            })
            this.setState({ emailUsed: false, phoneUsed: false, })
        }
        this.setState({ [name]: value })
    }
    render() {
        return (
            <Modal  closeIcon closeOnDimmerClick={false} size='small' trigger={this.props.trigger} >
                <Modal.Header>Neuer Mitarbeiter</Modal.Header>
                <Modal.Content scrolling>
                    <Form loading={this.state.saving} id="userCreateForm" onSubmit={this.save.bind(this)}>
                        <Segment.Group >
                            <Header attached="top" >Allgemeine Informationen</Header>
                            <Segment attached="bottom" color="green">
                                <Form.Group widths="equal">
                                    <Form.Select name="salutation" onChange={this.handleChange.bind(this)} required options={[{ key: "Herr", value: "Herr", text: "Herr" }, { key: "Frau", value: "Frau", text: "Frau" }]} fluid width="4" label="Anrede" />
                                    <Form.Input name="firstname" onChange={this.handleChange.bind(this)} required label="Vorname" />
                                    <Form.Input name="lastname" onChange={this.handleChange.bind(this)} required label="Nachname" />
                                </Form.Group>
                                <Form.Field>
                                    <label>E-Mail</label>
                                    <Input type="email" name="email" onChange={this.handleChange.bind(this)} required label="@" />
                                    {this.state.emailUsed && <small style={{marginTop:"-1em"}} className="helper">Diese E-Mail ist bereits in Benutzung</small>}
                                </Form.Field>
                                <Form.Field>
                                    <label>Telefonnummer</label>
                                    <Input name="phone" onChange={this.handleChange.bind(this)}  />
                                    {this.state.phoneUsed && <small style={{marginTop:"-1em"}} className="helper">Diese Telefonnummer ist bereits in Benutzung</small>}
                                </Form.Field>
                                <Form.Field>
                                    <label>Passwort*</label>
                                    <Input name="password" onChange={this.handleChange.bind(this)} required type="password" />
                                    {!strongRegex.test(this.state.password) && <small style={{marginTop:"-1em"}} className="helper">Mindestens 8 Zeichen, einen Groß- und einen Kleinbuchstaben, sowie eine Nummer.</small>}
                                </Form.Field>
                                <Form.Field>
                                    <label>Passwort bestätigen*</label>
                                    <Input name="password_check" onChange={this.handleChange.bind(this)} required type="password" />
                                    {this.state.password != this.state.password_check && <small style={{marginTop:"-1em"}} className="helper">Bitte überprüfen Sie, dass beide Passwörter gleich sind.</small>}
                                </Form.Field>
                                <Form.Field>
                                    <label>Kalender Farbe</label>
                                    <HuePicker color={ this.state.color } onChangeComplete={ (color)=>this.setState({color:color.hex}) } />
                                </Form.Field>
                                <Form.Select name="language" onChange={this.handleChange.bind(this)}  required label="Sprache" options={[{ flag: "de", key: "de", value: "DE", text: "Deutsch" }]} value="DE" />
                                {this.state.user.isAdmin==1 && <Form.Dropdown required
                                    name="company_id"
                                    value={this.state.company_id}
                                    onChange={this.handleChange.bind(this)} 
                                    label="Unternehmen auswählen"
                                    placeholder='Unternehmen auswählen'
                                    fluid
                                    search
                                    selection
                                    options={
                                        this.state.companies.map((elm)=>({
                                            key: elm.id, text: elm.name, value: elm.id
                                        }))
                                    }
                                />}
                                <Form.Select 
                                    name="permissions"
                                    onChange={this.handleChange.bind(this)} 
                                    required label="Rechte" options={[
                                        ...this.state.rightGroups
                                ]}></Form.Select>
                                <Form.Checkbox checked={this.state.isAdmin} onClick={()=>this.setState({isAdmin:!this.state.isAdmin})} label="Zugriff zur Administratoren Oberfläche" />
                            </Segment>
                        </Segment.Group>
                        <Segment style={{ textAlign: "center" }}>
                            {this.state.error && <Message negative >Es ist ein Fehler beim speichern aufgetreten. Bitte überprüfen Sie alle Felder oder kontaktieren Sie den Support.</Message>}
                            <Button
                                disabled={
                                    this.state.phoneUsed || 
                                    this.state.emailUsed ||
                                    this.state.password != this.state.password_check || 
                                    this.state.password =="" || 
                                    !strongRegex.test(this.state.password) 
                                }
                                type="submit"
                                positive
                                icon='checkmark'
                                labelPosition='right'
                                content="Hinzufügen"
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
            </Modal >
        )
    }
}

export default withRouter(SellersNew)