
import React from 'react'

export default  React.createContext({
    user:{
        salutation:"Herr",
        password:""
    },
    company:{
        country:"DE"
    },
    step1:false,
    step2:false,
    seller:0,
    changeUser(field,value){},
    changeCompany(field,value){}
    
});

//TODO