import React,{ Component } from "react";
import { Segment, Menu, Modal,Button, Input, Icon, Table, Pagination, Header, Image } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Currency from "react-currency-formatter";
import client from "../../shared/feathers";
import moment from "moment";
import GetName from "../Sellers/GetName";
let types = {commercial:"Unternehmen",private:"Privat"}
class Admin  extends Component{

    state={
        query:{},
        data:{
            total:1,
            limit:1,
            data:[]
        },
        page: 1,
        loading:true,
        user:{id:null}
    }
    async componentDidMount(){
        document.title = 'Unternehmen';
        this.setState({user:await client.service("user").find()})
        this.update()
    }

    changePage(event,data){
        this.setState({loading:true,page:data.activePage},()=>this.update())
    }
    update(){
        this.setState({loading:true})
        if(this.reactive){this.reactive.unsubscribe()}
        this.reactive = client.service("companies").watch().find({query: {$and:[...Object.values(this.state.query)],$skip:(this.state.page * this.state.data.limit)-this.state.data.limit }}).subscribe(async data=>this.setState({data,loading:false}))
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    changeFilter(name,value){
        if(name=="address"){
            this.setState({
                query:{
                    ...this.state.query,
                    [name]:{
                        $or:[
                            {postcode:{$like: "%"+value+"%" }},
                            {address:{$like: "%"+value+"%" }},
                            {city:{$like: "%"+value+"%" }}
                        ]
                    }
                }
            }, ()=>this.update())
        }else{
            this.setState({query:{...this.state.query,[name]:{[name]:{$like: "%"+value+"%" }}}}, ()=>this.update())
        }
    }
    render(){
        return ( 
            <Segment basic>
                <Menu>
                    <Menu.Item><b>Unternehmen</b></Menu.Item>
                    <Menu.Menu position="right">
                        <Modal trigger={<Menu.Item >Hinzufügen</Menu.Item>} basic size='small'>
                            <Modal.Content><iframe style={{borderRadius:5,border:0,width:"100%",height:630}} src={"/Authentification/Register?fullsize=1&seller="+this.state.user.id+"&noauth=1"} /></Modal.Content>
                        </Modal>
                    </Menu.Menu>
                </Menu>
                <Segment loading={this.state.loading} style={{padding:0}}>
                    <Table selectable celled size="small">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>ID</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Bild</Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Addresse</Table.HeaderCell>
                                <Table.HeaderCell>Inhaber</Table.HeaderCell>
                                <Table.HeaderCell>Telefon</Table.HeaderCell>
                                <Table.HeaderCell>Fax</Table.HeaderCell>
                                <Table.HeaderCell>Verkäufer</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Status</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Probezeit</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell collapsing style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)}  name="id" size="tiny" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="name" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="address" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="owner" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="phone" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="fax" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {this.state.data.data.length >0 && <Table.Body>
                            {this.state.data.data.map((entry, id) =>
                                <Table.Row key={entry.id} style={{cursor:"pointer"}} onClick={()=>this.props.history.push("/admin/"+entry.id)}>
                                    <Table.Cell>{entry.id}</Table.Cell>
                                    <Table.Cell style={{padding:0}}>{entry.logo &&<Image src={"https://api.carbill.dev.tecoyo.com/uploads/"+entry.logo} style={{width: '100%'}}/>}</Table.Cell>
                                    <Table.Cell>{entry.name}</Table.Cell>
                                    <Table.Cell>{entry.address + ", "+entry.postcode+" "+entry.city}</Table.Cell>
                                    <Table.Cell>{entry.owner}</Table.Cell>
                                    <Table.Cell>{entry.phone}</Table.Cell>
                                    <Table.Cell>{entry.fax}</Table.Cell>
                                    <Table.Cell><GetName id={entry.user_id} /></Table.Cell>
                                    <Table.Cell>{entry.payment_type?<Icon name="money bill alternate outline" />:<Icon name="phone square" />}</Table.Cell>
                                    <Table.Cell>{
                                        moment().isBefore(moment(entry.created_at).add(entry.trail,"days"))?
                                        moment(entry.created_at)
                                            .add(entry.trail,"days")
                                        .diff(
                                            moment()
                                        ,"days")+"/T":<Icon name="times"/>}</Table.Cell>
                                </Table.Row>
                            ) }
                        </Table.Body>}
                    </Table>

                    {this.state.data.data.length ==0 &&<Segment placeholder attached="bottom">
                        <Header icon><Icon name='search' /> Es wurde kein Kunde gefunden </Header>
                    </Segment>}
                </Segment>
                <Pagination 
                    onPageChange={this.changePage.bind(this)}
                    attached="bottom" 
                    boundaryRange={2} 
                    siblingRange={2}
                    fluid 
                    defaultActivePage={this.state.page} 
                    totalPages={Math.ceil(this.state.data.total / this.state.data.limit)} />
            </Segment>
        )
    }
}

export default withRouter(Admin)