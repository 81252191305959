const Errors =  {
"envkv-values-required":"The marker 'envkv' requires all consumption and emission values and the fuel type being set. EnVKV values are required for new cars, single day registration and demonstration vehicles.",
"constructionyear-registrationyear-pluasibility":"The year of construction cannot lie behind the first registration date. Please correct either the first registration date or the construction year",
"closingcosts-invalid":"Closing Costs Invalid",
"make-model-mismatch":"The selected model dooesn’t match the select make",
"envkv-carbon-dioxyd-emission-invalid":"Please enter a positive carbon dioxyd emission value or leave the field empty",
"financing-conditions-too-long":"Financing conditions too long",
"usedcarseal-criteria-not-matched":"The vehicle does not match the necessary criteria for the Used Car Seal you selected",
"envkv-consumption-combined-invalid":"Please enter a positive fuel consumption combined value or leave the field empty",
"endingrate-invalid":"Ending Rate Invalid",
"make-empty":"Please select a Make.",
"rateinsurance-invalid":"Rate Insurance Invalid",
"netprice-without-vat":"You must enter a valid VAT rate when using a net price",
"liftingheight-invalid":"The lifting height you entered is invalid",
"mileage-empty":"You must enter a mileage",
"vehicleIdentificationNumber-invalid":"The vehicle identification number (VIN) you entered is invalid",
"loadingspaceheight-invalid":"The loading space height is invalid",
"usedcarseal-on-new-car-warning":"The Used Car Seal can not be assigned to new cars",
"firstinstallment-invalid":"First Installment Invalid",
"highlights-2-invalid-chars":"The 2nd highlight contains invalid chars",
"dealerpriceamount-invalid":"The amount of the dealer price is invalid",
"price-type-price type invalid":"You must select a price type",
"description-contains-phone":"The description text is not allowed to contain a phone number",
"envkv-consumption-outer-invalid":"Please enter a positive fuel consumption (extra-urban) value or leave the field empty",
"highlights-1-too-long":"The 1st highlight is too long (max. 22 chars)",
"power-invalid-range":"Power must be in a range from 0 to 999.",
"envkv-consumption-outer-is-empty":"Please enter the fuel consumption (extra-urban)",
"operatinghours-invalid":"The operating hours you entered are invalid",
"nominalinterestrate-invalid":"Nominal Interest Rate Invalid",
"envkv-combined-power-consumption-invalid":"combined power consumption invalid",
"category-invalid":"The Category you entered is invalid",
"price-type-empty":"You must select a price type",
"invalid-video-url":"Invalid YouTube Link",
"ratepercent-invalid":"Rate Per Cent Invalid",
"price-empty":"A price must be provided.",
"period-invalid":"Duration Invalid",
"cubiccapacity-invalid":"The cubic capacity you entered is invalid",
"manufacturercolorname-too-long":"The manufacturer colour name you entered is too long",
"financing-mandatory-fields-new":"When booking the financing feature, all mandatory financing-values must be present (ratePerCent, monthlyRate, period, grossCreditAmount, closingCosts, rateInsurance, netCreditAmount, bank, nominalInterestRate, typeOfNominalInterestRate).",
"deliveryperiod-only-for-new-cars":"You can only enter a delivery period for new vehicles",
"model-empty":"For the vehicle-class Car a model is required. See Reference Data.",
"fuel-empty":"You must select a fuel type.",
"modeldescription-contains-phone":"The model description may not contain a phone number.",
"loadingspacelength-invalid":"The load capacity you entered is invalid",
"model-without-make":"Please select both Make and Model",
"envkv-and-electricity-failure":"Less Envkv Fields When Fuel Type Electro",
"manufacturercolorname-invalid-chars":"The manufacturer colour name contains invalid characters",
"deliverycost-invalid":"Delivery costs invalid",
"constructionyear-invalid":"The construction year you entered is invalid",
"usedcarseal-not-permitted":"You are not authorized to use the Used Car Seal you selected",
"countryversion-missing":"Countryversion necessary for new cars.",
"numberofbunks-invalid":"The number of bunks is invalid",
"highlights-3-too-long":"The 3rd highlight is too long (max. 22 chars)",
"highlights-2-too-long":"The 2nd highlight is too long (max. 22 chars)",
"select-country-version":"The country version is missing",
"highlights-3-invalid-chars":"The 3rd highlight contains invalid chars",
"vehiclelength-invalid":"The vehicle length is invalid",
"highlights-1-invalid-chars":"The 1st highlight contains invalid chars",
"first-registration":"A first registration date is required for all used vehicles.",
"numberOfPreviousOwners-invalid":"Number Of Previous Owners Invalid",
"deliverydate-year-too-low":"The delivery year is too low",
"dealer-price-not-supported":"For FSBO Ads there cannot be a dealer price.",
"installationheight-invalid":"The installation height you entered is invalid",
"loadcapacity-invalid":"The load capacity you entered is invalid",
"usagetype-demonstration-not-for-fsbo":"No Demonstration Vehicles",
"envkv-combined-power-consumption-is-empty":"combined power consumption is empty",
"modeldescription-too-long":"The model/variant you entered is too long (the model description may not be longer than 48 characters).",
"modeldescription-contains-url":"The model description may not contain a URL.",
"vat-rate-not-allowed-for-on-request":"VAT rate not allowed for on request",
"usagetype-unsupported-for-new-vehicles":"A new vehicle cannot be a vehicle with pre-registration, employees-one-year-car, classic car or demonstration-vehicle.",
"au-year-too-high":"Invalid year for AU (ASU - exhaust-inspection). The value must not be more than 6 years in future.",
"firstregistration-classiccar-conflict":"For classic cars the first registration must date back at least 20 years",
"shippingvolume-invalid":"The shipping volume is invalid",
"currency-invalid-for-site":"Currency invalid for site",
"grosscreditamount-invalid":"Gross Credit Amount Invalid",
"vehiclewidth-invalid":"The vehicle width is invalid",
"europalletstoragespaces-invalid":"The number of europallet storage spaces is invalid",
"constructiondate-invalid":"Date Of Manufacture Invalid",
"firstregistration-invalid":"The First Registration Date you entered is invalid",
"manufacturercolorname-contains-url":"The manufacturer colour name contains an URL",
"condition-classiccar-conflict":"The condition of a classic car must be set to 'used'",
"modeldescription-empty":"Please enter the model or respectively the variant for cars",
"category-empty":"Please select a category.",
"usedcarseal-on-damaged-car-warning":"The Used Car Seal can not be assigned to damaged cars",
"loadingspacewidth-invalid":"The load capacity you entered is invalid",
"envkv-carbon-dioxyd-emission-is-empty":"Please enter the carbon dioxyd emission value",
"firstregistration-employeescar-conflict":"First registration must not be more than 15 month in the past.",
"envkv-consumption-inner-invalid":"Please enter a positive fuel consumption (urban) value or leave the field empty",
"delivery-period-xor-delivery-date":"For new Cars either delivery date or delivery period must be provided, but not both at the same time.",
"envkv energy efficiency class is empty":"Enter Efficiency Class",
"average-price-not-for-damaged-vehicles":"Average price cannot be calculated for cars with unrepaired damage",
"mileage-used-invalid":"The mileage you entered is invalid for a used car",
"modeldescription-invalid-chars":"The model/variant you entered contains invalid characters",
"deliveryperiod-invalid":"Delivery period invalid",
"vat-rate-invalid":"The VAT rate is invalid",
"price-type-bidding-inconsistent":"You must select a price type",
"financing-bad-integrity":"The aggregated value of the financing model may not be less than the consumer price",
"numberOfPreviousOwners-unsupported":"You are not able to specify previous owners for this vehicle because it is a new vehicle",
"damageunrepaired-empty":"You must select whether the vehicle has a unrepaired damage",
"internalnumber-invalid-chars":"The entered internal number contains invalid characters",
"constructionyear-empty":"Please enter the construction year",
"bank-too-long":"Bank too long",
"firstregistration-newcar-conflict":"The first registration date must be empty for new vehicles",
"features-contains-invalid-value":"Features contain invalid value",
"internalnumber-too-long":"The internal number you entered is too long (max. 40 characters)",
"description-too-long":"The description you entered is too long",
"firstregistration-empty":"Please fill in the First Registration Date",
"consumerpriceamount-invalid":"The price amount is invalid. Please use only digits (no full stops or commas) when entering the price",
"liftingcapacity-invalid":"The lifting capacity you entered is invalid",
"modeldescription-contains-email":"The model description may not contain an e-mail address.",
"envkv-consumption-inner-is-empty":"Please enter the fuel consumption (urban)",
"axles-invalid":"The number of axles is invalid",
"numseats-invalid":"The number of seats you entered is invalid",
"dealer-price-to-high":"The dealer’s price cannot be higher than the retail price.",
"currency-empty":"Currency is empty",
"monthlyrate-invalid":"Rate Invalid",
"deliverydate-year-too-high":"The delivery year is too high",
"vehicleheight-invalid":"The vehicle height is invalid",
"envkv-consumption-combined-is-empty":"Please enter the fuel consumption combined",
"netcreditamount-invalid":"Net Credit Amount Invalid",
"description-contains-email":"The description text is not allowed to contain an email address",
"description-empty":"Please enter a description",
"duplicate-seller-inventory-key":"The internalNumber (AKA seller inventory key) must be unique for given seller",
"licensedweight-invalid":"The licensed weight you entered is invalid",
"au-year-too-low":"Invalid year for AU (ASU - exhaust-inspection). The value must not be before the year 1000.",
"mileage-new-invalid":"The mileage is invalid for a new car",
"hu-year-too-low":"Invalid year for HU (TUEV / general-inspection / technical inspection / hu-date). The value must not be before the year 1000.",
"internalnumber-contains-email":"The entered internal number contains an email address",
"hu-year-too-high":"Invalid year for HU (TUEV / general-inspection / technical inspection / hu-date). The value must not be more than 6 years in future.",
"renewal-for-site-id-other-than-germany-not-allowed":"Renewal is only allowed for Germany Site Id",
"manufacturercolorname-contains-email":"The manufacturer colour name contains an email address",
"average-price-only-for-cars":"Average price can only be calculated for cars",
"power-empty":"You must enter a power",
"consumer-price-not-in-range":"The price amount is not in a valid range",
"make-invalid-category":"The selected category is invalid for the selected make",
"batterycapacity-invalid":"The entered battery capacity is not in the range between 1 and 999 kWh",
"modelrange-too-long":"The model range contains too many characters",
"parkassists-cam-360-degrees-and-rear-view-cam-selected":"Rear view camera and 360° camera can’t be selected together",
"trimline-too-long":"The trim line contains too many characters",
"ad-deleted-by-mobile-quality":"The ad has been removed by the mobile.de quality team. Please see the adQuality for more details.",
"request-id-too-long":"The value submitted in the X-Mobile-Insertion-Request-Id is too long. Max length is 100 characters.",
"request-id-invalid-characters":"The value submitted int he X-Mobile-Insertion-Request-Id contains invalid characters. Only alphanumeric characters are allowed.",
}

export default Errors