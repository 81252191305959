import React, { Component } from "react";
import { Segment, Header, Button, Menu, Icon, Modal, Form } from "semantic-ui-react";
//import { Document, Page }  from 'react-pdf';
import { Document, Page }  from 'react-pdf/dist/entry.webpack';
import SignatureCanvas from 'react-signature-canvas'
import CarCompact from "../../Cars/compact";
import client from "../../../shared/feathers";
import ContractSingleEdit from "./edit";
import ContractSingleDocuments from "./files";
import Moment from "react-moment";
import moment from "moment";
class ContractSingle extends Component {
    state = {
        total: 0,
        loading: true,
        notFound: false,
        agbs_seller:false,
        agbs_buer: false,
        page:"view",
        contract:null
    }
    
    componentDidMount(){
        this.reactive = client.service("contracts").watch().get( this.props.match.params.id).subscribe(this.update.bind(this),()=>this.setState({loading:false, notFound: true}))
    }
    async update(data){
        this.setState({contract:data,loading:false})
    }
    componentDidUpdate(prevProps,prevState){
        if(this.state.loading == false&&this.state.notFound==false&&prevState.loading==true){
            if(document.getElementById('signatureContainer-seller')){
                this.setState({canvasSize:document.getElementById('signatureContainer-seller').offsetWidth})
            }
        }
    }
    onDocumentLoaded({ numPages }) {
        this.setState({ total: numPages, loading: false })
    }
    clearSignature(){
        this.refs.signature.clear()
    }
    clearSignatureSeller(){
        this.refs.signatureSeller.clear()
    }
    async save(insurance){
        this.setState({loading:true})
        client.service("contracts").patch(this.state.contract.id,{
            signature: this.refs.signature.getTrimmedCanvas().toDataURL(),
            signature_seller:this.refs.signatureSeller.getTrimmedCanvas().toDataURL(),
            status:"finish",
            insurance
        })
        await client.service("cars").patch(this.state.contract.car_id,{customer_id:this.state.contract.customer_id, sold:true})
        /* let user=await client.service("users").get(this.state.contract.user_id)
        let customer=await client.service("customers").get(this.state.contract.customer_id)
        let now = moment()
        await client.service("users").patch(user.id,{sales:user.sales + this.state.contract.price})
        await client.service("customers").patch(customer.id,{sales:customer.sales + this.state.contract.price})
        let sales_user = (await client.service("sales_user_daily").find({query:{user_id:user.id,year:now.year(),month:now.month()+1,day:now.date()}})).data
        console.log(sales_user,{user_id:user.id,year:now.year(),month:now.month()+1,day:now.date()})
        if(sales_user.length > 0){
            console.log("found")
            await client.service("sales_user_daily").patch(sales_user[0].id,{default:user.sales + this.state.contract.price})
        }else{
            console.log("notfound")
            await client.service("sales_user_daily").create({user_id:user.id,default:this.state.contract.price,year:now.year(),month:now.month()+1,day:now.date()})
        }
        sales_user = (await client.service("sales_user_monthly").find({query:{user_id:user.id,year:now.year(),month:now.month()+1}})).data
        if(sales_user.length > 0){
            await client.service("sales_user_monthly").patch(sales_user[0].id,{default:user.sales + this.state.contract.price})
        }else{
            await client.service("sales_user_monthly").create({user_id:user.id,default:this.state.contract.price,year:now.year(),month:now.month()+1})
        }
         sales_user = (await client.service("sales_user_daily").find({query:{year:now.year(),month:now.month()+1,day:now.date()}})).data
        if(sales_user.length > 0){
            await client.service("sales_user_daily").patch(sales_user[0].id,{default:user.sales + this.state.contract.price})
        }else{
            await client.service("sales_user_daily").create({default:this.state.contract.price,year:now.year(),month:now.month()+1,day:now.date()})
        }
        sales_user = (await client.service("sales_monthly").find({query:{year:now.year(),month:now.month()+1}})).data
        if(sales_user.length > 0){
            await client.service("sales_monthly").patch(sales_user[0].id,{default:user.sales + this.state.contract.price})
        }else{
            await client.service("sales_monthly").create({default:this.state.contract.price,year:now.year(),month:now.month()+1})
        } */
    }
    render() {

        if(this.state.loading){
            return <Segment basic><Segment loading placeholder></Segment></Segment>
        }
        if(this.state.notFound){
            return <Segment basic ><Segment placeholder color="red"> <Header icon><Icon name='search' /> Kaufvertrag wurde nicht gefunden.</Header> </Segment></Segment>
        }
        return (
            <div>
                <Segment.Group attached="bottom" style={{ width: window.innerWidth - 120,left: 120, right: 0, position: "fixed", top: -1, bottom: "auto", zIndex: 100 }}>
                    <Header attached="top">
                        Kaufvertrag für <CarCompact id={this.state.contract.car_id} field="manufacturer" /> <CarCompact id={this.state.contract.car_id} field="type" />
                        <Header.Subheader><CarCompact id={this.state.contract.car_id} field="identification" /> - #{this.state.contract.id} - <Moment format="DD.MM.YYYY">{this.state.contract.created_at}</Moment></Header.Subheader>
                    </Header>
                    <Menu attached="bottom">
                        <Menu.Item active={this.state.page =="view"} onClick={()=>this.setState({page:"view"})}>Vertrag</Menu.Item>
                        <Menu.Item active={this.state.page =="files"} onClick={()=>this.setState({page:"files"})}>Anhänge</Menu.Item>
                        {this.state.contract.status == "open" && <Menu.Item active={this.state.page =="edit"} onClick={()=>this.setState({page:"edit"})}>Bearbeiten</Menu.Item>}
                        <Menu.Menu position='right'>
                            {this.state.contract.status != "closed" && <Menu.Item><Button as="a" target="_blank" href={"https://api.carbill.dev.tecoyo.com/uploads/"+this.state.contract.document_blank}  icon labelPosition='left' ><Icon name="file pdf" />Download</Button></Menu.Item>}
                            {(this.state.contract.status == "finish" && !this.state.contract.receipt_id) && <Menu.Item><Button icon ><Icon loading name='spinner' /> Rechnung</Button></Menu.Item>}
                            {(this.state.contract.status == "finish" && !!this.state.contract.receipt_id) && <Menu.Item><Button primary onClick={()=>this.props.history.push("/receipts/view/"+this.state.contract.receipt_id)} >Rechnung</Button></Menu.Item>}
                            {this.state.contract.status == "finish" && <Menu.Item><Button as="a" target="_blank" href={"https://api.carbill.dev.tecoyo.com/uploads/"+this.state.contract.document_signed} positive icon labelPosition='left' ><Icon name="file pdf" />Download (Signiert)</Button></Menu.Item>}
                            {this.state.contract.status == "open" && <Menu.Item><Button onClick={async ()=>await client.service("contracts").patch(this.state.contract.id,{status:"closed"})} negative>Stornieren</Button></Menu.Item>}
                        </Menu.Menu>
                    </Menu>
                </Segment.Group>
                {this.state.page == "view" && 
                <Segment loading={this.state.loading} basic>
                    <Segment placeholder style={{ alignItems: "center", paddingTop: 100, paddingBottom: 200 }}>
                        <Document
                            file={this.state.contract.signature ? "https://api.carbill.dev.tecoyo.com/uploads/"+this.state.contract.document_signed : "https://api.carbill.dev.tecoyo.com/uploads/"+this.state.contract.document}
                            onLoadSuccess={this.onDocumentLoaded.bind(this)}
                        >
                            {(new Array(this.state.total).fill(undefined).map((val, idx) => idx)).map((entry, id) =>
                                <Segment key={id} style={{ padding: 0, maxWidth: window.innerWidth / 2, textAlign: "center", overflow: "hidden" }}>
                                    <Page width={window.innerWidth / 2} pageNumber={id + 1} />
                                </Segment>
                            )}
                        </Document>
                    </Segment>
                </Segment>
                }
                {this.state.page == "edit" && 
                    <Segment style={{position:"absolute",left:"1%",width:"98%",top:100,bottom:300,overflow:"auto"}}>
                        <ContractSingleEdit contract={this.state.contract} />
                    </Segment>
                }
                {this.state.page == "files" && 
                    <Segment style={{position:"absolute",left:"1%",width:"98%",top:100,bottom:300,overflow:"auto"}}>
                        <ContractSingleDocuments contract={this.state.contract} />
                    </Segment>
                }
                {this.state.contract.status == "open" && <Segment.Group attached="top" style={{ width: window.innerWidth - 250, left: 249, right: 0, position: "fixed", top: "auto", bottom: 0, zIndex: 100, alignItems: "center", margin: 0 }}>
                    <Segment.Group horizontal style={{backgroundColor:"#fff"}}>
                        <Segment width="8">
                            <Form widths="equal">
                                <Form.Field  >
                                    <label >Verkäufer Unterschrift<up>*</up></label>
                                    <Segment.Group >
                                        <Segment id="signatureContainer-seller" attached="top" style={{ padding: 0 }} >
                                            <SignatureCanvas canvasProps={{ width: this.state.canvasSize, height: 100, className: 'sigCanvas' }} ref="signatureSeller" />
                                        </Segment>
                                        <Button onClick={this.clearSignatureSeller.bind(this)} attached="bottom" size="tiny">Zurücksetzen</Button>
                                    </Segment.Group>
                                    <div style={{ clear: "both" }} />
                                </Form.Field>
                                <Form.Checkbox onChange={()=>this.setState({agbs_seller:!this.state.agbs_seller})} name="agbs_seller" label="Der Verkäufer sich erklärt mit dieser elektronischen Darstellung seiner Unterschrift rechtlich verpflichtet." />
                            </Form>
                        </Segment>
                        <Segment width="8">
                            <Form widths="equal">
                                <Form.Field>
                                    <label >Käufer Unterschrift<up>*</up></label>
                                    <Segment.Group >
                                        <Segment id="signatureContainer-buyer" attached="top" style={{ padding: 0 }} >
                                            <SignatureCanvas canvasProps={{ width: this.state.canvasSize, height: 100, className: 'sigCanvas' }} ref="signature" />
                                        </Segment>
                                        <Button onClick={this.clearSignature.bind(this)} attached="bottom" size="tiny">Zurücksetzen</Button>
                                    </Segment.Group>
                                    <div style={{ clear: "both" }} />
                                </Form.Field>
                                <Form.Checkbox onChange={()=>this.setState({agbs_buyer:!this.state.agbs_buyer})} name="agbs_buyer" label="Der Käufer sich erklärt mit dieser elektronischen Darstellung seiner Unterschrift rechtlich verpflichtet." />
                            </Form>
                        </Segment>
                    </Segment.Group>
                    <Segment style={{textAlign:"center"}}>
                        <Button positive disabled={!(this.state.agbs_seller && this.state.agbs_buyer)} onClick={()=>this.save(false)}>Abschließen</Button>
                        {/* <Modal trigger={<Button positive disabled={!(this.state.agbs_seller && this.state.agbs_buyer)}>Abschließen</Button>} basic size='small'>
                            <Header icon='shield' content='Haben Sie zu Ihrem Fahrzeug schon eine passende Versicherung?' />
                            <Modal.Content><p>Möchten sie unverbindlich und kostenlos Versicherungsangebote von uns erhalten?</p></Modal.Content>
                            <Modal.Actions>
                                <Button basic color='red' inverted onClick={()=>this.save(false)}> <Icon name='remove' /> Nein</Button>
                                <Button color='green' inverted onClick={()=>this.save(true)}><Icon name='checkmark' /> Ja </Button> 
                            </Modal.Actions>
                        </Modal> */}
                    </Segment>
                </Segment.Group>}
            </div>
        )
    }
}

export default ContractSingle