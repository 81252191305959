import React,{ Component } from "react";
import moment from 'moment'
import FullCalendar from 'fullcalendar-reactwrapper';
import { Segment } from "semantic-ui-react";
import client from '../../shared/feathers'
import deLocale from '@fullcalendar/core/locales/de';
import Context from "../context";
class Calendar extends Component{
    state={
        events:[]
    }
    componentDidMount(){
    }
    render(){
        return (
            <Segment basic>
                <Segment>
                    <FullCalendar 
                        id = "Test-Calc"
                        locales={[ deLocale ]}
                        locale="de"
                        header = {{
                            left: 'title today',
                            right: 'month,agendaWeek,basicDay,listWeek prev,next',
                        }}
                        locale="de"
                        buttonText={{
                            today:    'Heute',
                            month:    'Monat',
                            week:     'Woche',
                            day:      'Tag',
                            list:     'Liste'
                        }}
                        slotLabelFormat={"HH:mm"}
                        weekNumbers
                        events = {(start,end,lol,callback)=>{
                            console.log(
                                {
                                    query:{
                                        $and:[
                                            {start:{$le:end.format("YYYY-MM-DD HH:mm:SS")}},
                                            {start:{$ge:start.format("YYYY-MM-DD HH:mm:SS")}},
                                        ]
                                    }
                                })
                            client.service("events").find(
                                {
                                    query:{
                                        $and:[
                                            {start:{$lte:end.format("YYYY-MM-DD HH:mm:SS")}},
                                            {start:{$gte:start.format("YYYY-MM-DD HH:mm:SS")}},
                                        ]
                                    }
                                }
                            ).then(
                                (result)=>{
                                callback(
                                    result.data.map(elm=>({
                                        title: elm.title,
                                        start: moment(elm.start),
                                        id:elm.id,
                                        end:moment(elm.end),
                                        backgroundColor:elm.color
                                    }) ) ) 
                                }
                            )
                        }}
                        defaultDate={moment().format("YYYY-MM-DD")}
                        navLinks= {true} // can click day/week names to navigate views
                        editable= {true}
                        eventLimit= {true} // allow "more" link when too many events
                        height={580}
                        selectable
                        timeFormat="H:mm"
                        select={(start,end)=>this.context.openEvent(0,start.format("DD.MM.YYYY HH:mm"),end.format("DD.MM.YYYY HH:mm"))}
                        eventClick={(e)=>{this.context.openEvent(e)}}
                    />
                </Segment>
            </Segment>
        )
    }
}

Calendar.contextType = Context
export default Calendar