import React, { Component } from "react";
import { Segment, Menu, Icon } from "semantic-ui-react";
import chance from "chance";
import CarsList from './list'
import CarNew from "./add";

let IChance = new chance()
class Cars extends Component {
    render() {
        return (
            <Segment basic >
                {!this.props.hideMenu && <Menu>
                    <Menu.Item><b>Verkaufte Fahrzeuge</b></Menu.Item>
                    <Menu.Menu position="right">
                    </Menu.Menu>
                </Menu>}
                <CarsList query={{sold:true}} />
            </Segment>
        )
    }
}

export default Cars