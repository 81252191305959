import React, {Component} from 'react'
import {  Grid,Segment, } from 'semantic-ui-react'
import {withRouter, Route} from 'react-router-dom'

import RegisterContext from "./context";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step1Desc from "./Descriptions/Step1";
import Step2Desc from "./Descriptions/Step2";
import Step3Desc from "./Descriptions/Step3";

class Register extends Component{
    state={
        fullsize:false,
        seller:0,
    }
    componentDidMount(){
        document.title = 'AUTODIGI - Registrieren';
        const params = new URLSearchParams(this.props.location.search);
        if(params.get('fullsize')){
            if(params.get('fullsize')=="1"){
                this.setState({fullsize:true})
            }
        }
        if(params.get('seller')){
            this.setState({seller:params.get('seller')})
        }
    }
    state={
        user:{
            salutation:"Herr",
            password:""
        },
        company:{
            country:"DE"
        },
        changeUser:(field,value)=>{
            let user = {...this.state.user,[field]:value}
            this.setState({user})   
        },
        changeCompany:(field,value)=>{
            let company = {...this.state.company,[field]:value}
            this.setState({company})
        }
    }

    render(){
        if(this.state.fullsize){
            return(
                <RegisterContext.Provider value={this.state}>
                    <Segment.Group horizontal  style={{ height: '100%',position:"absolute",top:0,bottom:0,left:0,right:0 , margin:0}}>
                        <Segment style={{minWidth:470, maxWidth:500}}>
                            <Route path={"/Authentification/Register"} exact component={Step1} />
                            <Route path={"/Authentification/Register/Step-2"} exact component={Step2} />
                            <Route path={"/Authentification/Register/Step-3"} exact component={Step3} />
                        </Segment>
                        <Segment color="green" inverted >
                            <Route path={"/Authentification/Register"} exact component={Step1Desc} />
                            <Route path={"/Authentification/Register/Step-2"} exact component={Step2Desc} />
                            <Route path={"/Authentification/Register/Step-3"} exact component={Step3Desc} />
                        </Segment>
                    </Segment.Group>
                </RegisterContext.Provider>
            )
        }
        return (
            <RegisterContext.Provider value={this.state}>
                <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
                    <Grid.Column textAlign='left' style={{ maxWidth: 760 }}>
                    <center style={{marginTop:20}}><img height="70" src="/autodigi_weiss.png"></img></center>
                        <Segment.Group horizontal >
                            <Segment style={{minWidth:"auto", maxWidth:470}}>
                                <Route path={"/Authentification/Register"} exact component={Step1} />
                                <Route path={"/Authentification/Register/Step-2"} exact component={Step2} />
                                <Route path={"/Authentification/Register/Step-3"} exact component={Step3} />
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                </Grid>
                <div style={{paddingLeft:10,position:"absolute",top:0,left:"25%",color:"#fff",paddingTop:10,paddingBottom:10,marginTop:85,width:"75%", textAlign:"left",background:"linear-gradient(to right, rgba(0,0,0,1) 0%,rgba(125,185,232,0) 100%)"}}>
                    <Route path={"/Authentification/Register"} exact component={Step1Desc} />
                    <Route path={"/Authentification/Register/Step-2"} exact component={Step2Desc} />
                    <Route path={"/Authentification/Register/Step-3"} exact component={Step3Desc} />
                </div>
            </RegisterContext.Provider>
        )
    }
} 


export default withRouter(Register)