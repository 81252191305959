import React, { Component } from "react";
import { Segment, Button, Input, Form, Modal, List, Header, Icon, Message, Image, Grid } from "semantic-ui-react";
import MaskedInput from 'react-text-mask'
import Dropzone from 'react-dropzone'
import { withRouter } from "react-router-dom";
import client from '../../shared/feathers'
import numberToText from "number-to-text/converters/de";

import {CarBrand, Category} from './sync/'
import AdSettings from "./AdSettings";
import moment from "moment";
import Classification from "./sync/Classification";

class CarNew extends Component {
    
    state={
        carBrands:["Mercedes-Benz","Volkswagen","BMW","Audi","Abarth","AC","Acura","Aixam","Alfa Romeo","ALPINA","Artega","Asia Motors","Aston Martin","Austin","Austin Healey","Bentley","Borgward","Brilliance","Bugatti","Buick","Cadillac","Casalini","Caterham","Chatenet","Chevrolet","Chrysler","Citroën","Cobra","Corvette","Cupra","Dacia","Daewoo","Daihatsu","DeTomaso","Dodge","Donkervoort","DS Automobiles","Ferrari","Fiat","Fisker","Ford","GAC Gonow","Gemballa","GMC","Grecav","Hamann","Holden","Honda","Hummer","Hyundai","Infiniti","Isuzu","Iveco","Jaguar","Jeep","Kia","Koenigsegg","KTM","Lada","Lamborghini","Lancia","Land Rover","Landwind","Lexus","Ligier","Lincoln","Lotus","Mahindra","Maserati","Maybach","Mazda","McLaren","MG","Microcar","MINI","Mitsubishi","Morgan","Nissan","NSU","Oldsmobile","Opel","Pagani","Peugeot","Piaggio","Plymouth","Pontiac","Porsche","Proton","Renault","Rolls-Royce","Rover","Ruf","Saab","Santana","Seat","Skoda","Smart","speedART","Spyker","Ssangyong","Subaru","Suzuki","Talbot","Tata","TECHART","Tesla","Toyota","Trabant","Triumph","TVR","Volvo","Wartburg","Westfield","Wiesmann","Andere"],
        type:null,
        admission_first:"",
        price:0,
        files: [],
        images: [],
        uploadingImage: false,
        uploadingFile: false,
        progress:0,
        description: "",
        inspection_month: "",
        inspection_year: "",
        saving:false
    }
    handleChange = (e, { name,value }) => this.setState({ [name]:value })
    
    save(){
        let {category,mobile_adSettings,classification,kilometer,internal_id,manufacturer,type,identification,admission_first,admission_nr,price,inspection_month,inspection_year,tax,commercial,imported,autoscout24,mobile,description,yt_video,images,files} = this.state
        this.setState({saving:true})
        client.service("cars").create({category,mobile_adSettings:JSON.stringify(mobile_adSettings),classification,kilometer,internal_id,manufacturer,type,identification,admission_first:moment(admission_first,"DD-MM-YYYY").format("YYYY-MM-DD"),admission_nr,price,inspection_month,inspection_year,tax,commercial,imported,autoscout24,mobile,description,yt_video,images:JSON.stringify(images),files:JSON.stringify(files)}).then((result)=>{
            this.props.history.push("/cars/single/"+result.id)
        }).catch((e)=>{
            console.log(e)
            this.setState({saving:false,error:true})
        })
    }
    async uploadingFile(file,field,loadingField,last){
        let reader  = new FileReader();
            reader.addEventListener("load", () =>{
                client.service('uploads')
                    .create({uri: reader.result})
                    .then((response)=>{
                        this.setState({ [field]: [...this.state[field],{name:file.name,id:response.id, sort:0}], [loadingField]:last });
                    }).catch(()=>{
                        this.setState({ [loadingField]:last} );
                    });
            }, false);
            reader.readAsDataURL(file);
    }
    async onDropImages(images){
        this.setState({uploadingImage:true})
        let counter = 0
        for (const image of images) {
            counter++
            this.uploadingFile(image, "images","uploadingImage",!(counter >=images.length))
        }
    }
    async onDrop(images){
        this.setState({uploadingFile:true})
        let counter = 0
        for (const image of images) {
            counter++
            this.uploadingFile(image, "files","uploadingFile",!(counter >=images.length))
        }
    }
    deleteDoc(index,field){
        delete this.state[field][index];
        this.setState({[field]:this.state[field]})
    }
    changeSort(index,field,value){
        this.state[field][index].sort = value;
        this.setState({[field]:this.state[field]})
    }
    renderFields(){
        return (
            <div>
                <Grid columns="equal" style={{marginBottom:10}}>
                    <Grid.Column>
                        <Segment color="black">
                            <Header as="h5">Interne Fahrzeugnummer<up>*</up></Header>
                            <Form.Input name="internal_id" required onChange={this.handleChange.bind(this)} />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment color="black">
                            <Header as="h5">Klassifikation {this.state.classification?"":"- Bitte auswählen"}</Header>
                            <Classification value={this.state.classification} onChange={(a,e)=>this.setState({classification:e.value,type:null,manufacturer:null})} />
                        </Segment>
                    </Grid.Column>
                </Grid>
                {this.state.classification &&<div>
                     <Segment color="black">
                        <Header as="h5">Marke, Modell, Variante</Header>
                        <CarBrand category={this.state.category} type={this.state.type} classification={this.state.classification} manufacturer={this.state.manufacturer} onChange={data=>this.setState({manufacturer:data.manufacturer,type:data.type,category:data.category})} />
                    </Segment>
                     <Segment color="black">
                        <Header as="h5">Preis</Header>
                        <Form.Group widths="equal">
                            <Form.Input required name="price" value={this.state.price} label="Gesamtpreis in Euro" type="number" onChange={this.handleChange.bind(this)} />
                            <Form.Field >
                                <label>in Worten</label>
                                <p style={{marginTop:12}}>{numberToText.convertToText(this.state.price ? this.state.price : 0)} </p>
                            </Form.Field>
                        </Form.Group>
                        <Form.Checkbox name="tax" onChange={()=>this.setState({tax:!this.state.tax})} toggle label="Mit MwSt."  />

                    </Segment>
                     <Segment color="black">
                        <Header as="h5">Datenblatt</Header>
                        <Form.Input name="identification" required  onChange={this.handleChange.bind(this)}  label="Fahrzeug-Ident.-Nr" />
                        <Form.Group widths="equal">
                            <Form.Input  label="Datum der Erstzulassung"  >
                                <MaskedInput
                                    onChange={(e)=>this.handleChange(e,{name:"admission_first",value:e.target.value})} 
                                    pattern="[(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                                    placeholder="DD-MM-YYYY" 
                                    mask={[/\d/,/\d/,"-",/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/]}  
                                    guide={true} {...this.props} required  />
                            </Form.Input>
                            <Form.Input name="admission_nr" onChange={this.handleChange.bind(this)}  label="Zulassungsnummer" />
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Form.Input name="kilometer" required onChange={this.handleChange.bind(this)} type="number" label="Kilometerstand" />
                            <Form.Field >
                                <Form.Group widths="equal">
                                    <Form.Input required label="Nächste AU" onChange={this.handleChange.bind(this)}  >
                                        <MaskedInput
                                            onChange={(e)=>this.handleChange(e,{name:"inspection_month",value:e.target.value})}
                                            pattern="[0-9]{2}" placeholder="MM" mask={[/\d/,/\d/]} guide={true} {...this.props} required  />
                                    </Form.Input>
                                    <Form.Input  label="&nbsp;" onChange={this.handleChange.bind(this)}  >
                                        <MaskedInput
                                            onChange={(e)=>this.handleChange(e,{name:"inspection_year",value:e.target.value})}
                                            pattern="[0-9]{4}" placeholder="YYYY" mask={[/\d/,/\d/,/\d/,/\d/]} guide={true} {...this.props} required  />
                                    </Form.Input>
                                </Form.Group>
                            </Form.Field>
                        </Form.Group>
                        <Form.TextArea name="description" onChange={this.handleChange.bind(this)} label="Reparaturen, Änderungen am Fahrzeug" />
                        <Form.Field>
                            <label>Weitere Angaben</label>
                            <Form.Group>
                                <Form.Checkbox name="commercial" onChange={()=>this.setState({commercial:!this.state.commercial})} style={{marginTop:10}} toggle label="Wurde gewerblich genutzt"  />
                                <Form.Checkbox name="imported" onChange={()=>this.setState({imported:!this.state.imported})} style={{marginTop:10}} toggle label="Importfahrzeug"  />
                            </Form.Group>
                        </Form.Field>
                    </Segment>
                    <Form.Input name="yt_video" onChange={this.handleChange.bind(this)} label="Youtube-Video" placeholder="https://youtube.com/watch?v=ASd23sd" />

                    <Segment.Group>
                        <Header attached="top">Bilder</Header>

                            <Segment attached="bottom">
                                <Dropzone onDrop={this.onDropImages.bind(this)} maxSize={20000000} accept="image/*">
                                {({getRootProps, getInputProps, isDragActive}) => (
                                    <Segment placeholder={!isDragActive} style={{minHeight:80}} {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {this.state.uploadingImage ? "Wird gerade hochgeladen..." :"Ziehe die Datei hierhin, um sie hochzuladen."}
                                        {!this.state.uploadingImage && <small className="helper">Maximale Datei-Größe von 10MB</small>}
                                    </Segment>
                                )}
                                </Dropzone>
                                {this.state.images.length > 0 &&<List>
                                    {this.state.images.map((file,index) => (
                                        <List.Item >
                                            <List.Content floated='right'>
                                                <Input onChange={(e,{value})=>this.changeSort(index,"images",value)} type="number" size="small" value={file.sort == 0 ? "":file.sort} placeholder={"Sortierung bsp. "+(index+1)} style={{marginRight:10}} />
                                                <Button onClick={()=>this.deleteDoc(index,"images")} as="a" negative icon><Icon name="trash" /></Button>
                                            </List.Content>
                                            <Image avatar src={"https://api.carbill.dev.tecoyo.com/uploads/"+file.id} />
                                            <List.Content>{file.name}</List.Content>
                                        </List.Item>
                                    ))}
                                </List>}
                            </Segment>
                    </Segment.Group>

                    <Segment.Group>
                        <Header attached="top">Dokumente</Header>

                        <Segment attached="bottom">
                            <Dropzone maxSize={20000000} onDrop={this.onDrop.bind(this)} >
                            {({getRootProps, getInputProps, isDragActive}) => (
                                <Segment placeholder={!isDragActive} style={{minHeight:80}} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {this.state.uploadingFile ? "Wird gerade hochgeladen..." :"Ziehe die Datei hierhin, um sie hochzuladen."}
                                    {!this.state.uploadingImage && <small className="helper">Maximale Datei-Größe von 10MB</small>}
                                </Segment>
                            )}
                            </Dropzone>
                            {this.state.files.length > 0 &&<List>
                                {this.state.files.map((file,index) => (
                                    <List.Item >
                                        <List.Content floated='right'>
                                            <Button onClick={()=>this.deleteDoc(index,"files")} as="a" negative icon><Icon name="trash" /></Button>
                                        </List.Content>
                                        <List.Content>{file.name}</List.Content>
                                    </List.Item>
                                ))}
                            </List>}
                        </Segment>
                </Segment.Group>
                {this.state.error && <Message negative >Es ist ein Fehler beim speichern aufgetreten. Bitte überprüfen Sie alle Felder oder kontaktieren Sie den Support.</Message>}
                <Form.Button fluid primary  >Speichern</Form.Button>
                </div>}
            </div>
        )
    }
    render() {
        return (
            <Modal closeIcon closeOnDimmerClick={false} trigger={this.props.trigger} >
                <Modal.Header>Neues Auto hinzufügen</Modal.Header>
                <Modal.Content >
                    <Form loading={this.state.saving} onSubmit={()=>this.save()}>
                        {this.renderFields()}
                    </Form>
                </Modal.Content>
            </Modal >
        )
    }
}

export default withRouter(CarNew)