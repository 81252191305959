import React,{ Component } from "react";
import { Segment, Header, Grid, Form, Icon, Label, Button, Table, Menu, Checkbox } from "semantic-ui-react";
import client from "../../shared/feathers";
import Currency from "react-currency-formatter";
import moment from "moment"
import SellersList from "../Sellers/list";
import CustomerCompact from "../Customers/compact";
import Moment from "react-moment";

class AdminSingle extends Component{
    state={
        loading:true,
        error:false,
        notFound: false,
        company:null,
        saving:false,
        page:"info"
    }
    handleChange = (e, { name,value }) => this.setState({ [name]:value })
    componentDidMount() {
        this.reactive = client.service("companies").watch().get(this.props.match.params.id).subscribe(this.update.bind(this),()=>this.setState({loading:false, notFound: true}))
    }
    async update(data){
        this.setState({...data, loading:false})
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }

    async save(){
        this.setState({saving:true})
        const {name,owner,tax,vat,address,address_extra,postcode,city,phone,fax,maxUsers,trail,mobile_username,mobile_password} = this.state
        await client.service("companies").patch(this.state.id,{name,owner,tax,vat,address,address_extra,postcode,city,phone,fax,maxUsers,trail,mobile_username,mobile_password})
        this.setState({saving:false})
    }
    render(){
        if(this.state.loading){
            return <Segment basic><Segment loading placeholder></Segment></Segment>
        }
        if(this.state.notFound){
            return <Segment basic ><Segment placeholder color="red"> <Header icon><Icon name='search' /> Unternehmen wurde nicht gefunden.</Header> </Segment></Segment>
        }
        return (<Segment basic>
            <Segment.Group  style={{backgroundColor:"#fff"}}>
                <Grid attached="top" columns="equal" >
                    <Grid.Column>
                        <Segment style={{boxShadow:"none", border:0}}>
                            <Header>
                                {this.state.id} - {this.state.name}
                                <Header.Subheader >
                                    Erstellt: <Moment format="DD.MM.YYYY">{this.state.created_at}</Moment>&nbsp;
                                    
                                    Probezeit: {
                                        moment().isBefore(moment(this.state.created_at).add(this.state.trail,"days"))?
                                        moment(this.state.created_at)
                                            .add(this.state.trail,"days")
                                        .diff(
                                            moment()
                                        ,"days")+"/Tage":<Icon name="times"/>}
                                </Header.Subheader>
                            </Header>
                        </Segment>
                    </Grid.Column>
                </Grid>
                <Menu attached="bottom">
                    <Menu.Item onClick={()=>this.setState({page:"info"})} active={this.state.page=="info"}>Information</Menu.Item>
                    <Menu.Item onClick={()=>this.setState({page:"users"})} active={this.state.page=="users"}>Benutzer</Menu.Item>
                    <Menu.Item>SEPA</Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item color="red">Aktiviert <Checkbox checked={this.state.isActive==1} onClick={async ()=>await client.service("companies").patch(this.state.id,{isActive:!this.state.isActive})} style={{marginLeft:10}} toggle /></Menu.Item>
                    </Menu.Menu>
                </Menu>
            </Segment.Group>
            <Grid>

                {this.state.page=="users" && <Grid.Column><SellersList noUpgrade query={{company_id:this.state.id}} /></Grid.Column>}
                {this.state.page=="info" && <Grid.Row columns="equal">
                    <Grid.Column>
                        <Segment>
                            <Header>Unternehmen</Header>
                            <Form loading={this.state.saving} onSubmit={this.save.bind(this)} size="small">
                                <Form.Input required defaultValue={this.state.name} onChange={this.handleChange.bind(this)} name="name" label="Name" />
                                <Form.Input defaultValue={this.state.owner} onChange={this.handleChange.bind(this)} label="Inhaber" name="owner" />
                                <Form.Input defaultValue={this.state.tax} onChange={this.handleChange.bind(this)} label="Steuernummer"  name="tax" />
                                <Form.Input defaultValue={this.state.vat} onChange={this.handleChange.bind(this)} label="Ustd.Id." name="vat" />
                                <Form.Input required defaultValue={this.state.address} onChange={this.handleChange.bind(this)} label="Adresse" name="address" />
                                <Form.Input defaultValue={this.state.address_extra} onChange={this.handleChange.bind(this)} label="Adresszusatz" name="address_extra" />
                                <Form.Group widths="equal">
                                    <Form.Input defaultValue={this.state.postcode} onChange={this.handleChange.bind(this)} label="PLZ" name="postcode" />
                                    <Form.Input defaultValue={this.state.city} onChange={this.handleChange.bind(this)} label="Stadt" name="city" />
                                </Form.Group>
                                <Form.Input required defaultValue={this.state.phone} onChange={this.handleChange.bind(this)} label="Telefon" name="phone" />
                                <Form.Input defaultValue={this.state.fax} onChange={this.handleChange.bind(this)} label="Fax" name="fax" />
                                <Form.Input required defaultValue={this.state.maxUsers} onChange={this.handleChange.bind(this)} label="Maximale Benutzer" type="number" name="maxUsers" />
                                <Form.Input max="180" required defaultValue={this.state.trail} onChange={this.handleChange.bind(this)} label="Probetage" type="number" name="trail" />
                                <Segment>
                                    <Header>Mobile.de</Header>
                                    <Form.Input defaultValue={this.state.mobile_username} onChange={this.handleChange.bind(this)} label="Nutzername" name="mobile_username" />
                                    <Form.Input defaultValue={this.state.mobile_password} onChange={this.handleChange.bind(this)} label="Passwort" name="mobile_password" />
                                </Segment>
                                <Form.Button>Speichern</Form.Button>
                            </Form>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment.Group>
                            <Header attached="top">Zahlungsinformationen</Header>
                            <Segment attached="bottom">
                                {this.state.payment_type=="sepa" && <Form >
                                    <Form.Group widths="equal">
                                        <Form.Input value={JSON.parse(this.state.payment_data).firstname} required label="Vorname" />
                                        <Form.Input value={JSON.parse(this.state.payment_data).firstname} required label="Nachname" />
                                    </Form.Group>
                                    <Form.Input value={JSON.parse(this.state.payment_data).iban} required label="IBAN" />
                                    <Form.Input value={JSON.parse(this.state.payment_data).bic} required label="BIC" />
                                </Form>}
                                {!this.state.payment_type&& <b>Keine Angaben</b>}
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                </Grid.Row>}
            </Grid>
        </Segment>)
    }
}

export default AdminSingle