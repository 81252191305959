import React,{ Component } from "react";
import {Tab, Grid, Segment, Header} from "semantic-ui-react";
import FullCalendar from 'fullcalendar-reactwrapper';
import { ResponsiveLine } from '@nivo/line'
import moment from 'moment'

import client from '../../../shared/feathers'
import Context from "../../context";

const f2D=(x)=>{
    return ("0"+String(x)).slice(-2)
}

class SellerSingleStats extends Component{
    
    state={
        loadingMonth:true,
        loadingYear:true,
        Year:[],
        Month:[],
        MonthToLess:false,
        YearToLess:false,
    }

    async componentDidMount(){
            client.service("sales_user_daily").find({query:{$limit:30,$sort:{day:-1},user_id:this.props.user.id,month:moment().subtract(1,"month").month(),year:moment().subtract(1,"month").year()}}).then(result=>{
                if(result.data.length == 0){ this.setState({MonthToLess:true,loadingMonth:false});return;}
                this.setState({
                    loadingMonth:false,
                    Month:[{ 
                        id: this.props.user.firstname + " " + this.props.user.lastname,
                        data: result.data.map((entry, id) => 
                            ({
                                y: entry.default,
                                x: moment(entry.year+"-"+f2D(entry.month)+"-"+f2D(entry.day) ,"YYYY-MM-DD").toDate()
                            })
                        )
                    }]
                })
            })
            client.service("sales_user_monthly").find({query:{user_id:this.props.user.id,year:moment().year()}}).then(async result=>{
                if(result.data.length==0){this.setState({loadingYear:false,YearToLess:true}); return;}
                this.setState({
                    Year:[
                        { 
                            id: this.props.user.firstname + " " + this.props.user.lastname,
                            data: result.data.map((entry, id) => 
                                ({
                                    y: entry.default,
                                    x: moment(entry.year+"-"+f2D(entry.month)+"-"+f2D(entry.day),"YYYY-MM-DD").toDate()
                                }) 
                            )
                        }
                    ],
                    loadingYear:false
                })
            })
    }
    render(){
        return (
            <Tab.Pane attached="bottom" style={{marginTop:0}}>
                <Grid columns="equal">
                    <Grid.Row>
                        <Grid.Column>
                            <Segment.Group>
                                <Header attached="top">Umsatz der letzten 12 Monate</Header>
                                <Segment loading={this.state.loadingMonth} placeholder={this.state.MonthToLess} attached="bottom" style={{padding:0, height:500}}>
                                    {!this.state.MonthToLess && <ResponsiveLine 
                                        colors={"#7baaf7"}
                                        offsetType="none"
                                        borderWidth={2}
                                        borderColor="inherit"
                                        fillOpacity={0.3}
                                        margin={{
                                            top:10,
                                            left:40,
                                            bottom:40
                                        }}
                                        axisBottom={{
                                            "orient": "bottom",
                                            "tickSize": 5,
                                            "tickPadding": 5,
                                            "tickRotation": 0,
                                            "legend": "",
                                            "legendOffset": 36,
                                            format:(d)=>moment(d).format("MM.YY")
                                        }}
                                        data={this.state.Month}
                                    />}
                                </Segment>
                            </Segment.Group>
                        </Grid.Column>
                        <Grid.Column>
                            <FullCalendar 
                                header = {false}
                                locale="de"
                                height={540}
                                defaultView = 'listWeek'
                                events = {(start,end,lol,callback)=>{
                                    client.service("events").find({user_id:this.props.user.id,start:{$le:end,$ge:start}}).then(
                                        (result)=>{
                                        callback(
                                            result.data.map(elm=>({
                                                title: elm.title,
                                                start: elm.start,
                                                id:elm.id,
                                                end:elm.end
                                            }) ) ) 
                                        }
                                    )
                                }}
                                selectable
                                defaultDate={moment().format("YYYY-MM-DD")}
                                navLinks= {true} // can click day/week names to navigate views
                                editable= {true}
                                eventLimit= {true} // allow "more" link when too many events
                                selectable
                                timeFormat="H:mm"
                                select={(start,end)=>this.context.openEvent(0,start,end)}
                                eventClick={(e)=>{this.context.openEvent(e)}} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment.Group>
                                <Header attached="top">Umsatz der letzten 30 Tage</Header>
                                <Segment attached="bottom" loading={this.state.loadingYear} placeholder={this.state.YearToLess} style={{padding:0, height:400}}>
                                    {this.state.YearToLess && <ResponsiveLine 
                                        colors={"#7baaf7"}
                                        offsetType="none"
                                        borderWidth={2}
                                        borderColor="inherit"
                                        fillOpacity={0.3}
                                        margin={{
                                            top:10,
                                            left:40,
                                            bottom:40
                                        }}
                                        axisBottom={{
                                            "orient": "bottom",
                                            "tickSize": 5,
                                            "tickPadding": 5,
                                            "tickRotation": 0,
                                            "legend": "",
                                            "legendOffset": 36,
                                            format:(d)=>moment(d).format("DD.MM")
                                        }}
                                        data={this.state.Year}
                                    />}
                                </Segment>
                            </Segment.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Tab.Pane>
        )
    }
}
SellerSingleStats.contextType = Context

export default SellerSingleStats