import React, { Component } from "react";
import { Segment, Header, Input, Checkbox, Modal, Form, List, Button, Icon, Message} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Dropzone from 'react-dropzone'
import client from '../../shared/feathers'
import moment from "moment";

class ContractsNew extends Component {
    state={
        customer_id:null,
        holding: false,
        saving:false,
        files: [],
        customers:[],
        cars:[],
        customer: {type:"commercial"},
        taxes: false,
        kilometers:0,
        price:0
    }

    async search(field,query){
        console.log(query,await client.service(field).find({query}))
        this.setState({[field]:(await client.service(field).find({query})).data.map((elm,index)=>({
            key:elm.id,
            value:elm.id,
            text:(elm.firstname||elm.company)?(elm.company?elm.company:elm.firstname+" "+elm.lastname):elm.manufacturer+" "+elm.type,
            description:(elm.firstname||elm.company)?elm.id:elm.identification
        }))});
    }
    async componentDidMount(){
        if(this.props.customer_id){
            let customer = await client.service("customers").get(this.props.customer_id)
            this.setState({
                customer: customer,
                customer_id:customer.id,
                customers:[{
                    key: customer.id,
                    value: customer.id,
                    text: customer.firstname?customer.firstname+" "+customer.lastname:customer.company,
                    description: customer.id
                }]
            })
        }
        this.setState({cars:(await client.service("cars").find({query:{sold:false}})).data.map((elm,index)=>({
            key:elm.id,
            value:elm.id,
            text:(elm.firstname||elm.company)?(elm.company?elm.company:elm.firstname+" "+elm.lastname):elm.manufacturer+" "+elm.type,
            description:(elm.firstname||elm.company)?elm.id:elm.identification
        }))});
        this.setState({customers:(await client.service("customers").find()).data.map((elm,index)=>({
            key:elm.id,
            value:elm.id,
            text:(elm.firstname||elm.company)?(elm.company?elm.company:elm.firstname+" "+elm.lastname):elm.manufacturer+" "+elm.type,
            description:(elm.firstname||elm.company)?elm.id:elm.identification
        }))});
    }
    

    async uploadingFile(file,field,loadingField,last){
        let reader  = new FileReader();
        reader.addEventListener("load", () =>{
            client.service('uploads')
                .create({uri: reader.result})
                .then((response)=>{
                    this.setState({ [field]: [...this.state[field],{name:file.name,id:response.id, sort:0}], [loadingField]:last });
                }).catch(()=>{
                    this.setState({ [loadingField]:last} );
                });
        }, false);
        reader.readAsDataURL(file);
    }
    async onDrop(images){
        this.setState({uploadingFile:true})
        let counter = 0
        for (const image of images) {
            counter++
            this.uploadingFile(image, "files","uploadingFile",!(counter >=images.length))
        }
    }
    async handleChangeCustomer (e,{value}) {
        this.setState({
            customer_id: value,
            customer:await client.service("customers").get(value),
            holding:false
        })
    }
    deleteDoc(index,field){
        delete this.state[field][index];
        this.setState({[field]:this.state[field]})
    }
    async handleChangeCar (e,{value}) {
        let car = await client.service("cars").get(value)
        this.setState({
            car_id: value,
            kilometers:car.kilometer,
            price:car.price,
            taxes: car.tax
        })
    }
    handleChange = (e, { name,value }) => this.setState({ [name]:value })
    handleCheckbox = (e,{name}) => this.setState({[name]: !this.state[name]})
    async save(){
        this.setState({error:false,saving:true})
        let user = await client.service("user").find() 
        let {keys,admission_papers,financing,prepayment,files,price,customer_id,car_id,kilometers,taxes,holding,holding_company,holding_company_tax,holding_company_vat,holding_company_address,holding_company_address_extra,holding_company_postcode,holding_company_city,holding_company_country} = this.state
        client.service("contracts").create({keys,admission_papers,financing,prepayment,created_at:moment().format("YYYY-MM-DD HH:mm"),files:JSON.stringify(files),price,customer_id,car_id,kilometers,taxes,holding,holding_company,holding_company_tax,holding_company_vat,holding_company_address,holding_company_address_extra,holding_company_postcode,holding_company_city,holding_company_country,user_id:user.id}).then((result)=>{
            this.props.history.push("/contracts/"+result.id)
            this.setState({saving:false})
        }).catch((e)=>{
            console.log(e)
            this.setState({error:true,saving:false})
        })
    }
    render() {
        return (
            <Modal closeIcon closeOnDimmerClick={false} trigger={this.props.trigger} >
                <Modal.Header>Neuer Kaufvertrag</Modal.Header>
                <Modal.Content >
                    <Form loading={this.state.saving} onSubmit={this.save.bind(this)}>
                        <Form.Group widths="equal">
                            <Form.Select
                                required
                                value={this.state.customer_id}
                                name="customer_id"
                                label="Kunde"
                                placeholder='Kunden auswählen'
                                fluid
                                search={options=>this.state.customers}
                                noResultsMessage='Es wurde keine Kunden gefunden.' 
                                onSearchChange={(e,{searchQuery})=>this.search("customers",{
                                    $or:[
                                        {firstname: {$like:"%"+searchQuery+"%"}},
                                        {company: {$like:"%"+searchQuery+"%"}},
                                        {lastname: {$like:"%"+searchQuery+"%"}},
                                        {id: {$like:"%"+searchQuery+"%"}},
                                    ]
                                })}
                                options={this.state.customers}  
                                onChange={this.handleChangeCustomer.bind(this)}
                                />
                            {this.state.customer.type=="private" &&<Form.Checkbox name="holding" checked={this.state.holding} onChange={this.handleCheckbox.bind(this)} style={{marginTop:30}} label="als Gewerbe in Vertretung" toggle />}
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Select
                                required
                                value={this.state.car_id}
                                name="car_id"
                                label="Fahrzeuge"
                                search={options=>this.state.cars}
                                placeholder='Fahrzeug auswählen'
                                fluid
                                noResultsMessage='Es wurde keine Autos gefunden.' 
                                onSearchChange={(e,{searchQuery})=>this.search("cars",{
                                    $or:[
                                        {manufacturer: {$like:"%"+searchQuery+"%"},},
                                        {type: {$like:"%"+searchQuery+"%"},},
                                        {identification: {$like:"%"+searchQuery+"%"},},
                                        {id: {$like:"%"+searchQuery+"%"},}
                                    ]
                                })}
                                options={this.state.cars} 
                                onChange={this.handleChangeCar.bind(this)}
                            />
                            <Form.Field >
                                <label>Kilometerstand<up>*</up></label>
                                <Input type="number" required value={this.state.kilometers} onChange={this.handleChange.bind(this)} name="kilometers" label="KM" labelPosition="right" />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field >
                                <label>Preis<up>*</up></label>
                                <Input type="number" required value={this.state.price} onChange={this.handleChange.bind(this)} name="price" label="€" labelPosition="right" />
                            </Form.Field>
                            <Form.Field >
                                <label>Anzahlung</label>
                                <Input type="number" value={this.state.prepayment} onChange={this.handleChange.bind(this)} name="prepayment" label="€" labelPosition="right" />
                            </Form.Field>
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Form.Input label="Schlüssel" required value={this.state.keys} onChange={this.handleChange.bind(this)} name="keys"  />

                            <Form.Field >
                                <label>Zulassungsbescheinigung erhalten</label>
                                <Checkbox checked={this.state.admission_papers} onChange={()=>this.setState({admission_papers:!this.state.admission_papers})} name="admission_papers" style={{marginTop:8}} toggle required />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field >
                            <label>Darlehnsunterlagen erhalten</label>
                            <Checkbox checked={this.state.financing} onChange={()=>this.setState({financing:!this.state.financing})} name="financing" style={{marginTop:8}} toggle />
                        </Form.Field>
                        {this.state.holding && <Segment.Group>
                            <Header attached="top">Vertretendes Unternehmen</Header>
                            <Segment attached="bottom" color="green">
                                <Form.Input onChange={this.handleChange.bind(this)} name="holding_company" required label="Unternehmen" />
                                <Form.Group widths="equal">
                                    <Form.Input onChange={this.handleChange.bind(this)} name="holding_company_tax" required label="Steuernummer" />
                                    <Form.Input onChange={this.handleChange.bind(this)} name="holding_company_vat" label="USt-ID" />
                                </Form.Group>
                                <Form.Input onChange={this.handleChange.bind(this)} name="holding_company_address" required label="Straße, Hausnummer" />
                                <Form.Input placeholder="Adresszusatz" onChange={this.handleChange.bind(this)} name="holding_company_address_extra" />
                                <Form.Group widths="equal">
                                    <Form.Input onChange={this.handleChange.bind(this)} name="holding_company_postcode" required width="5" label="PLZ" />
                                    <Form.Input onChange={this.handleChange.bind(this)} name="holding_company_city" required label="Stadt" />
                                </Form.Group>
                                <Form.Select onChange={this.handleChange.bind(this)} name="holding_company_country" required label="Land" options={[{flag:"de",key:"de",value:"DE",text:"Deutschland"}]} value="DE" />
                            </Segment>
                        </Segment.Group>}
                        <Segment.Group>
                            <Header attached="top">Dokumente</Header>

                                <Segment attached="bottom">
                                    <Dropzone maxSize={20000000} onDrop={this.onDrop.bind(this)} >
                                    {({getRootProps, getInputProps, isDragActive}) => (
                                        <Segment placeholder={!isDragActive} style={{minHeight:80}} {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {this.state.uploadingFile ? "Wird gerade hochgeladen..." :"Ziehe die Datei hierhin, um sie hochzuladen."}
                                            {!this.state.uploadingImage && <small className="helper">Maximale Datei-Größe von 10MB</small>}
                                        </Segment>
                                    )}
                                    </Dropzone>
                                    {this.state.files.length > 0 &&<List>
                                        {this.state.files.map((file,index) => (
                                            <List.Item >
                                                <List.Content floated='right'>
                                                    <Button onClick={()=>this.deleteDoc(index,"files")} as="a" negative icon><Icon name="trash" /></Button>
                                                </List.Content>
                                                <List.Content>{file.name}</List.Content>
                                            </List.Item>
                                        ))}
                                    </List>}
                                </Segment>
                        </Segment.Group>
                        {this.state.error && <Message negative >Es ist ein Fehler beim speichern aufgetreten. Bitte überprüfen Sie alle Felder oder kontaktieren Sie den Support.</Message>}
                        <Form.Button fluid primary  >Speichern</Form.Button>
                    </Form>
                </Modal.Content>
            </Modal >
        )
    }
}

export default withRouter(ContractsNew)