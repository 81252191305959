import React, { Component } from "react";
import { Segment, Input, Table, Pagination, Button, Modal, Divider } from "semantic-ui-react";
import { withRouter} from "react-router-dom";
import Currency from "react-currency-formatter";
import chance from "chance";
import client from "../../shared/feathers";
import Order from "../Order/";
let IChance = new chance()

let Groups = {administrator:"Administrator",manager:"Manager",salesman:"Verkäufer",accountant:"Buchhalter",mechanic:"Mechaniker",carcreator:"Fahrzeugerfasser"}
class SellersList extends Component {
    state={
        query:{},
        data:{
            total:1,
            limit:1,
            data:[]
        },
        dataClosed:{
            total:1,
            limit:1,
            data:[]
        },
        quotaWarning:false,
        company:{maxUsers:1},
        page: 1,
        loading:true
    }
    async componentDidMount(){
        document.title = 'Mitarbeiter';
        this.setState({company:(await client.service("companies").find()).data[0]})
        this.update()
    }

    changePage(event,data){
        this.setState({loading:true,page:data.activePage},()=>this.update())
    }
    update(){
        this.setState({loading:true})
        if(this.reactive){this.reactive.unsubscribe()}
        if(this.reactiveClosed){this.reactiveClosed.unsubscribe()}
        this.reactive = client.service("users").watch().find({query: {$limit:6,deleted:false,active:true,$or:[...Object.values(this.state.query)],$skip:(this.state.page * this.state.data.limit)-this.state.data.limit,...this.props.query }}).subscribe(async data=>this.setState({data,loading:false}))
        this.reactiveClosed = client.service("users").watch().find({query: {$limit:6,deleted:false,active:false,$or:[...Object.values(this.state.query)],$skip:(this.state.page * this.state.data.limit)-this.state.data.limit,...this.props.query }}).subscribe(async dataClosed=>this.setState({dataClosed,loading:false}))
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
        if(this.reactiveClosed){this.reactiveClosed.unsubscribe()}
    }
    changeFilter(name,value){
        this.setState({query:{...this.state.query,[name]:{[name]:{$like: "%"+value+"%" }}}}, ()=>this.update())
    }
    activate(id){
        if(this.state.data.data.length < this.state.company.maxUsers){
            client.service("users").patch(id,{active:true}).then(()=>this.update())
        }else{
            this.setState({quotaWarning:true})
        }
    }
    render() {
        return (
            <div>
                <Modal size={"mini"} open={this.state.quotaWarning} onClose={()=>this.setState({quotaWarning:false})}>
                    <Modal.Header>Limit ist ausgeschöpft!</Modal.Header>
                    <Modal.Content>
                        <p>Wechseln Sie jetzt zu einen größeren Vertragsmodel um mehr Mitarbeiter zu Aktivieren</p>
                        <div style={{textAlign:"center"}}><Order  trigger={<Button positive>Upgraden</Button>} /></div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative content='Jetzt nicht' onClick={()=>this.setState({quotaWarning:false})} />
                    </Modal.Actions>
                </Modal>
                <Segment.Group style={{ padding: 0 }}>
                    <Table selectable attached='top' celled size="small">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>ID</Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>E-Mail</Table.HeaderCell>
                                <Table.HeaderCell>Telefon</Table.HeaderCell>
                                <Table.HeaderCell>Rolle</Table.HeaderCell>
                                <Table.HeaderCell>Umsatz</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell collapsing style={{ padding: 5, backgroundColor: '#fff' }}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="id" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}><Input onChange={(e,{name,value})=>{this.changeFilter("lastname",value);this.changeFilter(name,value)}} name="firstname" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="email" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="phone" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="permissions" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="price" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.data.data.map((entry, id) =>
                                <Table.Row key={entry.id} style={{backgroundColor:entry.deleted==1?"red":undefined, cursor: "pointer" }} onClick={() => this.props.history.push("/sellers/"+entry.id)}>
                                    <Table.Cell>{entry.id}</Table.Cell>
                                    <Table.Cell>{entry.firstname +" "+entry.lastname}</Table.Cell>
                                    <Table.Cell>{entry.email}</Table.Cell>
                                    <Table.Cell>{entry.phone}</Table.Cell>
                                    <Table.Cell>{Groups[entry.permissions]}</Table.Cell>
                                    <Table.Cell><Currency quantity={entry.sales} currency="EUR" /></Table.Cell>
                                </Table.Row>
                            )}
                            {(new Array((this.state.company.maxUsers-this.state.data.data.length)>0?this.state.company.maxUsers-this.state.data.data.length:0).fill(undefined).map((val, idx) => idx)).map((entry, id) => 
                                <Table.Row key={id} style={{backgroundColor:"#eee", cursor: "pointer" }} >
                                    <Table.Cell colSpan="6"><i>Freier Platz. Füge jetzt einen Mitarbeiter hinzu.</i></Table.Cell>
                                </Table.Row>
                            ) }
                        </Table.Body>
                    </Table>
                    <Pagination 
                        onPageChange={this.changePage.bind(this)}
                        attached="bottom" 
                        boundaryRange={2} 
                        siblingRange={2} 
                        fluid 
                        defaultActivePage={this.state.page} 
                        totalPages={Math.ceil(this.state.data.total / this.state.data.limit)} />
                </Segment.Group>

                {!this.props.noUpgrade && <Divider horizontal>Nicht genug freie Plätze?</Divider>}
                {!this.props.noUpgrade && <div style={{textAlign:"center"}}><Order trigger={<Button primary>Paket upgraden</Button>} /></div>}
                <Segment.Group style={{ padding: 0 }}>
                    <Table  attached='top' celled size="small">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan="8">Deaktivierte Mitarbeiter</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.dataClosed.data.map((entry, id) =>
                                <Table.Row key={entry.id} style={{backgroundColor:"#eee"}} >
                                    <Table.Cell>{entry.id}</Table.Cell>
                                    <Table.Cell>{entry.firstname +" "+entry.lastname}</Table.Cell>
                                    <Table.Cell>{entry.email}</Table.Cell>
                                    <Table.Cell>{entry.phone}</Table.Cell>
                                    <Table.Cell>{Groups[entry.permissions]}</Table.Cell>
                                    <Table.Cell><Currency quantity={entry.sales} currency="EUR" /></Table.Cell>
                                    <Table.Cell collapsing><Button size="mini" style={{backgroundColor:"#fff"}} onClick={()=>this.activate(entry.id)}>Aktivieren</Button></Table.Cell>
                                    <Table.Cell collapsing><Button size="mini" negative onClick={()=>{client.service("users").patch(entry.id,{phone:"deleted_"+entry.phone,email:entry.email+"_deleted",deleted:true,isVerified:false});this.update()}}>Löschen</Button></Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                    <Pagination 
                        onPageChange={this.changePage.bind(this)}
                        attached="bottom" 
                        boundaryRange={2} 
                        siblingRange={2} 
                        fluid 
                        defaultActivePage={this.state.page} 
                        totalPages={Math.ceil(this.state.dataClosed.total / this.state.dataClosed.limit)} />
                </Segment.Group>
            </div>
        )
    }
}

export default withRouter(SellersList)