import React, { Component } from "react";
import { Segment, Menu, Icon, Loader } from "semantic-ui-react";
import chance from "chance";
import SellersList from './list'
import SellersNew from './new'
import client from "../../shared/feathers";

let IChance = new chance()
class Sellers extends Component {
    state={
        loading:true,
        user:{company_id:0}
    }
    async componentDidMount(){
        this.setState({user:await client.service("user").find(),loading:false})
    }
    render() {
        if(this.state.loading){
            return <Loader active />
        }
        return (
            <Segment basic >
                {!this.props.hideMenu && <Menu>
                    <Menu.Item><b>Mitarbeiter</b></Menu.Item>
                    <Menu.Menu position="right">
                        <SellersNew trigger={<Menu.Item  ><Icon name="plus square" /> Hinzufügen</Menu.Item>} />
                    </Menu.Menu>
                </Menu>}
                <SellersList  query={{company_id:this.state.user.company_id}} />
            </Segment>
        )
    }
}

export default Sellers