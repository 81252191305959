import React,{ Component } from "react";
import { Image, Form, Segment, Header, Input, Accordion,Button, Checkbox, Message, List, Label } from "semantic-ui-react";
import {Fuel, WheelFormula, EmissionClass, EmissionSticker, ExteriorColor, InteriorColor, InteriorType, Airbag, Climatisation, HydraulicInstallation, SpeedControl, UsedCarSeal, Category} from './sync/'
import MaskedInput from 'react-text-mask'
import client from "../../shared/feathers";
class AdSettings extends Component{
    state={
        base:{},
        financeing:{},
        price:{},
        extras:{},
        saving:false,
        loading_mobile:false
    }

    async save(){
        this.setState({saving:true})
        await client.service("cars").patch(this.props.car.id,{mobile_adSettings:JSON.stringify({base:this.state.base,financeing:this.state.financeing,price:this.state.price,extras:this.state.extras})})
        if(this.props.car.mobile==1){
            this.uploadMobile()
        }else{
            deleteMobile()
        }
        this.setState({saving:false})
    }

    async uploadMobile(){
        this.setState({loading_mobile:true})
        await this.save()
        client.service("mobile").patch(this.props.car.id,{}).then(()=>{
            this.setState({loading_mobile:false})
        }).catch((e)=>{
            this.setState({loading_mobile:false})
        })
    }
    async deleteMobile() {
        this.setState({loading_mobile:true})
        client.service("mobile").remove(this.props.car.id,{}).then(()=>{
            this.setState({loading_mobile:false})
        }).catch((e)=>{
            this.setState({loading_mobile:false})
        })
    }
    componentDidMount(){
        this.setState(this.props.car.mobile_adSettings?this.props.car.mobile_adSettings:{base:{}, financeing:{}, price:{},extras:{}})
    }

    handleChange = (e, { name,value,checked,type }) =>{
        if(type=="checkbox"){
            this.state.base={...this.state.base, [name]:checked}
            this.setState(this.state.base)
        }else{
            this.state.base={...this.state.base, [name]:value}
            this.setState({ base:{...this.state.base, [name]:value} })
        }
        if(this.props.onChange){this.props.onChange({base:this.state.base,financeing:this.state.financeing,price:this.state.price,extras:this.state.extras})}
    }

    handleChangePrice = (e, { name,value,checked,type }) =>{
        if(type=="checkbox"){
            this.state.price={...this.state.price, [name]:checked}
            this.setState({ price:{...this.state.price, [name]:checked} })
        }else{
            this.state.price={...this.state.price, [name]:value}
            this.setState({ price:{...this.state.price, [name]:value} })
        }
        if(this.props.onChange){this.props.onChange({base:this.state.base,financeing:this.state.financeing,price:this.state.price,extras:this.state.extras})}
    }

    handleChangeFinanceing = (e, { name,value,checked,type }) =>{
        if(type=="checkbox"){
            this.state.financeing={...this.state.financeing, [name]:checked}
            this.setState({ financeing:{...this.state.financeing, [name]:checked} })
        }else{
            this.state.financeing={...this.state.financeing, [name]:value}
            this.setState({ financeing:{...this.state.financeing, [name]:value} })
        }
        if(this.props.onChange){this.props.onChange({base:this.state.base,financeing:this.state.financeing,price:this.state.price,extras:this.state.extras})}
    }
    handleChangeExtras = (e, { name,value,checked,type }) =>{
        if(type=="checkbox"){
            this.state.extras={...this.state.extras, [name]:checked}
            this.setState({ extras:{...this.state.extras, [name]:checked} })
        }else{
            this.state.extras={...this.state.extras, [name]:value}
            this.setState({ extras:{...this.state.extras, [name]:value} })
        }
        if(this.props.onChange){this.props.onChange({base:this.state.base,financeing:this.state.financeing,price:this.state.price,extras:this.state.extras})}
    }

    render(){
        let panels=[
            {
                key:"main",
                title:"Allgemeine Informationen",
                content: {content:(<div>
                    <Form.Group widths="equal">
                        <Form.Select clearable fluid label="Zustand" options={[{key:"USED",value:"USED",text:"Gebrauchtfahrzeug"},{key:"NEW",value:"NEW",text:"Neufahrzeug"}]} value={this.state.base.condition} name="condition" onChange={this.handleChange.bind(this)} />
                    </Form.Group>
                    <Form.TextArea value={this.state.base.description} name="description" onChange={this.handleChange.bind(this)} label="Beschreibung" />
                    <Form.Group widths="equal">
                        <Form.Input label="Lieferdatum" >
                            <MaskedInput
                                onChange={(e)=>this.handleChange(e,{name:"deliveryDate",value:e.target.value})} 
                                pattern="[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])"
                                placeholder="YYYY-MM-DD" 
                                value={this.state.base.deliveryDate}
                                mask={[/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/,"-",/\d/,/\d/]}  
                                guide={true}   />
                        </Form.Input>
                        <Form.Select value={this.state.base.deliveryPeriod} name="deliveryPeriod" onChange={this.handleChange.bind(this)} clearable type="number" label="Lieferbar ab (Nur für Neuwagen)" options={[{key:"1",value:"1",text:"1 Tag"},{key:"2",value:"2",text:"2 Tage"},{key:"3",value:"3",text:"3 Tage"},{key:"4",value:"4",text:"4 Tage"},{key:"5",value:"5",text:"5 Tage"},{key:"6",value:"6",text:"6 Tage"},{key:"7",value:"7",text:"1 Woche"},{key:"14",value:"14",text:"2 Wochen"},{key:"42",value:"42",text:"1 Monat"},{key:"60",value:"60",text:"2 Monate"},{key:"90",value:"90",text:"3 Monate"},{key:"120",value:"120",text:"4 Monate"},{key:"150",value:"150",text:"5 Monate"},{key:"180",value:"180",text:"6 Monate"},{key:"270",value:"270",text:"9 Monate"},{key:"360",value:"360",text:"1 Jahr"}]} />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <UsedCarSeal value={this.state.base.usedCarSeal} name="usedCarSeal" onChange={this.handleChange.bind(this)} />
                        <Form.Select clearable fluid label="Verwendungsart" options={[{key:"PRE_REGISTRATION",value:"PRE_REGISTRATION",text:"Tageszulassung"},{key:"EMPLOYEES_CAR",value:"EMPLOYEES_CAR",text:"Jahreswagen"},{key:"CLASSIC",value:"CLASSIC",text:"Oldtimer"},{key:"DEMONSTRATION",value:"DEMONSTRATION",text:"Vorführfahrzeug"}]} value={this.state.base.usageType} name="usageType" onChange={this.handleChange.bind(this)} />
                    </Form.Group>
                    <Form.Checkbox checked={this.state.base.dealerHomepage} value={this.state.base.dealerHomepage} name="dealerHomepage" onChange={this.handleChange.bind(this)}  label="Als 'Unsere Empfehlung' makieren" />
                    <Form.Checkbox checked={this.state.base.export} value={this.state.base.export} name="export" onChange={this.handleChange.bind(this)}  label="Verkauf nur für Export" />
                    <Form.Checkbox checked={this.state.base.warranty} value={this.state.base.warranty} name="warranty" onChange={this.handleChange.bind(this)}  label="Erweiterte Garantie" />
                    <Form.Checkbox checked={this.state.base.rentingPossible} value={this.state.base.rentingPossible} name="rentingPossible" onChange={this.handleChange.bind(this)}  label="Zur Vermietung anbieten" />
                </div>)}
            },
            {
                key:"price",
                title: "Preis",
                content: {content:(<div>
                    <Message>Preise werden mit "." als Dezimal stelle angegeben.</Message>
                    <Form.Input value={this.state.price.dealerPriceGross} name="dealerPriceGross" onChange={this.handleChangePrice.bind(this)} label="Verkaufspreis für andere Händler (mit Steuern)" type="number" />
                    <Form.Select value={this.state.price.type} name="type" onChange={this.handleChangePrice.bind(this)} clearable label="Preis-Typ" options={[{key:"FIXED",value:"FIXED",text:"Festpreis"},{key:"ON_REQUEST",value:"ON_REQUEST",text:"Auf Anfrage"}]} />
                    
                </div>)}
            },
            {
                key:"financing",
                title: "Finanzierung",
                content: {content:(<div>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Nettokreditbetrag</label>
                            <Input value={this.state.financeing.netLoanAmount} name="netLoanAmount" onChange={this.handleChangeFinanceing.bind(this)} label="€" labelPosition="right" type="number" />
                        </Form.Field>
                        <Form.Field>
                            <label>Bruttokreditbetrag</label>
                            <Input value={this.state.financeing.grossLoanAmount} name="grossLoanAmount" onChange={this.handleChangeFinanceing.bind(this)} label="€" labelPosition="right" type="number" />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Effektiver Jahreszins</label>
                            <Input value={this.state.financeing.annualPercentageRate} name="annualPercentageRate" onChange={this.handleChangeFinanceing.bind(this)} label="%" labelPosition="right" type="number" />
                        </Form.Field>
                        <Form.Field>
                            <label>Sollzinssatz</label>
                            <Input value={this.state.financeing.nominalInterestRate} name="nominalInterestRate" onChange={this.handleChangeFinanceing.bind(this)} label="%" labelPosition="right" type="number" />
                        </Form.Field>
                    </Form.Group>
                    <Form.Select value={this.state.financeing.typeOfNominalInterestRate} name="typeOfNominalInterestRate" onChange={this.handleChangeFinanceing.bind(this)} clearable label="Art des Sollzinssatzes" options={[{key:"BOUND",value:"BOUND",text:"Gebunden"},{key:"VARIABLE",value:"VARIABLE",text:"Variabel"},{key:"COMBINED",value:"COMBINED",text:"Kombiniert"}]} />
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Anzahlung</label>
                            <Input value={this.state.financeing.firstInstallment} name="firstInstallment" onChange={this.handleChangeFinanceing.bind(this)} label="€" labelPosition="right" type="number" />
                        </Form.Field>
                        <Form.Field>
                            <label>Monatliche Rate</label>
                            <Input value={this.state.financeing.monthlyInstallment} name="monthlyInstallment" onChange={this.handleChangeFinanceing.bind(this)} label="€" labelPosition="right" type="number" />
                        </Form.Field>
                    </Form.Group>
                    <Form.Field>
                        <label>Schlussrate</label>
                        <Input value={this.state.financeing.finalInstallment} name="finalInstallment" onChange={this.handleChangeFinanceing.bind(this)} label="€" labelPosition="right" type="number" />
                    </Form.Field>
                    <Form.Select value={this.state.financeing.paybackPeriod} name="paybackPeriod" onChange={this.handleChangeFinanceing.bind(this)} clearable label="Laufzeit in Monaten" options={[{key:"MONTHS_12",value:"MONTHS_12",text:"12"},{key:"MONTHS_24",value:"MONTHS_24",text:"24"},{key:"MONTHS_36",value:"MONTHS_36",text:"36"},{key:"MONTHS_48",value:"MONTHS_48",text:"48"},{key:"MONTHS_60",value:"MONTHS_60",text:"60"},{key:"MONTHS_72",value:"MONTHS_72",text:"72"},{key:"MONTHS_84",value:"MONTHS_84",text:"84"},{key:"MONTHS_96",value:"MONTHS_96",text:"96"}]} />
                    
                    <Form.Group widths="equal">
                        <Form.Input value={this.state.financeing.closingCosts} name="closingCosts" onChange={this.handleChangeFinanceing.bind(this)} label="Abschlussgebühren in €" type="number"/>
                        <Form.Input value={this.state.financeing.paymentProtectionInsurance} name="paymentProtectionInsurance" onChange={this.handleChangeFinanceing.bind(this)} label="Versicherungsschutz in €" type="number"/>
                    </Form.Group>
                    <Form.Input value={this.state.financeing.bank} name="bank" onChange={this.handleChangeFinanceing.bind(this)} label="Bank"/>
                    <Form.TextArea value={this.state.financeing.conditions} name="conditions" onChange={this.handleChangeFinanceing.bind(this)} label="Konditionen" />
                </div>)}
            },
            {
                key:"engine",
                title:"Fahrzeug / Motor",
                content: {content:(<div>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Hubraum</label>
                            <Input name="cubicCapacity" onChange={this.handleChange.bind(this)} label='cm3' labelPosition="right" type="number"/>
                        </Form.Field>
                        <Form.Field>
                            <label>Leistung</label>
                            <Input name="power" onChange={this.handleChange.bind(this)} label='KW' labelPosition="right" type="number"/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Select clearable fluid label="Getriebe" options={[{key:"MANUAL_GEAR",value:"MANUAL_GEAR",text:"Schaltgetriebe"},{key:"SEMIAUTOMATIC_GEAR",value:"SEMIAUTOMATIC_GEAR",text:"Halbautomatik"},{key:"AUTOMATIC_GEAR",value:"AUTOMATIC_GEAR",text:"Automatik"}]} value={this.state.base.gearbox} name="gearbox" onChange={this.handleChange.bind(this)} />
                        <WheelFormula value={this.state.base.wheelFormula} onChange={this.handleChange.bind(this)} />
                    </Form.Group>
                    {this.props.car.classification != "Car" && <Form.Input label="Axen" name="axles" onChange={this.handleChange.bind(this)}  type="number"/>}
                    
                    <Form.Group widths="equal" >
                        <Form.Field>
                            <label>Kraftstoff-Unterstützung</label>
                            <Checkbox style={{marginTop:10}} value={this.state.base.e10Enabled} name="e10Enabled" onChange={this.handleChange.bind(this)} toggle label="E10" /><br/>
                            <Checkbox style={{marginTop:10}} value={this.state.base.biodieselConversion} name="biodieselConversion" onChange={this.handleChange.bind(this)} toggle label="Biodiesel umwandlung" />
                            <Checkbox style={{marginTop:10}} value={this.state.base.biodieselSuitable} name="biodieselSuitable" onChange={this.handleChange.bind(this)} toggle label="Biodiesel direkt" /><br/>
                            <Checkbox style={{marginTop:10}} value={this.state.base.vegetableoilfuelConversion} name="vegetableoilfuelConversion" onChange={this.handleChange.bind(this)} toggle label="Planzenöl umwandlung" />
                            <Checkbox style={{marginTop:10}} value={this.state.base.vegetableoilfuelSuitable} name="vegetableoilfuelSuitable" onChange={this.handleChange.bind(this)} toggle label="Planzenöl direkt" />
                        </Form.Field>
                        <Form.Field>
                            <label>Andere Angaben</label>
                            <Checkbox style={{marginTop:7}} value={this.state.base.hybridPlugin} name="hybridPlugin" onChange={this.handleChange.bind(this)} toggle label="Hybrid" /><br/>
                            <Checkbox style={{marginTop:10}} value={this.state.base.performanceHandlingSystem} name="performanceHandlingSystem" onChange={this.handleChange.bind(this)} toggle label="Sportfahrwerk" />
                            <Checkbox style={{marginTop:10}} value={this.state.base.hybridPlugin} name="hybridPlugin" onChange={this.handleChange.bind(this)} toggle label="Start-Stop System" />
                            <Checkbox style={{marginTop:10}} value={this.state.base.startStopSystem} name="startStopSystem" onChange={this.handleChange.bind(this)} toggle label="Abgaskatalysator" /><br/>
                            <Checkbox style={{marginTop:10}} value={this.state.base.particulateFilterDiesel} name="particulateFilterDiesel" onChange={this.handleChange.bind(this)} toggle label="Partikelfilter" />
                        </Form.Field>
                    </Form.Group>
                </div>)}
            },
            {
                key:"usage",
                title:"Verbrauch / Treibstoff",
                content: {content:(<div>
                    <Form.Group widths="equal">
                        <Fuel value={this.state.base.fuel} onChange={this.handleChange.bind(this)} />
                        <Form.Select clearable label="Energy-Effizienz-Klasse" value={this.state.base.energyEfficiencyClass} name="energyEfficiencyClass" onChange={this.handleChange.bind(this)} options={[{key:"APLUS",value:"A+",text:"A+"},{key:"A",value:"A",text:"A"},{key:"B",value:"B",text:"B"},{key:"C",value:"C",text:"C"},{key:"D",value:"D",text:"D"},{key:"E",value:"E",text:"E"},{key:"F",value:"F",text:"F"},{key:"G",value:"G",text:"G"}]} />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <EmissionClass value={this.state.base.emissionClass} onChange={this.handleChange.bind(this)} />
                        <EmissionSticker value={this.state.base.emissionSticker} onChange={this.handleChange.bind(this)} />
                    </Form.Group>
                    <Form.Input value={this.state.base.co2} name="co2" onChange={this.handleChange.bind(this)} label="CO2 Verbrauch pro Kilometer" type="number" />
                    <Form.Field>
                        <label>Verbrauch Innerorts</label>
                        <Input value={this.state.base.consumptionInner} name="consumptionInner" onChange={this.handleChange.bind(this)} type="number" label="L/cm3/KW pro 100km" labelPosition="right" />
                    </Form.Field>
                    <Form.Field>
                        <label>Verbrauch Außerorts</label>
                        <Input value={this.state.base.consumptionOuter} name="consumptionOuter" onChange={this.handleChange.bind(this)} type="number" label="L/cm3/KW pro 100km" labelPosition="right" />
                    </Form.Field>
                    <Form.Field>
                        <label>Verbrauch zusammen</label>
                        <Input value={this.state.base.consumptionCombined} name="consumptionCombined" onChange={this.handleChange.bind(this)} type="number" label="L/cm3/KW pro 100km" labelPosition="right" />
                    </Form.Field>
                    <Form.Select clearable value={this.state.base.envkvPetrolType} name="envkvPetrolType" onChange={this.handleChange.bind(this)} options={[{key:"SUPER_PLUS",value:"SUPER_PLUS",text:"Super Plus"},{key:"SUPER",value:"SUPER",text:"Super"},{key:"NORMAL",value:"NORMAL",text:"Normal"}]} label="Test-Kraftstoff" />
                </div>)}
            },
            {
                key:"Exterior",
                title:"Karosserie / Innenraum",
                content: {content:(<div>
                    <Form.Checkbox checked={this.state.base.metallic} value={this.state.base.metallic} name="metallic" onChange={this.handleChange.bind(this)} toggle label="Metallic" style={{marginTop:7}} />
                    <Form.Group widths="equal">
                        <ExteriorColor value={this.state.base.exteriorColor} onChange={this.handleChange.bind(this)} />
                        <Form.Input value={this.state.base.manufacturerColorName} name="manufacturerColorName" onChange={this.handleChange.bind(this)} label="Sonderfarbe" />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <InteriorColor value={this.state.base.interiorColor} onChange={this.handleChange.bind(this)}  />
                        <InteriorType value={this.state.base.interiorType} onChange={this.handleChange.bind(this)}  />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Select value={this.state.base.doors} name="doors" onChange={this.handleChange.bind(this)} label="Türen" clearable options={[{key:"23",value:"TWO_OR_THREE",text:"2/3"},{key:"45",value:"FOUR_OR_FIVE",text:"4/5"},{key:"67",value:"SIX_OR_SEVEN",text:"6/7"}]} />
                        <Form.Input value={this.state.base.seats} name="seats" onChange={this.handleChange.bind(this)} label="Sitze" type="number" />
                    </Form.Group>
                </div>)}
            },
            {
                key:"facts",
                title:"Fakten",
                content: {content:(<div>
                    <Form.Checkbox checked={this.state.base.newHuAu} value={this.state.base.newHuAu} name="newHuAu" onChange={this.handleChange.bind(this)} label="Neue HU/AU für den Käufer" />
                    <Form.Group widths="equal">
                        <Form.Input label="Generalinspection" >
                            <MaskedInput
                                value={this.state.base.generalInspection_year}
                                onChange={({target})=>this.setState({generalInspection_year:target.value})}
                                pattern="[0-9]{4}" placeholder="Jahr" mask={[/\d/,/\d/,/\d/,/\d/]} guide={true}  />
                        </Form.Input>
                        <Form.Input label="&nbsp;" >
                            <MaskedInput
                                value={this.state.base.generalInspection_month}
                                onChange={({target})=>this.setState({generalInspection_month:target.value})}
                                pattern="[0-9]{2}" placeholder="Monat" mask={[/\d/,/\d/]} guide={true}  />
                        </Form.Input>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input label="Abgasuntersuchung" >
                            <MaskedInput
                                value={this.state.base.exhaustInspection_year}
                                onChange={({target})=>this.setState({exhaustInspection_year:target.value})}
                                pattern="[0-9]{4}" placeholder="Jahr" mask={[/\d/,/\d/,/\d/,/\d/]} guide={true}  />
                        </Form.Input>
                        <Form.Input label="&nbsp;" >
                            <MaskedInput
                                value={this.state.base.exhaustInspection_month}
                                onChange={({target})=>this.setState({exhaustInspection_month:target.value})}
                                pattern="[0-9]{2}" placeholder="Monat" mask={[/\d/,/\d/]} guide={true}  />
                        </Form.Input>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input label="Baujahr" >
                            <MaskedInput
                                value={this.state.base.constructionYear}
                                onChange={({target})=>this.setState({constructionYear:target.value})}
                                pattern="[0-9]{4}" placeholder="Jahr" mask={[/\d/,/\d/,/\d/,/\d/]} guide={true}  />
                        </Form.Input>
                        <Form.Input value={this.state.base.numberOfPreviousOwners} name="numberOfPreviousOwners" onChange={this.handleChange.bind(this)} type="number" label="Vorbesitzer"  />
                    </Form.Group>
                    <Form.Field>
                        <label>Weitere Angaben</label>
                    </Form.Field>
                        <Form.Checkbox checked={this.state.base.fullServiceHistory} value={this.state.base.fullServiceHistory} name="fullServiceHistory" onChange={this.handleChange.bind(this)} label="Checkheft gepflegt" />
                        <Form.Checkbox checked={this.state.base.damageUnrepaired} value={this.state.base.damageUnrepaired} name="damageUnrepaired" onChange={this.handleChange.bind(this)} label="Beschädigt" />
                        <Form.Checkbox checked={this.state.base.accidentDamaged} value={this.state.base.accidentDamaged} name="accidentDamaged" onChange={this.handleChange.bind(this)} label="Unfallfahrzeug" />
                        <Form.Checkbox checked={this.state.base.roadworthy} value={this.state.base.roadworthy} name="roadworthy" onChange={this.handleChange.bind(this)} label="Fahrtauglich" />
                        <Form.Checkbox checked={this.state.base.nonSmokerVehicle} value={this.state.base.nonSmokerVehicle} name="nonSmokerVehicle" onChange={this.handleChange.bind(this)} label="Nichtraucher Auto" />
                        <Form.Checkbox checked={this.state.base.taxi} value={this.state.base.taxi} name="taxi" onChange={this.handleChange.bind(this)} label="Taxi" />
                </div>)}
            },
            {
                key:"Vehicle",
                title:"Fahrzeugdetails",
                content: {content:(<div>
                    <Airbag value={this.state.base.airbag} name="airbag" onChange={this.handleChange.bind(this)}  />
                    <Climatisation value={this.state.base.climatisation} name="climatisation" onChange={this.handleChange.bind(this)}  />
                    <Form.Checkbox checked={this.state.base.abs} value={this.state.base.abs} name="abs" onChange={this.handleChange.bind(this)} label="ABS" />
                    <Form.Checkbox checked={this.state.base.airSuspension} value={this.state.base.airSuspension} name="airSuspension" onChange={this.handleChange.bind(this)} label="Luftfederung" />
                    <Form.Checkbox checked={this.state.base.alloyWheels} value={this.state.base.alloyWheels} name="alloyWheels" onChange={this.handleChange.bind(this)} label="Leichtmetallräder" />
                    <Form.Checkbox checked={this.state.base.automaticRainSensor} value={this.state.base.automaticRainSensor} name="automaticRainSensor" onChange={this.handleChange.bind(this)} label="Regensensor" />
                    <Form.Checkbox checked={this.state.base.auxiliaryHeating} value={this.state.base.auxiliaryHeating} name="auxiliaryHeating" onChange={this.handleChange.bind(this)} label="Standheizung" />
                    <Form.Checkbox checked={this.state.base.awning} value={this.state.base.awning} name="awning" onChange={this.handleChange.bind(this)} label="Markiese (Wohnwagen)" />
                    <Form.Checkbox checked={this.state.base.bed} value={this.state.base.bed} name="bed" onChange={this.handleChange.bind(this)} label="Bett" />
                    <Form.Checkbox checked={this.state.base.bluetooth} value={this.state.base.bluetooth} name="bluetooth" onChange={this.handleChange.bind(this)} label="Bluetooth" />
                    <Form.Checkbox checked={this.state.base.box} value={this.state.base.box} name="box" onChange={this.handleChange.bind(this)} label="Stauräume" />
                    <Form.Checkbox checked={this.state.base.bss} value={this.state.base.bss} name="bss" onChange={this.handleChange.bind(this)} label="Hubgerüstdämpfung" />
                    <Form.Checkbox checked={this.state.base.bunkBed} value={this.state.base.bunkBed} name="bunkBed" onChange={this.handleChange.bind(this)} label="Etagenbett" />
                    <Form.Checkbox checked={this.state.base.cdMultichanger} value={this.state.base.cdMultichanger} name="cdMultichanger" onChange={this.handleChange.bind(this)} label="CD-Wechsler" />
                    <Form.Checkbox checked={this.state.base.cdPlayer} value={this.state.base.cdPlayer} name="cdPlayer" onChange={this.handleChange.bind(this)} label="CD-Player" />
                    <Form.Checkbox checked={this.state.base.centralLocking} value={this.state.base.centralLocking} name="centralLocking" onChange={this.handleChange.bind(this)} label="Zentralverieglung" />
                    <Form.Checkbox checked={this.state.base.centralLubricantApplication} value={this.state.base.centralLubricantApplication} name="centralLubricantApplication" onChange={this.handleChange.bind(this)} label="Zentrale Schmiermittel verteilung" />
                    <Form.Checkbox checked={this.state.base.circularSeatingArrangement} value={this.state.base.circularSeatingArrangement} name="circularSeatingArrangement" onChange={this.handleChange.bind(this)} label="Rundsitz" />
                    <Form.Checkbox checked={this.state.base.coldstore} value={this.state.base.coldstore} name="coldstore" onChange={this.handleChange.bind(this)} label="Kühlschrank" />
                    <Form.Checkbox checked={this.state.base.compressor} value={this.state.base.compressor} name="compressor" onChange={this.handleChange.bind(this)} label="Kompressor" />
                    <Form.Checkbox checked={this.state.base.crane} value={this.state.base.crane} name="crane" onChange={this.handleChange.bind(this)} label="Kran" />
                    <Form.Checkbox checked={this.state.base.disabledAccessible} value={this.state.base.disabledAccessible} name="disabledAccessible" onChange={this.handleChange.bind(this)} label="Behindertengerecht" />
                    <Form.Checkbox checked={this.state.base.disabledConversion} value={this.state.base.disabledConversion} name="disabledConversion" onChange={this.handleChange.bind(this)} label="Behindertengerecht möglich" />
                    <Form.Checkbox checked={this.state.base.discBrake} value={this.state.base.discBrake} name="discBrake" onChange={this.handleChange.bind(this)} label="Scheibenbremsen" />
                    <Form.Checkbox checked={this.state.base.divider} value={this.state.base.divider} name="divider" onChange={this.handleChange.bind(this)} label="Trennwand" />
                    <Form.Checkbox checked={this.state.base.driversSleepingCompartment} value={this.state.base.driversSleepingCompartment} name="driversSleepingCompartment" onChange={this.handleChange.bind(this)} label="Schlafkabine" />
                    <Form.Checkbox checked={this.state.base.ebs} value={this.state.base.ebs} name="ebs" onChange={this.handleChange.bind(this)} label="Elektronisches-Brems-System" />
                    <Form.Checkbox checked={this.state.base.electricAdjustableSeats} value={this.state.base.electricAdjustableSeats} name="electricAdjustableSeats" onChange={this.handleChange.bind(this)} label="Elektronisch Anpassbare Sitze" />
                    <Form.Checkbox checked={this.state.base.electricExteriorMirrors} value={this.state.base.electricExteriorMirrors} name="electricExteriorMirrors" onChange={this.handleChange.bind(this)} label="Elektrische Außenspiegel" />
                    <Form.Checkbox checked={this.state.base.electricHeatedSeats} value={this.state.base.electricHeatedSeats} name="electricHeatedSeats" onChange={this.handleChange.bind(this)} label="Elektronische Sitzheizung" />
                    <Form.Checkbox checked={this.state.base.electricStarter} value={this.state.base.electricStarter} name="electricStarter" onChange={this.handleChange.bind(this)} label="Elektrischer Starter" />
                    <Form.Checkbox checked={this.state.base.electricWindows} value={this.state.base.electricWindows} name="electricWindows" onChange={this.handleChange.bind(this)} label="Elektrische Fensterheber" />
                    <Form.Checkbox checked={this.state.base.esp} value={this.state.base.esp} name="esp" onChange={this.handleChange.bind(this)} label="ESP" />
                    <Form.Checkbox checked={this.state.base.frontFogLights} value={this.state.base.frontFogLights} name="frontFogLights" onChange={this.handleChange.bind(this)} label="Nebelleuchten vorne" />
                    <Form.Checkbox checked={this.state.base.frontHydraulics} value={this.state.base.frontHydraulics} name="frontHydraulics" onChange={this.handleChange.bind(this)} label="Hydraulik vorne" />
                    <Form.Checkbox checked={this.state.base.frontJack} value={this.state.base.frontJack} name="frontJack" onChange={this.handleChange.bind(this)} label="Fronthaken" />
                    <Form.Checkbox checked={this.state.base.fullFairing} value={this.state.base.fullFairing} name="fullFairing" onChange={this.handleChange.bind(this)} label="Vollverkleidung zur Effizienz erhöhung" />
                    <Form.Checkbox checked={this.state.base.handsFreePhoneSystem} value={this.state.base.handsFreePhoneSystem} name="handsFreePhoneSystem" onChange={this.handleChange.bind(this)} label="Hands-Free Telefon-System" />
                    <Form.Checkbox checked={this.state.base.headUpDisplay} value={this.state.base.headUpDisplay} name="headUpDisplay" onChange={this.handleChange.bind(this)} label="Headup display" />
                    <HydraulicInstallation value={this.state.base.hydraulicInstallation} name="hydraulicInstallation" onChange={this.handleChange.bind(this)} />
                    <Form.Checkbox checked={this.state.base.immobilizer} value={this.state.base.immobilizer} name="immobilizer" onChange={this.handleChange.bind(this)} label="Wegfahrsperre" />
                    <Form.Checkbox checked={this.state.base.isofix} value={this.state.base.isofix} name="isofix" onChange={this.handleChange.bind(this)} label="ISOFIX-Zertifiziert" />
                    <Form.Checkbox checked={this.state.base.kickstarter} value={this.state.base.kickstarter} name="kickstarter" onChange={this.handleChange.bind(this)} label="Kickstarter" />
                    <Form.Checkbox checked={this.state.base.kitchen} value={this.state.base.kitchen} name="kitchen" onChange={this.handleChange.bind(this)} label="Küche" />
                    <Form.Checkbox checked={this.state.base.lightSensor} value={this.state.base.lightSensor} name="lightSensor" onChange={this.handleChange.bind(this)} label="Lichtsensor" />
                    <Form.Checkbox checked={this.state.base.middleSeatingArrangement} value={this.state.base.middleSeatingArrangement} name="middleSeatingArrangement" onChange={this.handleChange.bind(this)} label="Mittel Sitz verschiebbar" />
                    <Form.Checkbox checked={this.state.base.multifunctionalWheel} value={this.state.base.multifunctionalWheel} name="multifunctionalWheel" onChange={this.handleChange.bind(this)} label="Multifunktionslekrad" />
                    <Form.Checkbox checked={this.state.base.navigationSystem} value={this.state.base.navigationSystem} name="navigationSystem" onChange={this.handleChange.bind(this)} label="Navigationsgerät" />
                    <Form.Checkbox checked={this.state.base.onBoardComputer} value={this.state.base.onBoardComputer} name="onBoardComputer" onChange={this.handleChange.bind(this)} label="Board-Computer" />
                    <Form.Checkbox checked={this.state.base.panoramicGlassRoof} value={this.state.base.panoramicGlassRoof} name="panoramicGlassRoof" onChange={this.handleChange.bind(this)} label="Pannoramer Dach" />
                    <Form.Checkbox checked={this.state.base.parkingAssistants} value={this.state.base.parkingAssistants} name="parkingAssistants" onChange={this.handleChange.bind(this)} label="Parkassistent" />
                    <SpeedControl value={this.state.base.speedControl} name="speedControl" onChange={this.handleChange.bind(this)} />
                    <Form.Checkbox checked={this.state.base.radio} value={this.state.base.radio} name="radio" onChange={this.handleChange.bind(this)} label="Radio/Tuner" />
                    <Form.Select value={this.state.base.daytimeRunningLamps} name="daytimeRunningLamps" onChange={this.handleChange.bind(this)} label="Tagfahrlicht" options={[{key:"DAYTIME_RUNNING_LIGHTS",value:"DAYTIME_RUNNING_LIGHTS",text:"Tagfahrlicht"},{key:"LED_RUNNING_LIGHTS",value:"LED_RUNNING_LIGHTS",text:"LED-Tagfahrlicht"}]} />
                    <Form.Select value={this.state.base.slidingDoorType} name="slidingDoorType" onChange={this.handleChange.bind(this)} label="Schiebetür" options={[{key:"SLIDING_DOOR_RIGHT",value:"SLIDING_DOOR_RIGHT",text:"Schiebetür rechts"},{key:"SLIDING_DOOR_LEFT",value:"SLIDING_DOOR_LEFT",text:"Schiebetür links"},{key:"SLIDING_DOOR_BOTH_SIDED",value:"SLIDING_DOOR_BOTH_SIDED",text:"Schiebetür beidseitig"}]} />
                    <Form.Select value={this.state.base.headlightType} name="headlightType" onChange={this.handleChange.bind(this)} label="Scheinwerfer Typ" options={[{key:"XENON_HEADLIGHTS",value:"XENON_HEADLIGHTS",text:"Xenonscheinwerfer"},{key:"BI_XENON_HEADLIGHTS",value:"BI_XENON_HEADLIGHTS",text:"Bi-Xenon Scheinwerfer"},{key:"LED_HEADLIGHTS",value:"LED_HEADLIGHTS",text:"LED-Scheinwerfer"},{key:"LASER_HEADLIGHTS",value:"LASER_HEADLIGHTS",text:"Laserlicht"}]} />
                    <Form.Select value={this.state.base.bendingLightsType} name="bendingLightsType" onChange={this.handleChange.bind(this)} label="Kurvenlicht" options={[{key:"BENDING_LIGHTS",value:"BENDING_LIGHTS",text:"Kurvenlicht"},{key:"ADAPTIVE_BENDING_LIGHTS",value:"ADAPTIVE_BENDING_LIGHTS",text:"Adaptives Kurvenlicht"}]} />
                    <Form.Select value={this.state.base.breakdownService} name="breakdownService" onChange={this.handleChange.bind(this)} label="Unfall Fürsorge" options={[{key:"SPARE_WHEEL",value:"SPARE_WHEEL",text:"Reserverad"},{key:"EMERGENCY_WHEEL",value:"EMERGENCY_WHEEL",text:"Notrad"},{key:"REPAIR_KIT",value:"REPAIR_KIT",text:"Pannenkit"}]} />
                    <Form.Select value={this.state.base.battery} name="battery" onChange={this.handleChange.bind(this)} label="Batterie" options={[{key:"BATTERY_RENTED",value:"BATTERY_RENTED",text:"Batterie gemietet"},{key:"BATTERY_PURCHASED",value:"BATTERY_PURCHASED",text:"Batterie gekauft"},{key:"BATTERY_MISSING",value:"BATTERY_MISSING",text:"Batterie fehlt"}]} />
                    <Form.Select value={this.state.base.trailerCouplingType} name="trailerCouplingType" onChange={this.handleChange.bind(this)} label="Anhängerkupplung" options={[{key:"TRAILER_COUPLING_FIX",value:"TRAILER_COUPLING_FIX",text:"Anhängerkupplung fest"},{key:"TRAILER_COUPLING_SWIVELING",value:"TRAILER_COUPLING_SWIVELING",text:"Anhängerkupplung schwenkbar"},{key:"TRAILER_COUPLING_DETACHABLE",value:"TRAILER_COUPLING_DETACHABLE",text:"Anhängerkupplung abnehmbar"}]} />
                    <Form.Input value={this.state.base.trimLine} name="trimLine" onChange={this.handleChange.bind(this)} label="Ausstattungslinie" />
                    <Form.Input value={this.state.base.modelRange} name="modelRange" onChange={this.handleChange.bind(this)} label="Baureihe" />
                    <Form.Input type="number" value={this.state.base.batteryCapacity} name="batteryCapacity" onChange={this.handleChange.bind(this)} label="Batterie Kapazität in kWh" />
                    <Form.Checkbox checked={this.state.base.powerAssistedSteering} value={this.state.base.powerAssistedSteering} name="powerAssistedSteering" onChange={this.handleChange.bind(this)} label="Servolenkung" />
                    <Form.Checkbox checked={this.state.base.protectionRoof} value={this.state.base.protectionRoof} name="protectionRoof" onChange={this.handleChange.bind(this)} label="Verhärtetes Dach" />
                    <Form.Checkbox checked={this.state.base.rollOverBar} value={this.state.base.rollOverBar} name="rollOverBar" onChange={this.handleChange.bind(this)} label="Überrollkäfig" />
                    <Form.Checkbox checked={this.state.base.quickChangeAttachment} value={this.state.base.quickChangeAttachment} name="quickChangeAttachment" onChange={this.handleChange.bind(this)} label="Schnellkuppler" />
                    <Form.Checkbox checked={this.state.base.rearGarage} value={this.state.base.rearGarage} name="rearGarage" onChange={this.handleChange.bind(this)} label="Hintere Garage" />
                    <Form.Checkbox checked={this.state.base.roadLicence} value={this.state.base.roadLicence} name="roadLicence" onChange={this.handleChange.bind(this)} label="Straßenzulassung" />
                    <Form.Checkbox checked={this.state.base.roofRails} value={this.state.base.roofRails} name="roofRails" onChange={this.handleChange.bind(this)} label="Dachreling" />
                    <Form.Checkbox checked={this.state.base.secondaryAirConditioning} value={this.state.base.secondaryAirConditioning} name="secondaryAirConditioning" onChange={this.handleChange.bind(this)} label="Sekundäre Klimaanlage" />
                    <Form.Checkbox checked={this.state.base.sepShower} value={this.state.base.sepShower} name="sepShower" onChange={this.handleChange.bind(this)} label="Separate Dusche" />
                    <Form.Checkbox checked={this.state.base.sideSeatingArrangement} value={this.state.base.sideSeatingArrangement} name="sideSeatingArrangement" onChange={this.handleChange.bind(this)} label="Seiten Sitz verstellbar" />
                    <Form.Checkbox checked={this.state.base.skiBag} value={this.state.base.skiBag} name="skiBag" onChange={this.handleChange.bind(this)} label="SKI-Kiste" />
                    <Form.Checkbox checked={this.state.base.sleepSeats} value={this.state.base.sleepSeats} name="sleepSeats" onChange={this.handleChange.bind(this)} label="Schlafsitze" />
                    <Form.Checkbox checked={this.state.base.solarEnergySystem} value={this.state.base.solarEnergySystem} name="solarEnergySystem" onChange={this.handleChange.bind(this)} label="Solarzellen" />
                    <Form.Checkbox checked={this.state.base.sportPackage} value={this.state.base.sportPackage} name="sportPackage" onChange={this.handleChange.bind(this)} label="Sportpacket" />
                    <Form.Checkbox checked={this.state.base.sportSeats} value={this.state.base.sportSeats} name="sportSeats" onChange={this.handleChange.bind(this)} label="Sport Sitze" />
                    <Form.Checkbox checked={this.state.base.sunroof} value={this.state.base.sunroof} name="sunroof" onChange={this.handleChange.bind(this)} label="Sonnendach" />
                    <Form.Checkbox checked={this.state.base.superSingleWheels} value={this.state.base.superSingleWheels} name="superSingleWheels" onChange={this.handleChange.bind(this)} label="Super Einzelräder" />
                    <Form.Checkbox checked={this.state.base.tailLift} value={this.state.base.tailLift} name="tailLift" onChange={this.handleChange.bind(this)} label="Hecklift" />
                    <Form.Checkbox checked={this.state.base.tractionControlSystem} value={this.state.base.tractionControlSystem} name="tractionControlSystem" onChange={this.handleChange.bind(this)} label="Traktionssteuersystem" />
                    <Form.Checkbox checked={this.state.base.tv} value={this.state.base.tv} name="tv" onChange={this.handleChange.bind(this)} label="Fernsehr" />
                    <Form.Checkbox checked={this.state.base.ureaTankAdBlue} value={this.state.base.ureaTankAdBlue} name="ureaTankAdBlue" onChange={this.handleChange.bind(this)} label="AdBlue" />
                    <Form.Checkbox checked={this.state.base.wc} value={this.state.base.wc} name="wc" onChange={this.handleChange.bind(this)} label="Toilette" />
                    <Form.Checkbox checked={this.state.base.windshield} value={this.state.base.windshield} name="windshield" onChange={this.handleChange.bind(this)} label="Windschutz" />
                    <Form.Checkbox checked={this.state.base.alarmSystem} value={this.state.base.alarmSystem} name="alarmSystem" onChange={this.handleChange.bind(this)} label="Alarmsystem" />
                    <Form.Checkbox checked={this.state.base.armRest} value={this.state.base.armRest} name="armRest" onChange={this.handleChange.bind(this)} label="Armlehne" />
                    <Form.Checkbox checked={this.state.base.heatedWindshield} value={this.state.base.heatedWindshield} name="heatedWindshield" onChange={this.handleChange.bind(this)} label="Beheizter Windschutz" />
                    <Form.Checkbox checked={this.state.base.heatedSteeringWheel} value={this.state.base.heatedSteeringWheel} name="heatedSteeringWheel" onChange={this.handleChange.bind(this)} label="beheiztes Lenkrad" />
                    <Form.Checkbox checked={this.state.base.hillStartAssist} value={this.state.base.hillStartAssist} name="hillStartAssist" onChange={this.handleChange.bind(this)} label="Anfahrt-Assistent" />
                    <Form.Checkbox checked={this.state.base.electricTailgate} value={this.state.base.electricTailgate} name="electricTailgate" onChange={this.handleChange.bind(this)} label="Elektrische Heckklappe" />
                    <Form.Checkbox checked={this.state.base.leatherSteeringWheel} value={this.state.base.leatherSteeringWheel} name="leatherSteeringWheel" onChange={this.handleChange.bind(this)} label="Leder bezogenes Lenkrad" />
                    <Form.Checkbox checked={this.state.base.lumbarSupport} value={this.state.base.lumbarSupport} name="lumbarSupport" onChange={this.handleChange.bind(this)} label="Lumbar Sitze/Kissen" />
                    <Form.Checkbox checked={this.state.base.massageSeats} value={this.state.base.massageSeats} name="massageSeats" onChange={this.handleChange.bind(this)} label="Massage Sitze" />
                    <Form.Checkbox checked={this.state.base.fatigueWarningSystem} value={this.state.base.fatigueWarningSystem} name="fatigueWarningSystem" onChange={this.handleChange.bind(this)} label="Müdigkeits Warn-System" />
                    <Form.Checkbox checked={this.state.base.nightVisionAssist} value={this.state.base.nightVisionAssist} name="nightVisionAssist" onChange={this.handleChange.bind(this)} label="Nachtsicht-Assistent" />
                    <Form.Checkbox checked={this.state.base.emergencyCallSystem} value={this.state.base.emergencyCallSystem} name="emergencyCallSystem" onChange={this.handleChange.bind(this)} label="Emergency-Call System" />
                    <Form.Checkbox checked={this.state.base.tirePressureMonitoring} value={this.state.base.tirePressureMonitoring} name="tirePressureMonitoring" onChange={this.handleChange.bind(this)} label="Reifendruck-Warn-System" />
                    <Form.Checkbox checked={this.state.base.paddleShifters} value={this.state.base.paddleShifters} name="paddleShifters" onChange={this.handleChange.bind(this)} label="Lenkrad Gang-Schaltung" />
                    <Form.Checkbox checked={this.state.base.electricHeatedRearSeats} value={this.state.base.electricHeatedRearSeats} name="electricHeatedRearSeats" onChange={this.handleChange.bind(this)} label="Elektrisch beheizte Rücksitze" />
                    <Form.Checkbox checked={this.state.base.soundSystem} value={this.state.base.soundSystem} name="soundSystem" onChange={this.handleChange.bind(this)} label="Verbessertes Sound-System" />
                    <Form.Checkbox checked={this.state.base.voiceControl} value={this.state.base.voiceControl} name="voiceControl" onChange={this.handleChange.bind(this)} label="Sprachsteuerung" />
                    <Form.Checkbox checked={this.state.base.touchscreen} value={this.state.base.touchscreen} name="touchscreen" onChange={this.handleChange.bind(this)} label="Touchscreen" />
                    <Form.Checkbox checked={this.state.base.usb} value={this.state.base.usb} name="usb" onChange={this.handleChange.bind(this)} label="USB" />
                    <Form.Checkbox checked={this.state.base.trafficSignRecognition} value={this.state.base.trafficSignRecognition} name="trafficSignRecognition" onChange={this.handleChange.bind(this)} label="Verkehrsschild erkennung" />
                    <Form.Checkbox checked={this.state.base.highBeamAssist} value={this.state.base.highBeamAssist} name="highBeamAssist" onChange={this.handleChange.bind(this)} label="Fern- und Abblendlicht Assistent" />
                    <Form.Checkbox checked={this.state.base.summerTires} value={this.state.base.summerTires} name="summerTires" onChange={this.handleChange.bind(this)} label="Sommerreifen" />
                    <Form.Checkbox checked={this.state.base.winterTires} value={this.state.base.winterTires} name="winterTires" onChange={this.handleChange.bind(this)} label="Winterreifen" />
                    <Form.Checkbox checked={this.state.base.allSeasonTires} value={this.state.base.allSeasonTires} name="allSeasonTires" onChange={this.handleChange.bind(this)} label="Jahreszeiten-Sensor" />
                    <Form.Checkbox checked={this.state.base.steelWheels} value={this.state.base.steelWheels} name="steelWheels" onChange={this.handleChange.bind(this)} label="Stahlfelgen" />
                    <Form.Checkbox checked={this.state.base.glareFreeHighBeam} value={this.state.base.glareFreeHighBeam} name="glareFreeHighBeam" onChange={this.handleChange.bind(this)} label="Fernlicht-Matrix" />
                    <Form.Checkbox checked={this.state.base.headlightWasherSystem} value={this.state.base.headlightWasherSystem} name="headlightWasherSystem" onChange={this.handleChange.bind(this)} label="Scheinwerfer-Wash-System" />
                    <Form.Checkbox checked={this.state.base.winterPackage} value={this.state.base.winterPackage} name="winterPackage" onChange={this.handleChange.bind(this)} label="Winterpacket" />
                    <Form.Checkbox checked={this.state.base.smokersPackage} value={this.state.base.smokersPackage} name="smokersPackage" onChange={this.handleChange.bind(this)} label="Raucher Packet" />
                    <Form.Checkbox checked={this.state.base.distanceWarningSystem} value={this.state.base.distanceWarningSystem} name="distanceWarningSystem" onChange={this.handleChange.bind(this)} label="Abstand Warnsystem" />
                    <Form.Checkbox checked={this.state.base.electricBackseatAdjustment} value={this.state.base.electricBackseatAdjustment} name="electricBackseatAdjustment" onChange={this.handleChange.bind(this)} label="elektrische Rücksitzverstellung" />
                    <Form.Checkbox checked={this.state.base.ambientLighting} value={this.state.base.ambientLighting} name="ambientLighting" onChange={this.handleChange.bind(this)} label="Ambient Light" />
                    <Form.Checkbox checked={this.state.base.wifiHotspot} value={this.state.base.wifiHotspot} name="wifiHotspot" onChange={this.handleChange.bind(this)} label="WIFI-Hotspot" />
                    <Form.Checkbox checked={this.state.base.carplay} value={this.state.base.carplay} name="carplay" onChange={this.handleChange.bind(this)} label="Carplay" />
                    <Form.Checkbox checked={this.state.base.androidAuto} value={this.state.base.androidAuto} name="androidAuto" onChange={this.handleChange.bind(this)} label="Android-Auto" />
                    <Form.Checkbox checked={this.state.base.digitalCockpit} value={this.state.base.digitalCockpit} name="digitalCockpit" onChange={this.handleChange.bind(this)} label="Digitales Cockpit" />
                    <Form.Checkbox checked={this.state.base.passengerSeatIsofixPoint} value={this.state.base.passengerSeatIsofixPoint} name="passengerSeatIsofixPoint" onChange={this.handleChange.bind(this)} label="ISOFIX-Beifahrer" />
                    <Form.Checkbox checked={this.state.base.wirelessCharging} value={this.state.base.wirelessCharging} name="wirelessCharging" onChange={this.handleChange.bind(this)} label="Kabelloses Laden" />
                    <Form.Checkbox checked={this.state.base.integratedMusicStreaming} value={this.state.base.integratedMusicStreaming} name="integratedMusicStreaming" onChange={this.handleChange.bind(this)} label="Musik Streaming" />
                    <Form.Checkbox checked={this.state.base.dimmingInteriorMirror} value={this.state.base.dimmingInteriorMirror} name="dimmingInteriorMirror" onChange={this.handleChange.bind(this)} label="Innenspiegel dimmer" />
                    <Form.Checkbox checked={this.state.base.foldFlatPassengerSeat} value={this.state.base.foldFlatPassengerSeat} name="foldFlatPassengerSeat" onChange={this.handleChange.bind(this)} label="Zusammenklappbarer Beifahrersitz" />
                    <Form.Checkbox checked={this.state.base.cargoBarrier} value={this.state.base.cargoBarrier} name="cargoBarrier" onChange={this.handleChange.bind(this)} label="Frachtbarriere" />
                    <Form.Checkbox checked={this.state.base.speedLimiter} value={this.state.base.speedLimiter} name="speedLimiter" onChange={this.handleChange.bind(this)} label="Geschwindigkeitsbegrenzer" />
                    <Form.Checkbox checked={this.state.base.rangeExtender} value={this.state.base.rangeExtender} name="rangeExtender" onChange={this.handleChange.bind(this)} label="Reichweitenverstärker" />
                    <Form.Checkbox checked={this.state.base.collisionAvoidance} value={this.state.base.collisionAvoidance} name="collisionAvoidance" onChange={this.handleChange.bind(this)} label="Kollisionsvermeidung-Assistent" />
                    <Form.Checkbox checked={this.state.base.keylessEntry} value={this.state.base.keylessEntry} name="keylessEntry" onChange={this.handleChange.bind(this)} label="Keyless" />
                    <Form.Checkbox checked={this.state.base.ventilatedSeats} value={this.state.base.ventilatedSeats} name="ventilatedSeats" onChange={this.handleChange.bind(this)} label="Belüftete Sitze" />
                    <Form.Checkbox checked={this.state.base.laneDepartureWarning} value={this.state.base.laneDepartureWarning} name="laneDepartureWarning" onChange={this.handleChange.bind(this)} label="Spurverlassungswarnung" />
                    <Form.Checkbox checked={this.state.base.blindSpotMonitor} value={this.state.base.blindSpotMonitor} name="blindSpotMonitor" onChange={this.handleChange.bind(this)} label="Toter-Winkel-Assistent" />
                </div>)}
            },
            {
                key:"bookableFeatures",
                title: "Kostenpflichtige Extras",
                content: {content:(<div>
                    <Segment.Group>
                        <Header attached="top">
                            <Image src='/images/mobile.png' style={{ height: 20, width: 'auto' }} size='small' /> mobile.de
                            <Header.Subheader>Preise können abweichen! Bitte entnehmen Sie die Preise von der mobile.de Seite.</Header.Subheader>
                        </Header>
                        <Segment attached="bottom" style={{paddingLeft:0,paddingRight:0}}>
                            <List  divided relaxed>
                                <List.Item style={{paddingLeft:10,paddingRight:10}}>
                                    <List.Icon verticalAlign='top'> <Form.Checkbox name="mobile_topOfPage" checked={this.state.extras.mobile_topOfPage} onChange={this.handleChangeExtras.bind(this)} /></List.Icon>
                                    <List.Content >
                                        <List.Header >Top-Inserat: 6,00 € pro Fahrzeug/Tag (kostenfreie Kontingente für die Pakete Komfort und Premium)</List.Header>
                                        <List.Description >
				                            <p>Mit dem Top-Inserat platzieren Sie Ihr Fahrzeug an der allerersten Stelle der Suchergebnisseiten. Voraussetzung ist, dass Ihr Fahrzeug den Suchkriterien des potenziellen Käufers entspricht. Bei mehr als einem zur Suche passenden Top-Inserat wird das angezeigte Inserat unter allen gebuchten Top-Inseraten per Zufall ausgewählt.</p>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item style={{paddingLeft:10,paddingRight:10}}>
                                    <List.Icon verticalAlign='top' > <Form.Checkbox name="mobile_adTurbo" checked={this.state.extras.mobile_adTurbo} onChange={this.handleChangeExtras.bind(this)}  /></List.Icon>
                                    <List.Content >
                                        <List.Header >Inseratsturbo: 2,99 € pro Fahrzeug/Tag (Exklusiv buchbar in den Paketen Premium und Komfort)</List.Header>
                                        <List.Description >
				                            <p>Mit dem Inseratsturbo wird Ihr Inserat in der mobile.de Standard-Sortierung weiter vorn positioniert und damit häufiger aufgerufen. Zudem wird Ihr Inserat automatisch zu eBay Kleinanzeigen exportiert und dort regelmäßig auf der ersten Seite platziert. So erreichen Sie mehr Interessenten aus Ihrer Region.</p>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item style={{paddingLeft:10,paddingRight:10}}>
                                    <List.Icon verticalAlign='top' > <Form.Checkbox name="mobile_pageOneAd" checked={this.state.extras.mobile_pageOneAd} onChange={this.handleChangeExtras.bind(this)}  /></List.Icon>
                                    <List.Content >
                                        <List.Header >Seite-1-Inserat: 1,20 € pro Fahrzeug/Tag (kostenfreie Kontingente für die Pakete Komfort und Premium)</List.Header>
                                        <List.Description >
				                            <p>Mit dem Seite-1-Inserat erscheint Ihr Inserat auf der ersten Seite der Suchergebnisse, wenn Ihr Fahrzeug den Suchkriterien eines Interessenten entspricht. Bei mehr als drei zur Suche passenden Seite-1-Inseraten werden die präsentierten Inserate unter allen gebuchten Seite-1-Inseraten per Zufall ausgewählt.</p>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item style={{paddingLeft:10,paddingRight:10}}>
                                    <List.Icon verticalAlign='top' > <Form.Checkbox name="mobile_redPencil" checked={this.state.extras.mobile_redPencil} onChange={this.handleChangeExtras.bind(this)}  /></List.Icon>
                                    <List.Content >
                                        <List.Header >Rotstiftpreis: 0,90 € pro Fahrzeug/Tag (kostenfrei für Nutzer des Premium-Pakets)</List.Header>
                                        <List.Description >
				                            <p>Der Rotstiftpreis macht Interessenten schnell und effektiv auf eine Preissenkung aufmerksam. Er erscheint rot hervorgehoben als durchgestrichener Preis über dem neuen, gesenkten Preis. Der Rotstiftpreis wird Interessenten auf der Trefferliste und auf der Detailansicht Ihres Inserats angezeigt.</p>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item style={{paddingLeft:10,paddingRight:10}}>
                                    <List.Icon verticalAlign='top' > <Form.Checkbox name="mobile_eyeCatcher" checked={this.state.extras.mobile_eyeCatcher} onChange={this.handleChangeExtras.bind(this)}  /></List.Icon>
                                    <List.Content >
                                        <List.Header >Blickfänger-Inserat: 0,80 € pro Fahrzeug/Tag (kostenfreie Kontingente für die Pakete Komfort und Premium)</List.Header>
                                        <List.Description >
				                            <p>Mit dem Blickfänger werden Ihre Inserate in der Suchergebnisliste größer und auffälliger dargestellt – mit farbiger Hervorhebung und übersichtlicherer Struktur.</p>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item style={{paddingLeft:10,paddingRight:10}}>
                                    <List.Icon verticalAlign='top' > <Form.Checkbox name="mobile_ebaykExport" checked={this.state.extras.mobile_ebaykExport} onChange={this.handleChangeExtras.bind(this)}  /></List.Icon>
                                    <List.Content >
                                        <List.Header >Export zu eBay Kleinanzeigen: 0,50 € pro Fahrzeug/Tag (teilw. kostenfrei für die Pakete Komfort und Premium)</List.Header>
                                        <List.Description >
				                            <p>Mit dem eBay Kleinanzeigen Export werden Ihre Inserate zusätzlich zu eBay Kleinanzeigen exportiert. Damit bieten Sie Ihre Fahrzeuge zusätzlich auf einem Marktplatz an, der stark auf die lokale Suche spezialisiert ist. Ihr Fahrzeug wird dabei nicht als Auktion, sondern zum Festpreis angeboten.</p>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item style={{paddingLeft:10,paddingRight:10}}>
                                    <List.Icon verticalAlign='top' > <Form.Checkbox name="mobile_financing" checked={this.state.extras.mobile_financing} onChange={this.handleChangeExtras.bind(this)}  /></List.Icon>
                                    <List.Content >
                                        <List.Header >Finanzierungsvorschlag: 0,10 € pro Fahrzeug/Tag</List.Header>
                                        <List.Description >
				                            <p>Sie können Ihren Inseraten Finanzierungsvorschläge hinzufügen (Ausnahme: Lkw und Nutzfahrzeuge). Mit der Buchung eines Finanzierungsvorschlags wird Ihr Angebot ausführlich auf der Detailansicht angezeigt und auf den Suchergebnisseiten gekennzeichnet.</p>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            </List>
                        </Segment>
                    </Segment.Group>
                </div>)}
            }
        ]
        let FormBox=Form
        if(this.props.noForm){
            FormBox="div"
        }
        return (
            <FormBox loading={this.props.saving}>
                <Segment.Group style={{marginTop:20,marginBottom:20}}>
                    <Header attached="top" style={{borderTop:"2px solid #000"}} >
                        Werbe-Anzeige
                        <Header.Subheader>Einstellungen für mobile.de und/oder Autoscout24</Header.Subheader>
                    </Header>
                    <Segment attached>
                        <Form.Field>
                            <label>Anzeigentitel</label>
                            <Input value={this.state.base.modelDescription?this.state.base.modelDescription:this.props.car.manufacturer+" "+this.props.car.type} name="modelDescription" onChange={this.handleChange.bind(this)} />
                            <small style={{marginTop:"-1em"}} className="helper">Eine detailierte Beschreibung des Fahrzeugmodells. Beispiel: Golf III leichte Kratzer. </small>
                        </Form.Field>
                    </Segment>
                    <Accordion fluid styled panels={panels} style={{borderRadius:0}} />
                </Segment.Group>
            </FormBox>
        )
    }
}

export default AdSettings