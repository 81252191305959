import React, {Component} from 'react'
import {  Form, Grid, Header,Icon, Segment,Button, Loader } from 'semantic-ui-react'
import {withRouter, Route} from 'react-router-dom'
import client from '../../shared/feathers'

class Forgot extends Component{
    state={
        loading:false,
        finish: false
    }
    request(){
        this.setState({loading:true})
        client.service("authManagement").create({ 
            action: 'sendResetPwd',
            value: {email:this.state.email},
        }).then(result=>{
            console.log(result)
            this.setState({loading:false,finish:true})
        }).catch(e=>{
            console.log(e)
            this.setState({loading:false,finish:true})
        })
    }
    handleChange = (e, { name, value }) => this.setState({ [name]: value })
    render(){
        return (
            <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
                <Grid.Column textAlign='left' style={{ maxWidth: 760 }}>
                    <center style={{marginTop:20}}><img height="70" src="/autodigi_weiss.png"></img></center>
                    <Segment style={{overflow:"hidden"}} >
                        <Grid >
                            {(!this.state.finish&&!this.state.loading) && <Grid.Column style={{paddingTop:30,paddingRight:30}} >
                                <Header as="h3">Passwort vergessen?</Header>
                                <Header.Subheader>Lassen Sie sich per E-Mail einen Passwort zurücksetzen Link zusenden.<br/>Geben Sie dafür einfach Ihre E-Mail-Addresse an</Header.Subheader>
                                <Form style={{paddingTop:35}} onSubmit={this.request.bind(this)}>
                                    <Form.Input label="E-Mail" required type="email" name="email" onChange={this.handleChange.bind(this)}/>
                                    <Form.Button primary fluid icon labelPosition="right" style={{textAlign:"left"}}>
                                        Passwort wiederherstellen
                                        <Icon name='envelope' />
                                    </Form.Button>
                                    <Button as="a" onClick={()=>this.props.history.push("/Authentification")} fluid icon labelPosition="right" style={{textAlign:"left"}}>
                                        Zurück
                                        <Icon name='arrow left' />
                                    </Button>
                                </Form>
                            </Grid.Column>}
                            {(this.state.finish&&!this.state.loading) && <Grid.Column style={{paddingTop:90}} >
                                <Header as="h3">Überprüfen Sie ihre E-Mails</Header>
                                <Header.Subheader>Rufen Sie die E-Mail ab, die wir Ihnen gerade gesendet haben. Dort können Sie Ihr Passwort zurücksetzen.</Header.Subheader>
                                {/*<Button as="a" primary onClick={()=>this.props.history.push("/Authentification/forgot-password/Verify")} fluid icon labelPosition="right" style={{textAlign:"left", marginBottom:5}}>
                                    Jetzt Code eingeben
                                    <Icon name='arrow right' />
                                </Button>*/}
                                <Button as="a" onClick={()=>this.props.history.push("/Authentification")} fluid icon labelPosition="right" style={{textAlign:"left",marginTop:30}}>
                                    Zurück zur Anmeldung
                                    <Icon name='arrow left' />
                                </Button>
                            </Grid.Column>}
                            {this.state.loading && <Grid.Column width="12"><Loader active>Überprüfe E-Mail</Loader></Grid.Column>}
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }
} 

export default withRouter(Forgot)