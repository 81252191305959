import React, { Component } from "react";
import { Tab, Button } from "semantic-ui-react";


class PayPal extends Component {
    render() {
        return (
            <Tab.Pane attached={false}>
                <Button size="big" primary fluid>Jetzt mit PayPal verbinden</Button>
            </Tab.Pane>
        )
    }
}

export default PayPal