import React,{ Component } from "react";
import { Form } from "semantic-ui-react";
import axios from 'axios'
import Category from './Category'

class CarBrand extends Component{
    state={
        loadingClass:true,
        loadingBrand:false,
        disabledBrand:true,
        loadingType:false,
        disabledType:true,
        classification:null,
        manufacturer:null,
        type:null,
        classes:[],
        manufacturers:[],
        types:[]
    }
    async componentDidMount(){
        if(this.props.classification){
            await this.updateClass()
            await this.setStateAsync({classification:this.props.classification})
        }else{
            await this.updateClass()
            await this.setStateAsync({classification:"Car"})
            await this.updateBrand()
        }
        if(this.props.manufacturer){
            await this.updateBrand()
            await this.setStateAsync({manufacturer:this.props.manufacturer})
        }
        if(this.props.type){
            await this.updateType()
            await this.setStateAsync({type:this.props.type})
        }

    }
    async componentDidUpdate(prevProps){
        if(this.state.classification!=this.props.classification){
            await this.setStateAsync({classification:this.props.classification})
            await this.updateBrand()
        }
    }
    handleChange(e,{name,value}){
        this.setState({[name]:value},()=>{
            if(name=="classification"){this.updateBrand()}
            if(name=="manufacturer"){this.updateType()}
        })
        let {manufacturer,type,classification} = this.state
        this.props.onChange({manufacturer,classification,type,[name]:value})
    }
    setStateAsync(state) {
        return new Promise((resolve) => {
          this.setState(state, resolve)
        });
    }
    async updateClass(){
        this.setState({loadingClass:true,classes:[],classification:null})
        await this.setStateAsync( {classes:(await axios.get("https://carbill.dev.tecoyo.com/refdata/classes",{headers:{Accept:"application/vnd.de.mobile.api+json"},withCredentials:false}) ).data.values.map(el=>({text:el.description,value:el.name,key:el.name})) } )
        return true;
    }
    async updateBrand(){
        this.setState({loadingBrand:true,manufacturers:[],manufacturer:null,type:null,types:[]})
        await this.setStateAsync( {loadingBrand:false,manufacturers:(await axios.get("https://carbill.dev.tecoyo.com/refdata/classes/"+this.state.classification+"/makes",{headers:{Accept:"application/vnd.de.mobile.api+json"},withCredentials:false}) ).data.values.map(el=>({text:el.description,value:el.name,key:el.name})) } )
        return true;
    }
    async updateType(){
        this.setState({loadingType:true,types:[],type:null})
        try{
            let types = await axios.get("https://carbill.dev.tecoyo.com/refdata/classes/"+this.state.classification+"/makes/"+this.state.manufacturer+"/models",{headers:{Accept:"application/vnd.de.mobile.api+json"},withCredentials:false})
            await this.setStateAsync( {loadingType:false,types:types.data.values.map(el=>({text:el.description,value:el.name,key:el.name})) } )
        }catch(e){
            await this.setStateAsync( {loadingType:false,types:[]} )
        }
        return true;
    }
    render(){
        return (
            <div>
                <Form.Group widths="equal">
                    <Form.Select search options={this.state.manufacturers} disabled={!this.state.classification} loading={this.state.loadingBrand} name="manufacturer" value={this.state.manufacturer} required onChange={this.handleChange.bind(this)} label="Marke"  />
                    {!(this.state.manufacturer && this.state.types.length==0) && <Form.Select search options={this.state.types} disabled={!this.state.manufacturer} loading={this.state.loadingType} name="type" value={this.state.type} required={(!this.state.manufacturer || this.state.types.length!=0)} onChange={this.handleChange.bind(this)} label="Modell" />}
                    {(this.state.manufacturer && this.state.types.length==0) && <Form.Input  label="Model"  name="type" value={this.state.type} required onChange={this.handleChange.bind(this)}  />}
                    <Category classification={this.props.classification} value={this.props.category} onChange={this.handleChange.bind(this)}  />
                </Form.Group>
            </div>
        )
    }
}

export default CarBrand