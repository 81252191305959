import React, { Component } from "react";
import { Segment, Header, Tab, Menu, Icon, Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Contracts from "../../Contracts/list";
import Receipts from "../../Receipts/list";
import Informations from "./informations";
import Events from "./events";
import Comments from './comments'
import Documents from './documents'
import ContractAdd from "../../Contracts/new";
import client from "../../../shared/feathers";

let types = {commercial:"Unternehmen",private:"Privat"}
class CustomerSingle extends Component {
    state={
        loading:true,
        notFound: false,
        customer: null
    }
    componentDidMount() {
        this.reactive = client.service("customers").watch().get( this.props.match.params.id).subscribe(async (data)=>this.setState({customer:data,loading:false}),()=>this.setState({loading:false, notFound: true}))
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    render() {
        if(this.state.loading){
            return <Segment basic><Segment loading placeholder></Segment></Segment>
        }
        if(this.state.notFound){
            return <Segment basic ><Segment placeholder color="red"> <Header icon><Icon name='search' /> Kunde wurde nicht gefunden.</Header> </Segment></Segment>
        }
        let {customer} = this.state

        const panes = [
            { menuItem: (<Menu.Item ><Icon name="info circle" /> Informationen</Menu.Item>), render: () => <Informations customer={customer} /> },
            { menuItem: (<Menu.Item ><Icon name="calendar" /> Termine </Menu.Item>), render: () => <Events customer={customer} /> },
            { menuItem: (<Menu.Item ><Icon name="file pdf" /> Rechnungen </Menu.Item>), render: () => <Tab.Pane attached="bottom" style={{ marginTop: 0, padding: 0, border: 0 }}><Receipts query={{customer_id:customer.id}} TableProps={{ style: { marginTop: -1, borderRadius: 0 } }} SegmentProps={{ attatched: true, style: { marginTop: -1, borderRadius: 0 } }} /></Tab.Pane> },
            { menuItem: (<Menu.Item ><Icon name="car" /> Kaufverträge</Menu.Item>), render: () => <Tab.Pane attached="bottom" style={{ marginTop: 0, padding: 0, border: 0 }}><Contracts query={{customer_id:customer.id}} hideMenu TableProps={{ style: { marginTop: -1, borderRadius: 0 } }} SegmentProps={{ attatched: true, style: { marginTop: -1, borderRadius: 0 } }} /></Tab.Pane> },
            { menuItem: (<Menu.Item ><Icon name="file alternate" /> Dokumente</Menu.Item>), render: () => <Documents customer={customer} /> },
            { menuItem: (<Menu.Item ><Icon name="comment" /> Kommentare</Menu.Item>), render: () => <Comments  customer={customer} />},
        ]

        return (
            <Segment basic >

                <Segment.Group>

                    {/*<Label attached="top" color="green">
                        Der nächste <b>Verkaufs</b> Termin ist am: <b>10.12.2019 15:00</b>
                        <Label.Detail>Termin öffnen </Label.Detail>
                    </Label>*/}
                    <Header attached="top" style={{ position: "relative" }}>
                        {customer.company} {customer.firstname} {customer.lastname}
                        <Header.Subheader>#{customer.id} {types[customer.type]}</Header.Subheader>
                        <div style={{ position: "absolute", right: 10, top: 10 }}>
                            <Button.Group>
                                <Button onClick={()=>this.props.history.push("/receipts/add?customer_id="+customer.id)}>Neue Rechnung </Button>
                                <Button.Or />
                                <ContractAdd customer_id={customer.id} trigger={<Button primary>Neuer Kaufvertrag</Button>} />
                            </Button.Group>
                        </div>
                    </Header>
                    <Tab menu={{ widths: 6, secondary: true, pointing: true, style: { marginBottom: 0 } }} panes={panes} />
                </Segment.Group>
            </Segment>
        )
    }
}

export default withRouter(CustomerSingle)