import React, {Component} from 'react'
import { Form, Header, Checkbox, Label } from 'semantic-ui-react'
import client from '../../shared/feathers'
import RegisterContext from "./context";


class Step3 extends Component{
    state={
        gpdr: false,
        agbs: false,
        newsletter: false, 
        error: true,
        loading: false
    }
    save(){
        this.setState({loading:true})
        let {user,company,seller} = this.context
        client.service("register").create({
            user: {...user,newsletter: this.state.newsletter},
            company,seller
        }).then(()=>{
            this.setState({loading:false})
            this.props.history.push("/Authentification/Register-Sucessfull")
        }).catch((e)=>{
            console.log(e)
            this.setState({loading:false})
            this.props.history.push("/Authentification/Register-Error")
        })
    }
    render(){
        return (
            <Form onSubmit={()=>this.save()} loading={this.state.loading}>
                <Header style={{marginTop:30,marginBottom:50}} textAlign='center'>
                    Sie sind jetzt bereit zum Starten!
                    <Header.Subheader>Nachdem Sie unsere AGB's gelesen und akzeptiert haben, können Sie loslegen.</Header.Subheader>
                </Header>
                <Checkbox onChange={()=>this.setState({agbs:!this.state.agbs})} value={this.state.agbs} style={{marginBottom:20, fontSize:12}} label="Ich habe die allgemeinen Geschäftsbedingungen gelesen und akzeptiert." />
                <Checkbox onChange={()=>this.setState({newsletter:!this.state.newsletter})} value={this.state.newsletter} style={{marginBottom:10, fontSize:12}} label="Verpassen Sie keine News! " />
                <Label    style={{marginBottom:20, fontSize:12, fontWeight:"normal"}}>Mit der Zustimmung erhalten Sie den AutoDIGI-Newletter. Sie können Ihre Einverständnis jederzeit widerrufen. Wir geben keine personenbezogenen Daten an Dritte weiter.</Label>
                <Checkbox onChange={()=>this.setState({gpdr:!this.state.gpdr})} value={this.state.gpdr} style={{marginBottom:50, fontSize:12}} label="Ich habe die Datenschutzbestimmungen gelesen und zur Kenntnis genommen." />
                <Form.Button  disabled={!(this.state.gpdr && this.state.agbs)} primary fluid>Abschließen</Form.Button>
            </Form>
        )
    }
} 

Step3.contextType = RegisterContext

export default Step3