import React, { Component } from "react";
import { Segment, Menu, Icon,Dropdown,Image } from "semantic-ui-react";
import chance from "chance";
import CarsList from './list'
import CarNew from "./add";
import CarsImport from "./import";

let IChance = new chance()
class Cars extends Component {
    render() {
        return (
            <Segment basic >
                {!this.props.hideMenu && <Menu>
                    <Menu.Item><b>Fahrzeuge</b></Menu.Item>
                    <Menu.Menu position="right">
                        <Dropdown  text='Importieren' item>
                            <Dropdown.Menu>
                                <CarsImport trigger={<Dropdown.Item>von mobile.de</Dropdown.Item>} />
                            </Dropdown.Menu>
                            </Dropdown>
                        <CarNew trigger={<Menu.Item><Icon name="plus square" /> Hinzufügen</Menu.Item>} />
                    </Menu.Menu>
                </Menu>}
                <CarsList query={{sold:false}} />
            </Segment>
        )
    }
}

export default Cars