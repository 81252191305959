import React, { Component } from "react";
import { Segment, Tab, Form, Button } from "semantic-ui-react";
import SignatureCanvas from 'react-signature-canvas'
import "./order.css";


class SEPA extends Component {
    state={
        canvasSize:500
    }
    componentDidMount(){
        if(document.getElementById('signatureContainer')){
            this.setState({canvasSize:document.getElementById('signatureContainer').offsetWidth})
        }
    }
    clearSignature(){
        this.refs.signature.clear()
    }
    render() {
        return (
            <Tab.Pane attached={false}>
                    <Form.Group widths="equal">
                        <Form.Input required label="Vorname" />
                        <Form.Input required label="Nachname" />
                    </Form.Group>
                    <Form.Input required label="IBAN" />
                    <Form.Input required label="BIC" />
                    <Form.Field  >
                        <label >Unterschrift<up>*</up></label>
                        <Segment.Group >
                            <Segment id="signatureContainer" attached="top" style={{padding:0}} >
                                <SignatureCanvas canvasProps={{width: this.state.canvasSize, height: 200, className: 'sigCanvas'}} ref="signature" />
                            </Segment>
                            <Button onClick={this.clearSignature.bind(this)} attached="bottom" size="tiny">Zurücksetzen</Button>
                        </Segment.Group>
                        <div style={{clear:"both"}} />
                    </Form.Field>
                    <Form.Checkbox required label="Ich stimme dem SEPA Direct Mandat zu." />
            </Tab.Pane>
        )
    }
}

export default SEPA