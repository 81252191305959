import React, {Component} from 'react'
import {  Form, Grid, Header,Icon, Segment,Button, Loader, Input } from 'semantic-ui-react'
import {withRouter, Route} from 'react-router-dom'
import client from '../../shared/feathers'

var strongRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");

class ForgotVerify extends Component{
    state={
        loading:false,
        finish: false,
        err:false
    }
    request(){
        this.setState({loading:true})
        console.log({password:this.state.password,token:this.props.match.params.token})
        client.service("authManagement").create({ 
            action: 'resetPwdLong',
            value: {
                password:this.state.password,
                token:this.props.match.params.token
            },
        }).then(result=>{
            console.log(result)
            this.setState({loading:false,finish:true,err:false})
        }).catch(e=>{
            console.log(e)
            this.setState({loading:false,finish:true,err:true})
        })
    }
    handleChange = (e, { name, value }) => this.setState({ [name]: value })
    render(){
        return (
            <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
                <Grid.Column textAlign='left' style={{ maxWidth: 760 }}>
                    <center style={{marginTop:20}}><img height="70" src="/autodigi_weiss.png"></img></center>
                    <Segment style={{padding:0, overflow:"hidden"}} >
                        <Grid >
                            <Grid.Column style={{padding:0}} width="4"><img height="326" style={{width:"100%"}} src="https://picsum.photos/200/326" /></Grid.Column>
                            {(!this.state.finish&&!this.state.loading) && <Grid.Column style={{paddingTop:60,paddingRight:30}} width="12">
                                <Header as="h3">Passwort ändern.</Header>
                                <Header.Subheader>Bitte geben Sie nachfolgend Ihr neues Passwort an.</Header.Subheader>
                                <Form style={{paddingTop:35}} onSubmit={this.request.bind(this)}>
                                    <Form.Field >
                                        <label>Neues Passwort <sup>*</sup></label>
                                        <Input required type="password" name="password" onChange={this.handleChange.bind(this)}/>
                                        {!strongRegex.test(this.state.password) && <small style={{marginTop:"-1em"}} className="helper">Mindestens 8 Zeichen, einen Groß- und einen Kleinbuchstaben, sowie eine Nummer.</small>}
                                    </Form.Field>
                                    <Form.Button disabled={!strongRegex.test(this.state.password)} primary fluid icon labelPosition="right" style={{textAlign:"left"}}>
                                        Passwort ändern
                                        <Icon name='shield' />
                                    </Form.Button>
                                </Form>
                            </Grid.Column>}
                            {(this.state.finish&&!this.state.loading&&!this.state.err) && <Grid.Column style={{paddingTop:90,paddingRight:30}} width="12">
                                <Header as="h3">Erfolgreich</Header>
                                <Header.Subheader>Ihr Passwort wurde erfolgreich abgeändert. Sie können sich jetzt Anmelden.</Header.Subheader>
                                {/*<Button as="a" primary onClick={()=>this.props.history.push("/Authentification/forgot-password/Verify")} fluid icon labelPosition="right" style={{textAlign:"left", marginBottom:5}}>
                                    Jetzt Code eingeben
                                    <Icon name='arrow right' />
                                </Button>*/}
                                <Button as="a" onClick={()=>this.props.history.push("/Authentification")} fluid icon labelPosition="right" style={{textAlign:"left",marginTop:30}}>
                                    Zurück zur Anmeldung
                                    <Icon name='arrow left' />
                                </Button>
                            </Grid.Column>}
                            {(this.state.finish&&!this.state.loading&&this.state.err) &&  <Grid.Column style={{paddingTop:80,paddingRight:30}} width="12">
                                <Header as="h3">Fehlgeschlagen</Header>
                                <Header.Subheader>Leider ist Ihre Anfrage abgelaufen oder Fehlerhaft. Bitte versuchen Sie es erneut.</Header.Subheader>
                                <Button as="a" onClick={()=>this.props.history.push("/Authentification/forgot-password/")} fluid icon labelPosition="right" style={{textAlign:"left",marginTop:30,marginBottom:5}}>
                                    Erneut Passwort zusenden?
                                    <Icon name='envelope' />
                                </Button>
                                <Button as="a" onClick={()=>this.props.history.push("/Authentification")} fluid icon labelPosition="right" style={{textAlign:"left"}}>
                                    Zurück zur Anmeldung
                                    <Icon name='arrow left' />
                                </Button>
                            </Grid.Column>}
                            {this.state.loading && <Grid.Column width="12"><Loader active>Überprüfe Anfrage</Loader></Grid.Column>}
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }
} 

export default withRouter(ForgotVerify)