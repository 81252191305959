import React,{ Component } from "react";
import { Loader } from "semantic-ui-react";
import client from '../../../shared/feathers'

class SellerSingleCompact extends Component{
    state={
        seller: null
    }
    async componentDidMount(){
        this.setState({seller:await client.service("users").get(this.props.seller_id)})
    }
    render(){
        if(this.state.seller){
            return this.state.seller.firstname+" "+this.state.seller.lastname
        }
        return <Loader active inline size='mini' />
    }
}

export default SellerSingleCompact