import React, { Component } from "react";
import { Segment, Header, List, Icon, Button } from "semantic-ui-react";
import Dropzone from 'react-dropzone'
import client from '../../../shared/feathers'

class ContractSingleDocuments extends Component {
    state={
        files: []
    }

    async uploadingFile(file,field,loadingField,last){
        let reader  = new FileReader();
        reader.addEventListener("load", () =>{
            client.service('uploads')
                .create({uri: reader.result})
                .then(async(response)=>{
                    await client.service("contracts").patch(this.props.contract.id,{[field]: JSON.stringify([...JSON.parse(this.props.contract[field]),{name:file.name,id:response.id, sort:0}])})
                }).catch(()=>{
                    this.setState({ [loadingField]:last} );
                }); 
        }, false);
        reader.readAsDataURL(file);
    }
    async onDrop(images){
        this.setState({uploadingFile:true})
        let counter = 0
        for (const image of images) {
            counter++
            this.uploadingFile(image, "files","uploadingFile",!(counter >=images.length))
        }
    }
    async handleChangeCustomer (e,{value}) {
        this.setState({
            customer_id: value,
            customer:await client.service("customers").get(value),
            holding:false
        })
    }

    async deleteDoc(index,field){
        var tmp = {[field]: JSON.parse(this.props.contract[field])};
        delete tmp[field][index];
        await client.service("contracts").patch(this.props.contract.id, {[field]:JSON.stringify(tmp[field].filter((el)=>el!=null))} )
    }

    render() {
        return (
            <Segment.Group>
                <Header attached="top">Dokumente</Header>
                <Segment attached="bottom">
                    <Dropzone maxSize={20000000} onDrop={this.onDrop.bind(this)} >
                    {({getRootProps, getInputProps, isDragActive}) => (
                        <Segment placeholder={!isDragActive} style={{minHeight:80}} {...getRootProps()}>
                            <input {...getInputProps()} />
                            {this.state.uploadingFile ? "Wird gerade hochgeladen..." :"Ziehe die Datei hierhin, um sie hochzuladen."}
                            {!this.state.uploadingImage && <small className="helper">Maximale Datei-Größe von 10MB</small>}
                        </Segment>
                    )}
                    </Dropzone>
                    {JSON.parse(this.props.contract.files).length > 0 &&<List>
                        {JSON.parse(this.props.contract.files).map((file,index) => (
                            <List.Item key={index} >
                                <List.Content floated='right'>
                                    <Button onClick={()=>this.deleteDoc(index,"files")} as="a" negative icon><Icon name="trash" /></Button>
                                </List.Content>
                                <List.Content><a href={"https://api.carbill.dev.tecoyo.com/uploads/"+file.id} target="_blank">{file.name}</a></List.Content>
                            </List.Item>
                        ))}
                    </List>}
                </Segment>
            </Segment.Group>
        )
    }
}

export default ContractSingleDocuments