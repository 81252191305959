import React,{ Component } from "react";
import { Loader } from "semantic-ui-react";
import client from '../../shared/feathers'

let types = {commercial:"Unternehmen",private:"Privat"}
class CustomerCompact extends Component{
    state={
        seller: null
    }
    async componentDidMount(){
        this.setState({seller:await client.service("customers").get(this.props.id)})
    }
    render(){
        if(this.state.seller){
            if(this.props.field =="type"){
                return types[this.state.seller.type]
            }
            return this.state.seller[this.props.field] || ""
        }
        return <Loader active inline size='mini' />
    }
}

export default CustomerCompact