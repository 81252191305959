import React, {Component} from 'react'
import {  Sidebar, Menu, Icon, Segment, Button, Header } from 'semantic-ui-react'
import {withRouter} from 'react-router-dom'
import client from '../../shared/feathers'
import Tour from 'reactour'
let Groups = {administrator:"Administrator",manager:"Manager",salesman:"Verkäufer",accountant:"Buchhalter",mechanic:"Mechaniker",carcreator:"Fahrzeugerfasser"}


class Tutorial extends Component{
    state={
        open:this.props.user.tour==1
    }
    async onClose(){
        this.setState({open:false})
        await client.service("users").patch(this.props.user.id,{tour:false})
    }
    render(){
        const steps={
            manager:[
                {content:<div><Header>Herzlich Willkommen bei Carbill</Header> Damit Sie Carbill besser kennen lernen, führen Sie wir nun durch ein kurzes Tutorial.</div>},
                {selector:"#menu-settings",stepInteraction:false,content: ({ goTo, inDOM }) => (<div><Header>Einstellungen</Header>Um loszulegen sollten Sie Ihre Kontaktdaten, Rechnungsdaten sowie das Unternehmenslogo einpflegen.<Button onClick={()=>{this.props.history.push("/settings");goTo(2)}} primary style={{marginTop:10}}>Jetzt zu den Einstellungen</Button></div>)},
                {selector:"#general-settings",showButtons:false,content:<div><Header>Allgemeine Kontaktdaten & Firmenlogo </Header>Tragen Sie alle Unternehmensrelevanten Informationen ein. Vergessen Sie nicht zu speichern. </div>},
                {selector:"#bank-settings",content:<div><Header>Bankverbindung</Header>Ihre Bankdaten werden auf zukünftigen Rechnungen und Verträgen angezeigt werden. </div>},
                {selector:"#mobile-settings",content:<div><Header>Mobile.de</Header> Tragen Sie Ihre Mobile.de API-Zugangsdaten ein. Sofern Sie noch keinen Zugang auf die Sellers-API von Mobile haben, setzen Sie sich bitte mit Mobile in Verbindung.</div>},
                {selector:"#menu-sellers",content:<div><Header>Mitarbeiter</Header> Hier können Sie Ihre Mitarbeiter verwalten und Ihr Paket, sofern Sie mehr Mitarbeiter benötigen, hochstufen.</div>},
                {selector:"#menu-cars",content:<div><Header>Fahrzeuge</Header> Hier können Sie Ihre Fahrzeuge einpflegen und die Werbeanzeigen verwalten.</div>},
                {selector:"#menu-customers",content:<div><Header>Kunden</Header> Hier können Sie Ihre Kunden anlegen und verwalten.</div>},
                {selector:"#menu-receipts",content:<div><Header>Rechnungen</Header> Hier können Sie die Rechnungen all Ihrer Kunden einsehen.</div>},
                {selector:"#menu-calendar",content:<div><Header>Kalender</Header> Nutzen Sie den Kalender um Verkaufsgespräche oder Termine mit Ihren Mitarbeitern zu teilen.</div>},
                {selector:"#menu-contracts",content:<div><Header>Kaufverträge</Header> Nachdem Sie den Kunden angelegt haben, können Sie einen Kaufvertrag erstellen.</div>},
                {selector:"#menu-dashboard",content:<div><Header>Dashboard</Header> Hier sehen Sie allgemeine Statistiken sowie Termine.</div>},
            ],
            administrator:[
                {content:<div><Header>Herzlich Willkommen bei Carbill</Header> Damit Sie Carbill besser kennen lernen, führen Sie wir nun durch ein kurzes Tutorial.</div>},
            ],salesman:[
                {content:<div><Header>Herzlich Willkommen bei Carbill</Header> Damit Sie Carbill besser kennen lernen, führen Sie wir nun durch ein kurzes Tutorial.</div>},
            ],accountant:[
                {content:<div><Header>Herzlich Willkommen bei Carbill</Header> Damit Sie Carbill besser kennen lernen, führen Sie wir nun durch ein kurzes Tutorial.</div>},
            ],mechanic:[
                {content:<div><Header>Herzlich Willkommen bei Carbill</Header> Damit Sie Carbill besser kennen lernen, führen Sie wir nun durch ein kurzes Tutorial.</div>},
            ],carcreator:[
                {content:<div><Header>Herzlich Willkommen bei Carbill</Header> Damit Sie Carbill besser kennen lernen, führen Sie wir nun durch ein kurzes Tutorial.</div>},
            ]
        }
        return (
            <Tour
                steps={steps[this.props.user.permissions]}
                isOpen={this.state.open}
                rounded={5}
                disableInteraction
                onRequestClose={this.onClose.bind(this)}
                lastStepNextButton={<Button positive >Fertig.</Button>} 
            />
        )
    }
} 

export default withRouter(Tutorial)