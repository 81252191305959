import React,{ Component } from "react";
import { Grid, Tab, GridRow, Segment, Form, Header, Table,Comment, GridColumn, Button,Label } from "semantic-ui-react";

import Contracts from "../../Contracts/list";
import Receipts from "../../Receipts/list";

import CustomerEdit from "./change";
import SellerSingleCompact from "../../Sellers/Single/compact";
import Moment from "react-moment";
import client from '../../../shared/feathers'


/*
  <Table.Cell>{IChance.integer({min: 2,max: 15})}</Table.Cell>
  <Table.Cell>{IChance.pickone(['Unternehmen', 'Privat'])}</Table.Cell>
  <Table.Cell selectable><Link to={"/customers/1"}>{IChance.pickone([IChance.company(), IChance.name()])}</Link></Table.Cell>
  <Table.Cell>{IChance.address()}, {IChance.postcode()} {IChance.city()}, {IChance.country({ full: true })}</Table.Cell>
  <Table.Cell>Autohandel</Table.Cell>
  <Table.Cell>{IChance.email()}</Table.Cell>
  <Table.Cell>{IChance.phone({country: "uk", mobile: true })}</Table.Cell>
  <Table.Cell>{IChance.phone({country: "uk", mobile: true })}</Table.Cell>
  <Table.Cell>{IChance.euro()}</Table.Cell>
*/                                         

class Informations extends Component{
    state={
        loading: true,
        comments: []
    }

    async componentDidMount() {
        this.reactive = client.service("customer_comments").watch().find({query:{$sort:{id:-1},$limit:5,customer_id:this.props.customer.id}}).subscribe(async (data)=>this.setState({comments:data.data,loading:false}) )
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    render(){
        return (
            <Tab.Pane attached="bottom" style={{marginTop:0}}>
                <Grid>
                    <GridRow columns="equal">
                        <Grid.Column>
                            <Segment.Group>
                                <Segment attached>
                                    <CustomerEdit customer={this.props.customer} />
                                </Segment>
                            </Segment.Group>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment.Group className="comments minimal" style={{maxWidth:"100%"}}>
                                <Header attached="top">Kommentare</Header>

                                {this.state.comments.map((entry,id)=>
                                <Segment attached style={{padding:5}}>
                                    <Comment>
                                        <Comment.Content>
                                            <Comment.Author><SellerSingleCompact seller_id={entry.user_id} /><Comment.Metadata><Moment fromNow locale="de">{entry.created_at}</Moment></Comment.Metadata></Comment.Author>
                                            <Comment.Text>{entry.comment}</Comment.Text>
                                        </Comment.Content>
                                    </Comment>
                                </Segment>
                                )}

                                
                            </Segment.Group>

                            <Segment.Group>
                                <Header attached="top">Kaufverträge</Header>
                                <Contracts 
                                    hideMenu 
                                    noFilter
                                    query={{customer_id:this.props.customer.id}}
                                    noPagination
                                    TableProps={{style:{marginTop:-1, borderRadius:0}}} 
                                    SegmentProps={{compact:true,size:"small",attatched:true,style:{border:0,margin:0,marginTop:-1, borderRadius:0,width: "100%"}}} limit={5} />
                                <Button attached="bottom">Mehr anzeigen</Button>
                            </Segment.Group>

                        </Grid.Column>
                    </GridRow>
                    <GridRow columns="2">
                        <GridColumn >
                            <Segment.Group>
                                <Header attached="top">Fahrzeug Rechnungen</Header>
                                <Receipts 
                                    hideMenu 
                                    lessInfo
                                    noFilter
                                    noPagination
                                    query={{customer_id:this.props.customer.id,contract_id:{$ne:null}}}
                                    TableProps={{style:{marginTop:-1, borderRadius:0}}} 
                                    SegmentProps={{compact:true,size:"small",attatched:true,style:{border:0,margin:0,marginTop:-1, borderRadius:0,width: "100%"}}} limit={5} />
                                <Button attached="bottom">Mehr anzeigen</Button>
                            </Segment.Group>
                        </GridColumn>
                        <GridColumn>

                            <Segment.Group>
                                <Header attached="top">Rechnungen</Header>
                                <Receipts 
                                    hideMenu 
                                    lessInfo
                                    noFilter
                                    noPagination
                                    query={{customer_id:this.props.customer.id,contract_id:null}}
                                    TableProps={{style:{marginTop:-1, borderRadius:0}}} 
                                    SegmentProps={{compact:true,size:"small",attatched:true,style:{border:0,margin:0,marginTop:-1, borderRadius:0,width: "100%"}}} limit={5} />
                                <Button attached="bottom">Mehr anzeigen</Button>
                            </Segment.Group>
                        </GridColumn>
                    </GridRow>
                </Grid>
            </Tab.Pane>
        )
    }
}

export default Informations