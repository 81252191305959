import React, {Component} from 'react';
import {CardElement,IbanElement,PaymentRequestButtonElement, injectStripe} from 'react-stripe-elements';
import { Segment,Header,Form } from 'semantic-ui-react';

class CheckoutForm extends Component {
  state={
    ibanERR:""
  }
  constructor(props) {
    super(props);
    console.log(props)
    this.submit = this.submit.bind(this);

  }

  async submit(ev) {
    // User clicked submit
    let result = await this.props.stripe.createToken({name: 'Jenny Rosen',currency:"eur"})
    if(result.error){
      this.setState({ibanERR:result.error.message})
    }
    console.log(this.props.stripe)
    console.log(ev)
  }
  render() {
    return (
      <div className="checkout">
        <Form>
          <Header attached="top">SEPA-Lastschrift Mandat</Header>
          <Segment attached="bottom">
            <Form.Input label="Konto-Inhaber" />
            <Form.Field>
              <label>IBAN</label>
              <div style={{padding:" .67857143em 1em",borderRadius:".28571429rem",border: "1px solid",borderColor:this.state.ibanERR!=""?"rgba(255,0,0,.51)":"rgba(34,36,38,.15)"}}>
                <IbanElement supportedCountries={['SEPA']} />
              </div>
              <small>{this.state.ibanERR}</small>
            </Form.Field>
          </Segment>
          <button onClick={this.submit.bind(this)}>Send</button>

        </Form>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
