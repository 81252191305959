import React, {Component} from 'react'
import { Message, Button, Loader,Sidebar,Segment, Icon, Header } from 'semantic-ui-react'
import {BrowserRouter, Route } from 'react-router-dom'
import client from '../shared/feathers'

import moment from 'moment';
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css'
import Menu from './Menu';
import DashboardPage from './Dashboard'
import Customers from './Customers'
import CustomersSingle from './Customers/Single/'
import Cars from './Cars'
import CarsSold from './Cars/sold'
import CarSingle from './Cars/Single'
import Sellers from './Sellers'
import SellerSingle from './Sellers/Single/'
import Receipts from './Receipts'
import ReceiptSingle from './Receipts/Single'
import ReceiptAdd from "./Receipts/add";
import Calendar from './Calendar'
import Contracts from './Contracts'
import ContractSingle from './Contracts/Single/'
import Settings from './Settings'
import Admin from './Admin'
import AdminSingle from './Admin/single'
import Order from './Order';
import Context from "./context";
import EventSingle from './Calendar/Singel';
import Tutorial from './Tutorial';

class Dashboard extends Component{
    state={
        open:false,
        create:false,
        event_id:0,
        hover:true,
        loading: true,
        openEvent:this.openEvent.bind(this),
        closeEvent:this.closeEvent.bind(this),
        start:"",end:""
    }
    openEvent(event_id,start,end){
        if(event_id==0){
            this.setState({open:true, create:true,start,end,event_id:0})
        }else{
            this.setState({open:true,create:false,event_id:event_id.id})
        }
    }
    closeEvent(callback){
        this.setState({open:false, create:false,start:moment(),end:moment()},callback)
    }
    async componentDidMount(){
        document.title = 'Dashboard';
        let user = await client.service("user").find()
        let company = await client.service("companies").get(user.company_id)
        this.setState({user,company,loading:false})
    }
    render(){
        return (
            <BrowserRouter >
                <Context.Provider value={this.state}>
                        <Sidebar as={Segment} animation='overlay' style={{background:"#fff"}} direction='right' vertical visible={this.state.open} width="wide">
                            <Header style={{borderLeft:"none",borderRight:"none",borderTop:"none"}} attached="top"><Icon onClick={this.closeEvent.bind(this,()=>{})} style={{cursor:"pointer"}} name="close" /> Kalendar Eintrag</Header>
                            <EventSingle key={this.state.event_id+this.state.start} {...this.state} />
                        </Sidebar>

                            <Loader active={this.state.loading} />

                            <div onMouseEnter={()=>this.setState({hover:false})}><Menu /></div>
                            {!this.state.loading &&<div style={{ width:"100vw", height:"100vh"}}>
                                {/*<Tutorial company={this.state.company} user={this.state.user}/>*/}
                                <div 
                                    onMouseEnter={()=>this.setState({hover:true})}
                                    
                                    className="inverted" style={{transition:"left 0.5s",position:"fixed", top:0,left:this.state.hover?120:250,right:0, overflow:"auto",overflowX:"hidden",bottom: 0}}>
                                    {this.state.company.isActive == 0 && <Message attached="bottom" warning >
                                        <Order force={!moment().isBefore(moment(this.state.company.created_at).add(this.state.company.trail,"days"))} trigger={<Button floated="right" primary>Jetzt Paket buchen</Button>} />
                                        <Message.Header>Herzlich Willkommen! Ihre Testversion läuft nur noch {moment(this.state.company.created_at).add(this.state.company.trail,"days").diff(moment(),"days")} Tage!</Message.Header>
                                        <p>Bitte wählen Sie Ihr Paket und Ihre Zahlungsmethode aus.</p> 
                                    </Message>}
                                    <Route path="/" exact component={()=><DashboardPage />} />
                                    <Route path="/dashboard" exact component={DashboardPage} />
                                    {["administrator","manager","salesman","accountant"].includes(this.state.user.permissions) && <Route path="/customers" exact component={Customers} />}
                                    {["administrator","manager","salesman","accountant"].includes(this.state.user.permissions) && <Route path="/customers/:id" exact component={CustomersSingle} />}
                                    {["administrator","manager","salesman","mechanic","carcreator"].includes(this.state.user.permissions) && <Route path="/cars/active" exact component={Cars} />}
                                    {["administrator","manager","salesman","mechanic","carcreator"].includes(this.state.user.permissions) && <Route path="/cars/single/:id" exact component={CarSingle} />}
                                    {["administrator","manager","salesman","mechanic","carcreator"].includes(this.state.user.permissions) && <Route path="/cars/sold" exact component={CarsSold} />}
                                    {["administrator","manager"].includes(this.state.user.permissions) && <Route path="/sellers" exact component={Sellers} />}
                                    {["administrator","manager"].includes(this.state.user.permissions) && <Route path="/sellers/:id" exact component={SellerSingle} />}
                                    {["administrator","manager","salesman","mechanic","accountant"].includes(this.state.user.permissions) &&<Route path="/receipts" exact component={Receipts} />}
                                    {["administrator","manager","salesman","mechanic","accountant"].includes(this.state.user.permissions) &&<Route path="/receipts/add" exact component={ReceiptAdd} />}
                                    {["administrator","manager","salesman","mechanic","accountant"].includes(this.state.user.permissions) &&<Route path="/receipts/view/:id" exact component={ReceiptSingle} />}
                                    {["administrator","manager","salesman","mechanic","carcreator","accountant"].includes(this.state.user.permissions) && <Route path="/calendar" component={()=><Calendar />} />}
                                    {["administrator","manager","salesman","accountant"].includes(this.state.user.permissions) && <Route path="/contracts" exact component={Contracts} />}
                                    {["administrator","manager","salesman","accountant"].includes(this.state.user.permissions) && <Route path="/contracts/:id" exact component={ContractSingle} />}
                                    {["administrator","manager"].includes(this.state.user.permissions) &&<Route path="/settings" component={Settings} />}
                                    {this.state.user.isAdmin == 1 &&<Route path="/admin" exact component={Admin} />}
                                    {this.state.user.isAdmin == 1 &&<Route path="/admin/:id" exact component={AdminSingle} />}
                                </div>
                            </div>}

                </Context.Provider>
            </BrowserRouter>
        )
    }
} 

export default Dashboard