import React,{ Component } from "react";
import { Segment, Header, Tab, Menu, Icon, Button } from "semantic-ui-react";
import chance from "chance";
import UserSingleSettings from "./settings";
import UserSingleStats from "./stats";
import client from "../../../shared/feathers";

let IChance = new chance()


let Groups = {administrator:"Administrator",manager:"Manager",salesman:"Verkäufer",accountant:"Buchhalter",mechanic:"Mechaniker",carcreator:"Fahrzeugerfasser"}

class SellerSingle extends Component{
    state={
        loading:true,
        notFound: false,
        user: null
    }
    componentDidMount() {
        this.reactive = client.service("users").watch().get( this.props.match.params.id,{deleted:false}).subscribe(async (data)=>this.setState({user:data,loading:false}),()=>this.setState({loading:false, notFound: true}))
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    render(){
        if(this.state.loading){
            return <Segment basic><Segment loading placeholder></Segment></Segment>
        }
        if(this.state.notFound){
            return <Segment basic ><Segment placeholder color="red"> <Header icon><Icon name='search' /> Kunde wurde nicht gefunden.</Header> </Segment></Segment>
        }
        const panes = [
            { menuItem: (<Menu.Item ><Icon name="dashboard" /> Statistiken</Menu.Item>), render: () => <UserSingleStats user={this.state.user}  /> },
            { menuItem: (<Menu.Item ><Icon name="cogs" /> Verwalten</Menu.Item>), render: () => <UserSingleSettings user={this.state.user} /> },
        ]        
        return (
            <Segment basic>
                <Segment.Group>
                    <Header attached="top">
                        {this.state.user.active==1 &&<Button floated="right" onClick={()=>{ client.service("users").patch(this.state.user.id,{active:false}).then(()=>{
                            this.props.history.push("/sellers/")
                        }); }}>Deaktivieren</Button>}
                        <Button floated="right" 
                        onClick={()=>{
                            client.service("users").patch(this.state.user.id,{email:this.state.user.email+"_deleted_"+IChance.string({ pool: 'abcde' }),deleted:true,isVerified:false}).then(()=>{
                                this.props.history.push("/sellers/")
                            });
                        }} negative>Löschen</Button>
                        {this.state.user.firstname} {this.state.user.lastname}
                        <Header.Subheader>{Groups[this.state.user.permissions]}</Header.Subheader>
                    </Header>
                    <Tab menu={{widths:2, secondary: true, pointing: true,style:{marginBottom:0} }}  panes={panes} />
                </Segment.Group>
            </Segment>
        )
    }
}

export default SellerSingle