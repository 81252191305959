import React, { Component } from "react";
import { Message, Modal,Button,Divider,Table,Form,Icon } from "semantic-ui-react";
import Package from "./package";
import PayPal from "./Paypal";
import SEPA from "./SEPA";
import {StripeProvider,Elements} from 'react-stripe-elements';
import client from "../../shared/feathers";
import { withRouter } from "react-router-dom"

import MyStoreCheckout from './MyStoreCheckout';
class Order extends Component {
    state={
        customer_id:null,
        open:false
    }
    componentDidMount(){
        if(this.props.cusomter_id){
            this.setState({customer_id})
        }
    }
    buy(){
        var stripe = Stripe('pk_test_h4cmQqvxt8IVRQS5PJ2qb0Rg007gmE3D47');

        stripe.redirectToCheckout({
        items: [
            {plan: 'plan_FOMyH52I8eqbYS',quantity:1}
        ],
        billingAddressCollection: "required",
        successUrl: 'https://autodigi.dev.tecoyo.com/order/success',
        cancelUrl: 'https://autodigi.dev.tecoyo.com/order/canceled',
        }).then(function (result) {
            console.log(result)
        });
    }
    render() {
        return (
            <Modal open={this.props.force ? true:this.state.open} closeIcon={!this.props.force} closeOnDimmerClick={false} onOpen={()=>this.setState({open:true})} onClose={()=>this.setState({open:false})}  trigger={this.props.trigger} >
                <Modal.Content >
                    {this.props.force && <Message negative>
                        <b>Ihre Testversion ist abgelaufen</b> <br/>bitte wählen Sie einen Vertrag aus und bestellen Sie. Ansonsten können Sie auch unseren Support anrufen.
                        
                        <Button size="tiny" fluid onClick={()=>{client.logout();this.props.history.push("/");}} style={{backgroundColor:"#fff",border:"#d9d9d9 1px solid",marginTop:8}}>
                            <Icon name='shutdown' />
                            Abmelden
                        </Button>
                    </Message>}

                    <Form>
                        <Package />
                        
                        {/*<Tab style={{marginTop:20}} menu={{pointing: true, widths:2}} panes={[
                            { menuItem: ( <Menu.Item key="sepa"> <img style={{height:40, width:"auto"}} src="/images/logo-payment-sepa.png" /></Menu.Item> ), render: () => <SEPA /> },
                            { menuItem: ( <Menu.Item key="paypal"> <img style={{height:40, width:"auto"}} src="/images/paypal-784404_640.png" /></Menu.Item> ), render: () => <PayPal /> },
                        ]} />
                        <Divider horizontal>SEPA-Mandat</Divider>
                        <SEPA />
                        */}
                        
                        <Divider horizontal>Bezahlmethode</Divider>

                        <Elements>
                            <StripeProvider apiKey="pk_live_uNLcmkndUnnKVwrLD3ZNUprS00whiLHQgM">
                                <MyStoreCheckout />
                            </StripeProvider>
                        </Elements>
                        <Divider horizontal>Warenkorb</Divider>
                        <Table celled>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell collapsing><b>Bronze - Monatliches Abonnement*</b></Table.Cell>
                                    <Table.Cell>
                                    Speicherplatz:	Unbegrenzt<br/>
                                    Funktionen:	Unbegrenzt<br/>
                                    Max. Benutzer:	2<br/>
                                    Support:	E-Mail & Telefon<br/>
                                    Datensicherheit:	DSGVO optimiert<br/>
                                    </Table.Cell>
                                    <Table.Cell collapsing>99,00 € / Monat<br/><small>inkl. Mwst</small></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan={2} style={{textAlign:"right"}}><b>Einmalig</b></Table.Cell>
                                    <Table.Cell>0,00 €</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan={2} style={{textAlign:"right"}}><b>Monatlich</b></Table.Cell>
                                    <Table.Cell>99,00 €<br/><small>inkl. Mwst</small></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <small>*Der Vertrag wird nach Beendigung des Monats automatisch um einen Monat verlängert und kann jeweils zum Monatsanfang gekündigt werden.</small>
                        
                        <Button onClick={this.buy.bind(this)} primary size="large" fluid style={{marginTop:20}}>Kostenpflichtig Abonieren</Button>
                        
                    </Form>
                </Modal.Content>
            </Modal >
        )
    }
}

export default Order